import styled from "styled-components";

const AppPageCenterWrapper = styled.div`
  background-color: #F0F4F7;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding-bottom: 20px;
  margin-top: 96px;
`;

export default AppPageCenterWrapper;