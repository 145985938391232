import React from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";
import AppTestVideoItem from "../app-test-video/AppTestVideoItem";
import AppTestVideoItemAnswer from "../app-test-video/AppTestVideoItemAnswer";

const AppTestVideoResultWrapper = styled.div`
  margin-top: 35px;
  max-width: 842px;
  width: 100%;
`;

const AppTestVideoResultTitleWrapper = styled.div`
  
  display: flex;
  justify-content: space-between;
  width: 100%;
  
  .app-test-video-result-title {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: #424242;
  }
  .app-test-video-result-text {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #272727;
    margin-top: 8px;
    a {
      color: #597ABA;
    }
  }
  .app-test-video-result-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  @media (max-width: 800px) {
    flex-direction: column-reverse;
    align-items: center;
    .app-test-video-result-title {
      font-size: 18px;
      margin-top: 10px;
    }
  }
`;

const AppTestVideoResultButtonWrapper = styled.div`
  a {
    background: #359C39;
    box-shadow: -7px 5px 33px rgba(25, 41, 124, 0.05);
    border-radius: 7px;
    min-width: 242px;
    padding: 16px 29px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
    text-decoration: none;
    display: block;
    margin-top: 28px;
    transition: .3s all;
    &:hover {
      background-color: #2a7a2d;
    }
  }
`;

const AppTestVideoResultRatingWrapper = styled.div`
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;
    background: #E8AC37;
    border-radius: 50%;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    color: #FFFFFF;
  }
  p {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    margin-top: 8px;
  }
`;

const AppTestVideoResult = () => {
    return (
        <AppTestVideoResultWrapper>
            <AppTestVideoResultTitleWrapper>
                <div className="app-test-video-result-left">
                    <p className="app-test-video-result-title">Вы уже прошли тест по этому видео</p>
                    <p className="app-test-video-result-text">
                        Вы можете скачать этот сертификат также в меню <Link to="/">сертификаты</Link>
                    </p>
                    <AppTestVideoResultButtonWrapper>
                        <Link to="/">Получить сертификат</Link>
                    </AppTestVideoResultButtonWrapper>
                </div>
                <AppTestVideoResultRatingWrapper>
                    <span>70</span>
                    <p>Ваш балл</p>
                </AppTestVideoResultRatingWrapper>
            </AppTestVideoResultTitleWrapper>
            <AppTestVideoItemAnswer isCorrect={true} title="Вопрос 1" />
            <AppTestVideoItemAnswer isCorrect={false} title="Вопрос 2" />
            <AppTestVideoItemAnswer isCorrect={true} title="Вопрос 3" />
        </AppTestVideoResultWrapper>
    );
};

export default AppTestVideoResult;