import React from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";

import videoPhoto from '../../assets/videoimage.jpg';

const CheckboxHeaderWrapper = styled.span`
  border: 2px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 3px;
  width: 20px;
  height: 20px;
  display: block;
`;

const CheckboxWrapper = styled.span`
  border: 2px solid #2A2A2A;
  box-sizing: border-box;
  border-radius: 3px;
  width: 20px;
  height: 20px;
  display: block;
`;

const AppVideoTableWrapper = styled.div`
  width: 100%;
  
  .app-table-link {
    color: #2466B0;
    text-decoration: underline;
  }

  @media (max-width: 900px) {
    overflow-x: auto;
  }

  table {
    width: 100%;
    border-collapse: collapse;

    thead {
      tr {
        th:first-child {
          padding-left: 25px;
        }

        th {
          height: 60px;
          background: #2A2A2A;
          font-family: "Gilroy", sans-serif;
          color: #fff;
          font-size: 18px;
          font-weight: 400;
          border-bottom: 1px solid #E2E8F0;
          text-align: left;
          padding-right: 25px;

          @media (max-width: 900px) {
            font-size: 14px;
            min-height: 40px;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 7px 0;
          border-bottom: 1px solid #E2E8F0;
          font-family: "Gilroy", sans-serif;
          font-size: 16px;
          background: #FFFFFF;
          height: 63px;
          
          a {
            text-decoration: none;
            color: #2D3748;;
          }

          input {
            font-family: "Gilroy", sans-serif;
            font-size: 16px;
            background: #F0F0F0;
            border: 1px solid #DADADA;
            box-sizing: border-box;
            height: 44px;
            outline: none;
            cursor: pointer;
            padding: 0 20px;
          }

          @media (max-width: 900px) {
            padding-right: 20px;
          }
          
        }

        td:first-child {
          padding-left: 25px;
        }
      }
    }
  }
`;

const TableVideoWrapper = styled.div`

  display: flex;

  .table-video-image {
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
    background-image: url(${videoPhoto});
    width: 120px;
    height: 68px;
  }
`;

const TableVideoTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 18px;
  max-width: 330px;
  width: 100%;

  @media (max-width: 900px) {
    width: 330px;
  }

  .table-video-text-title {
    font-family: "Gilroy", sans-serif;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #2D3748;
  }

  .table-video-text-text {
    font-family: "Gilroy", sans-serif;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #737373;
  }
`;

const AppBuyVideoTable = () => {
    return (
        <AppVideoTableWrapper>
            <table>
                <thead>
                <tr>
                    <th>
                        <CheckboxHeaderWrapper/>
                    </th>
                    <th>Строитель</th>
                    <th>Дата теста</th>
                    <th>Termin</th>
                    <th>Дата прикрепления</th>

                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>
                        <CheckboxWrapper/>
                    </td>
                    <td>
                        <Link className="app-table-link" to="/edit-user-profile">Фомин Родион</Link>
                    </td>
                    <td>00/00/00</td>
                    <td>Termin </td>
                    <td>00/00/00</td>
                </tr>
                <tr>
                    <td>
                        <CheckboxWrapper/>
                    </td>
                    <td>
                        <Link className="app-table-link" to="/edit-user-profile">Фомин Родион</Link>
                    </td>
                    <td>00/00/00</td>
                    <td>Termin </td>
                    <td>00/00/00</td>
                </tr>
                <tr>
                    <td>
                        <CheckboxWrapper/>
                    </td>
                    <td>
                        <Link className="app-table-link" to="/edit-user-profile">Фомин Родион</Link>
                    </td>
                    <td>00/00/00</td>
                    <td>Termin </td>
                    <td>00/00/00</td>
                </tr>
                <tr>
                    <td>
                        <CheckboxWrapper/>
                    </td>
                    <td>
                        <Link className="app-table-link" to="/edit-user-profile">Фомин Родион</Link>
                    </td>
                    <td>00/00/00</td>
                    <td>Termin </td>
                    <td>00/00/00</td>
                </tr>
                <tr>
                    <td>
                        <CheckboxWrapper/>
                    </td>
                    <td>
                        <Link className="app-table-link" to="/edit-user-profile">Фомин Родион</Link>
                    </td>
                    <td>00/00/00</td>
                    <td>Termin </td>
                    <td>00/00/00</td>
                </tr>
                <tr>
                    <td>
                        <CheckboxWrapper/>
                    </td>
                    <td>
                        <Link className="app-table-link" to="/edit-user-profile">Фомин Родион</Link>
                    </td>
                    <td>00/00/00</td>
                    <td>Termin </td>
                    <td>00/00/00</td>
                </tr>
                <tr>
                    <td>
                        <CheckboxWrapper/>
                    </td>
                    <td>
                        <Link className="app-table-link" to="/edit-user-profile">Фомин Родион</Link>
                    </td>
                    <td>00/00/00</td>
                    <td>Termin </td>
                    <td>00/00/00</td>
                </tr>
                </tbody>
            </table>
        </AppVideoTableWrapper>
    );
}

export default AppBuyVideoTable;