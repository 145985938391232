import React, {useEffect, useState} from 'react'
import {useHistory, useParams} from "react-router-dom";
import TheHeader from "../../components/the-header/TheHeader";
import TheSidebarMenu from "../../components/the-sidebar-menu/TheSidebarMenu";
import AppPageCenterWrapper from "../../components/app-page-center-wrapper/AppPageCenterWrapper";
import AppContainerWrapper from "../../styles/AppContainerWrapper";
import AppRow from "../../components/app-row/AppRow";
import AppPageTitleWrapper from "../../styles/AppPageTitleWrapper";
import AppTableFilter from "../../components/app-table-filter/AppTableFilter";
import AppendButtonWrapper from "../../styles/AppendButtonWrapper";
import TheAdminTestFormIndex from "../../components/the-admin-test-form-index/the-admin-test-form-index";
import AppTablePagination from "../../components/app-table-pagination/AppTablePagination";
import {useDispatch, useSelector} from "react-redux";
import {remoteCreateAdminTest, remoteGetAdminTestInfo, remoteUpdateAdminTest, showMessage} from "../../redux/actions";
import fileIcon from "../../assets/file.svg";
import FormWrapper from "../../styles/FormWrapper";
import FormGroupWrapper from "../../styles/FormGroupWrapper";
import FormGroupButtonWrapper from "../../styles/FormGroupButtonWrapper";
import AdminQuestionsWrapper from "../../styles/AdminQuestionsWrapper";
import AdminQuestionItemWrapper from "../../styles/AdminQuestionItemWrapper";
import {v4 as uuidv4} from 'uuid';

const AdminTestForm = () => {

    const dispatch = useDispatch()
    const history = useHistory()

    const apiToken = useSelector(state => state.apiToken)

    const {id} = useParams()

    const [testName, setTestName] = useState('')
    const [questions, setQuestions] = useState([])

    const [tempAnswer, setTempAnswer] = useState('')

    const [language, setLanguage] = useState('en')

    useEffect(() => {
        dispatch(remoteGetAdminTestInfo({
            apiToken,
            testId: id,
            cb: (test) => {
                setTestName(test.test_name)

                const formatQuestions = test.questions.map((item, index) => {
                    if (index == 0) {
                        item.isActive = true
                    } else {
                        item.isActive = false
                    }

                    return item
                })

                setQuestions(formatQuestions)

            }
        }))
    }, [id])

    const createTestHandler = () => {
        dispatch(remoteUpdateAdminTest({
            apiToken,
            testId: id,
            test: {
                test_name: testName,
                questions
            },
            cb: (res) => {
                if (res.status == 200) {
                    dispatch(showMessage({
                        title: 'Операция успешна!',
                        message: 'Тест успешно отредактирован'
                    }))
                }
            }
        }))
    }

    const changeLanguageHandler = (e) => {
        setLanguage(e.target.value)
    }

    const tempAnswerHandler = (e) => {
        setTempAnswer(e.target.value)
    }

    const testNameHandler = (e) => {
        setTestName(e.target.value)
    }

    const testContentHandler = (e) => {
        const questionId = e.target.getAttribute('id')

        const newQuestions = questions.map((item) => {

            if (item.id == questionId) {
                item.content = e.target.value
            }

            return item;
        })

        setQuestions(newQuestions)
    }

    const tempAnswerAddHandler = (e) => {
        const questionId = e.target.getAttribute('data-question-id')

        if (tempAnswer.length == 0) return

        const newQuestions = questions.map((item) => {
            if (item.id == questionId && item.language == language) {
                item.answers.push({
                    id: uuidv4(),
                    content: tempAnswer,
                    isRight: false,
                    language: language
                })

                setTempAnswer('')
            }

            return item;
        })

        setQuestions(newQuestions)
    }

    const changeQuestionHandler = (e) => {
        const questionId = e.target.getAttribute('data-question-id')

        const newQuestions = questions.map((item) => {

            if (item.language == language) {
                if (item.id == questionId) {
                    return {
                        ...item,
                        isActive: true
                    }
                } else {
                    return {
                        ...item,
                        isActive: false
                    }
                }
            }

            return item

        })

        setQuestions(newQuestions)
    }

    const addQuestionHandler = () => {

        const newQuestions = questions.map((item) => {

            if (item.language == language) {
                return {
                    ...item,
                    isActive: false
                }
            }

            return item
        })

        newQuestions.push({
            id: uuidv4(),
            content: '',
            isActive: true,
            answers: [],
            language: language
        })

        setQuestions(newQuestions)
    }

    const answerOnChangeHandler = (e) => {
        const questionId = e.target.getAttribute('data-question-id')
        const answerId = e.target.getAttribute('id')
        const newText = e.target.value

        const newQuestions = questions.map((item) => {
            if (item.id == questionId && item.language == language) {
                const newAnswers = item.answers.map((answer) => {
                    if (answer.id == answerId && answer.language == language) {
                        answer.content = newText
                    }

                    return answer
                })

                item.answers = newAnswers
            }

            return item
        })

        setQuestions(newQuestions)
    }

    const deleteAnswerHandler = (e) => {
        const questionId = e.target.getAttribute('data-question-id')
        const answerId = e.target.getAttribute('data-answer-id')

        const isConfirm = window.confirm('Подтверждаете удалени?')

        if (!isConfirm) return

        const newQuestions = questions.map((item) => {
            if (item.id == questionId && item.language == language) {
                const newAnswers = item.answers.filter((answer) => {

                    if (answer.id == answerId && answer.language == language) {
                        return false
                    }

                    return true
                })

                item.answers = newAnswers
            }

            return item
        })

        setQuestions(newQuestions)
    }

    const onChangeStatusAnswerHandler = (e) => {
        const questionId = e.target.getAttribute('data-question-id')
        const answerId = e.target.getAttribute('data-answer-id')

        const newStatus = e.target.value

        const newQuestions = questions.map((item) => {
            if (item.id == questionId && item.language == language) {
                const newAnswers = item.answers.map((answer) => {
                    if (answer.id == answerId && answer.language == language) {
                        answer.isRight = newStatus
                    }

                    return answer
                })

                item.answers = newAnswers
            }

            return item
        })

        setQuestions(newQuestions)
    }

    const removeQuestionHandler = (e) => {

        const isConfirm = window.confirm('Подтверждаете удаление?')

        if (!isConfirm) return

        const newQuestions = questions.filter((item) => {

            if (item.language == language && item.isActive) {
                return false;
            }

            return true
        })

        setQuestions(newQuestions)
    }

    return (
        <React.Fragment>
            <TheHeader/>
            <TheSidebarMenu activeLink="admin-tests"/>
            <AppPageCenterWrapper>
                <AppContainerWrapper>
                    <AppRow>
                        <AppPageTitleWrapper>
                            <p>Редактирование теста - {testName}</p>

                            <select value={language} onChange={changeLanguageHandler} name="language">
                                <option value="ru">RU</option>
                                <option value="en">EN</option>
                                <option value="de">DE</option>
                            </select>
                        </AppPageTitleWrapper>
                    </AppRow>
                    <AppRow>
                        <FormWrapper>
                            <FormGroupWrapper>
                                <label>Название теста</label>
                                <input onChange={testNameHandler} value={testName} name="testName" type="text"/>
                            </FormGroupWrapper>

                            <FormGroupWrapper>
                                <label>Вопросы</label>
                                <AdminQuestionsWrapper>
                                    {
                                        questions.map((item, index) => {

                                            if (item.language == language) {
                                                return (
                                                    <div
                                                        onClick={changeQuestionHandler}
                                                        data-question-id={item.id}
                                                        key={item.id}
                                                        className={`${'admin-question-item'} 
                                                ${item.isActive ? 'admin-question-item-active' : ''}`}>
                                                        {index + 1} вопрос
                                                    </div>
                                                )
                                            }
                                        })
                                    }

                                    <button onClick={addQuestionHandler} className="admin-question-item-add-btn"
                                            type="button">+
                                    </button>
                                </AdminQuestionsWrapper>
                            </FormGroupWrapper>

                            {
                                questions.length > 0 && questions[0].language == language ?
                                    <FormGroupWrapper>
                                        <button
                                            style={{alignSelf: 'flex-start'}}
                                            onClick={removeQuestionHandler}
                                            className="remove-answer-btn">Удалить вопрос
                                        </button>
                                    </FormGroupWrapper>
                                    :
                                    ''
                            }

                            {
                                questions.map((item) => {

                                    if (item.isActive && item.language == language) {
                                        return (
                                            <>
                                                <FormGroupWrapper>
                                                    <label>Содержание вопроса</label>
                                                    <textarea id={item.id} onChange={testContentHandler}
                                                              value={item.content}/>
                                                </FormGroupWrapper>

                                                {
                                                    item.answers.map((answer, index) => {

                                                        if (answer.language == language) {
                                                            return (
                                                                <FormGroupWrapper key={answer.id}>
                                                                    <label>Ответ {index + 1}</label>
                                                                    <div
                                                                        className="add-question-item add-question-item-edit">
                                                                        <input key={answer.id}
                                                                               id={answer.id}
                                                                               data-question-id={item.id}
                                                                               onChange={answerOnChangeHandler}
                                                                               value={answer.content} type="text"/>

                                                                        <select
                                                                            onChange={onChangeStatusAnswerHandler}
                                                                            data-question-id={item.id}
                                                                            data-answer-id={answer.id}
                                                                            value={answer.isRight}
                                                                            className="mark-question-select">
                                                                            <option
                                                                                value={false}>Неправильный
                                                                            </option>
                                                                            <option
                                                                                value={true}>Правильный
                                                                            </option>
                                                                        </select>

                                                                        <button
                                                                            className="remove-answer-btn"
                                                                            data-question-id={item.id}
                                                                            data-answer-id={answer.id}
                                                                            onClick={deleteAnswerHandler}
                                                                            type="button">Удалить
                                                                        </button>
                                                                    </div>
                                                                </FormGroupWrapper>
                                                            )
                                                        }

                                                    })
                                                }

                                                <FormGroupWrapper>
                                                    <label>Новый ответ</label>
                                                    <div className="add-question-item">
                                                        <input value={tempAnswer} onChange={tempAnswerHandler}
                                                               type="text"/>
                                                        <button className="add-question-btn"
                                                                data-question-id={item.id}
                                                                onClick={tempAnswerAddHandler}
                                                                type="button">+
                                                        </button>
                                                    </div>
                                                </FormGroupWrapper>


                                            </>
                                        )
                                    }
                                })
                            }

                            <FormGroupButtonWrapper>
                                <button onClick={createTestHandler}>Сохранить изменения</button>
                            </FormGroupButtonWrapper>

                        </FormWrapper>
                    </AppRow>
                </AppContainerWrapper>
            </AppPageCenterWrapper>
        </React.Fragment>
    )
}

export default AdminTestForm