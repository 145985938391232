import React, {useState} from 'react'
import {Link} from "react-router-dom";
import CheckboxWrapper from "../../styles/CheckboxWrapper";
import CheckboxHeaderWrapper from "../../styles/CheckboxHeaderWrapper";
import AppVideoTableWrapper from "../../styles/AppVideoTableWrapper";
import RemoveIcon from "../../styles/RemoveIcon";
import {useDispatch, useSelector} from "react-redux";
import {
    changeCheckedCategory,
    remoteAdminDeleteCategory,
} from "../../redux/actions";
import StatSubVideosWrapper from "../../styles/StatSubVideosWrapper";

import AppAdminStatTableItem from "../../components/app-admin-stat-table-item/AppAdminStatTableItem";
import {v4 as uuidv4} from 'uuid'

const AppAdminStatTable = ({rows}) => {

    const dispatch = useDispatch()

    const apiToken = useSelector(state => state.apiToken)

    return (
        <AppVideoTableWrapper>
            <table>
                <thead>
                <tr>
                    <th>Сотрудник</th>
                    <th>Видео</th>
                    <th>Количество просмотров</th>
                </tr>
                </thead>
                <tbody>
                {
                    rows.map((item) => {
                        return (
                            <tr key={uuidv4()}>
                                <td>{item.name}</td>
                                <td>{item.video_name}</td>
                                <td>{item.views}</td>
                            </tr>
                        )
                    })
                }

                </tbody>
            </table>
        </AppVideoTableWrapper>
    )
}

export default AppAdminStatTable