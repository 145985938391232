import React, {useEffect, useState} from 'react'
import TheHeader from "../../components/the-header/TheHeader";
import TheSidebarMenu from "../../components/the-sidebar-menu/TheSidebarMenu";
import AppPageCenterWrapper from "../../components/app-page-center-wrapper/AppPageCenterWrapper";
import AppRow from "../../components/app-row/AppRow";
import AppContainerWrapper from "../../styles/AppContainerWrapper";
import AppPageTitleWrapper from "../../styles/AppPageTitleWrapper";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import AppTableSearch from "../../components/app-table-search/AppTableSearch";
import {remoteGetCompany, remoteGetStatDetailed, remoteGetStats, remoteGetVideo} from "../../redux/actions";
import AppAdminStatTable from "../../components/app-admin-stat-table/AppAdminStatTable";


const AdminStatDetailedIndex = () => {

    const dispatch = useDispatch()
    const apiToken = useSelector(state => state.apiToken)
    const [statistics, setStatistics] = useState([])
    const [companyObj, setCompanyObj] = useState(false)
    const [videoObj, setVideoObj] = useState(false)

    const history = useHistory()

    const {company, video} = useParams()

    useEffect(() => {

        dispatch(remoteGetStatDetailed({
            apiToken,
            company,
            video,
            cb: (stat) => {
                setStatistics(stat)
            }
        }))

        dispatch(remoteGetCompany({
            apiToken,
            companyId: company,
            cb: (companyRes) => {
                setCompanyObj(companyRes)
            }
        }))

        dispatch(remoteGetVideo({
            apiToken,
            video,
            cb: (videoRes) => {
                setVideoObj(videoRes)
            }
        }))

    }, [apiToken, company, video])

    return (
        <React.Fragment>
            <TheHeader/>
            <TheSidebarMenu activeLink="admin-stat"/>
            <AppPageCenterWrapper>
                <AppContainerWrapper>
                    <AppRow>
                        <AppPageTitleWrapper>
                            {videoObj ?
                                videoObj.description[0].name + ' - ' + companyObj.name : ''
                            }
                        </AppPageTitleWrapper>
                    </AppRow>
                    <AppRow justify="space-between">
                        <AppTableSearch/>
                    </AppRow>
                    <AppRow>
                        <AppAdminStatTable rows={statistics}/>
                    </AppRow>
                </AppContainerWrapper>
            </AppPageCenterWrapper>
        </React.Fragment>
    )
}

export default AdminStatDetailedIndex