import axios from "axios";
import config from "../config";

const authService = {
    register: (
        {
            companyName,
            companyAddress,
            postIndex,
            companyCity,
            companyCountry,
            email,
            contactPerson,
            phone,
            fieldOfActivity,
            amountOfStuff,
            accountReceiver,
            payment,
            isOfferAccepted,
            isConfidentialAccepted,
            comment
        }
    ) => {
        return axios({
            method: 'post',
            url: config.getRegisterUrl(),
            data: {
                name: companyName,
                register_address: companyAddress,
                post_index: postIndex,
                city: companyCity,
                country: companyCountry,
                email: email,
                comment: comment,
                contact_person: contactPerson,
                phone_number: phone,
                field_of_activity: fieldOfActivity,
                employee_amount: amountOfStuff,
                recipient_invoice: accountReceiver,
                payment_method: payment,
                offer_agree: isOfferAccepted,
                confidentiality_terms_agree: isConfidentialAccepted
            }
        })
    },

    login: ({email, password}) => {
        return axios({
            method: 'post',
            url: config.getLoginUrl(),
            data: {
                email,
                password
            }
        })
    }
}

export default authService;