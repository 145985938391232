import React, {useEffect} from "react";

import styled from "styled-components";
import AppContainer from "../app-container/AppContainer";
import AppRow from "../app-row/AppRow";
import AppLogo from "../app-logo/AppLogo";
import TheHeaderLinkList from "../the-header-link-list/TheHeaderLinkList";
import TheHeaderSearch from "../the-header-search/TheHeaderSearch";
import {Link} from "react-router-dom";
import TheHeaderFilter from "../the-header-filter/TheHeaderFilter";
import TheHeaderAccount from "../the-header-account/TheHeaderAccount";
import RoleService from "../../services/RoleService";
import {changeField} from "../../redux/actions";
import {useDispatch, useSelector} from "react-redux";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const HeaderWrapper = styled.header`
  width: 100%;
  height: 96px;
  background-color: #212121;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
`;

const HeaderGroupLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 610px;
  width: 100%;
`;

const HeaderAccountWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 800px) {
    width: 100%;
    justify-content: flex-end
  };
  margin-top: 10px;
`;

const AllVideoWrapper = styled.div`
  a {
    background-color: #F9F9F9;
    min-width: 200px;
    width: 100%;
    text-align: center;
    padding: 6px 16px;
    color: #2A2A2A;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    text-decoration: none;
    border-radius: 15px;

    @media (max-width: 800px) {
      display: block;
    }
  }
`;

const MobileBtnGlobalWrapper = styled.div`
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  justify-self: flex-start;
  @media (max-width: 900px) {
    display: none;
  }
`;

const MobileBtnGlobalAdaptWrapper = styled.div`
  cursor: pointer;
  height: 100%;
  align-items: center;
  justify-self: flex-start;
  display: none;
  width: 100%;
  
  @media (max-width: 900px) {
    display: flex;
  }
`;

const MobileBtnWrapper = styled.div`
  position: relative;
  width: 29px;
  height: 3px;
  background-color: #fff;

  &:before {
    content: '';
    position: absolute;
    top: -8px;
    left: 0;
    width: 29px;
    height: 3px;
    background-color: #fff;
  }

  &:after {
    content: '';
    position: absolute;
    top: 8px;
    left: 0;
    width: 29px;
    height: 3px;
    background-color: #fff;
  }
`;

const TheHeader = () => {

    const dispatch = useDispatch();
    const isSidebarActive = useSelector(state => state.isSidebarActive)
    const isLogin = useSelector(state => state.isLogin)

    const roleService = new RoleService();

    const onMobileMenuHandler = () => {
        dispatch(changeField({
            field: 'isSidebarActive',
            value: !isSidebarActive
        }))
    }

    const {width} = useWindowDimensions();

    useEffect(() => {
        if (width < 900) {
            dispatch(changeField({
                field: 'isSidebarActive',
                value: false
            }))
        }
    }, [width, dispatch]);

    if (isLogin) {
        return (
            <HeaderWrapper>
                <AppContainer role={roleService.getCompanyRole()}>
                    <AppRow justify="space-between">
                        <HeaderGroupLogo>
                            {roleService.getCompanyRole() === roleService.getCompanyRole() ?
                                <MobileBtnGlobalWrapper onClick={onMobileMenuHandler}>
                                    <MobileBtnWrapper/>
                                </MobileBtnGlobalWrapper>
                                :
                                ''
                            }

                            <AppLogo/>
                            <TheHeaderSearch/>
                        </HeaderGroupLogo>
                        <HeaderRight>
                            {roleService.getCompanyRole() === roleService.getCompanyRole() ?
                                <MobileBtnGlobalAdaptWrapper onClick={onMobileMenuHandler}>
                                    <MobileBtnWrapper/>
                                </MobileBtnGlobalAdaptWrapper>
                                :
                                <React.Fragment>
                                    <AllVideoWrapper>
                                        <Link to="/all-videos">Все доступные видео</Link>
                                    </AllVideoWrapper>
                                    <TheHeaderFilter/>
                                </React.Fragment>
                            }
                            <TheHeaderAccount/>
                        </HeaderRight>
                    </AppRow>
                </AppContainer>
            </HeaderWrapper>
        );
    } else {
        return (

            <HeaderWrapper>
                <AppContainer>
                    <AppRow justify="space-between">
                        <AppLogo/>
                        <TheHeaderLinkList/>
                    </AppRow>
                </AppContainer>
            </HeaderWrapper>
        );
    }


};

export default TheHeader;