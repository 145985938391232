import React from "react";
import styled from "styled-components";
import TheLanguageSelect from "../the-language-select/TheLanguageSelect";

import {
    Link,
} from "react-router-dom";

const TheHeaderLinkListWrapper = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;

  li:not(:last-child) {
    margin-right: 10px;
  }

  a {
    font-family: "Roboto", sans-serif;
    text-decoration: none;
    color: white;
    font-weight: 500;
    font-size: 22px;
    margin-right: 60px;
  }
`;

const TheHeaderLinkList = () => {
    return (
        <TheHeaderLinkListWrapper>
            <li>
                <Link to="/login">Войти</Link>
            </li>
            <li>
                <TheLanguageSelect/>
            </li>
        </TheHeaderLinkListWrapper>
    );
};

export default TheHeaderLinkList;