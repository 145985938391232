import React from "react";
import filterSearchIcon from "../../assets/tablefilterserarch.svg";
import filterIcon from "../../assets/tableflter.svg";
import TableFilterWrapper from "../../styles/TableFilterWrapper";
import TableFilterInputWrapper from "../../styles/TableFilterInputWrapper";

const AppTableSearch = () => {
    return (
        <TableFilterWrapper>
            <TableFilterInputWrapper>
                <img src={filterSearchIcon} alt=""/>
                <input type="text" placeholder="Поиск"/>
            </TableFilterInputWrapper>
        </TableFilterWrapper>
    )
}

export default AppTableSearch