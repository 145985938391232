const initialState = {
    companyName: '',
    companyAddress: '',
    postIndex: '',
    companyCity: '',
    companyCountry: '',
    email: '',
    contactPerson: '',
    phone: '',
    fieldOfActivity: '',
    amountOfStuff: '',
    accountReceiver: '',
    payment: '',
    isOfferAccepted: false,
    isConfidentialAccepted: false,
    comment: '',
    isShowPreloader: false,
    payments: [],
    isShowMessage: false,
    titleMessage: '',
    textMessage: '',
    apiToken: window.localStorage.getItem('api_token') ? window.localStorage.getItem('api_token'): false,
    password: '',
    userRole: false,
    isSidebarActive: true,
    isLogin: true,
    tests: [],
    companies: [],
    adminTestInfo: {
        id: 0,
        test_name: '',
        questions: []
    },
    searchResults : [],
    searchResultsMessage: '',
    isSearchResultActive: false,
    maxPagination: 1,
    remoteCategories: [],
    videos: [],
    users: [],
    specializations: [],
    statistics: []
}

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case 'REMOVE_CATEGORY':
            const removeCategories = state.remoteCategories.filter((item) => {
                return item.id != action.payload
            })

            return {
                ...state,
                remoteCategories: removeCategories
            }

        case 'REMOVE_COMPANY':
            const removeCompanies = state.companies.filter((item) => {
                return item.id != action.payload
            })

            return {
                ...state,
                companies: removeCompanies
            }

        case 'SET_SEARCH_RESULTS':
            return {
                ...state,
                searchResultsMessage: action.message,
                searchResults: action.payload
            }

        case 'REMOVE_TEST':
            const removedTests = state.tests.filter((item) => {
                return item.id != action.payload
            })

            return {
                ...state,
                tests: removedTests
            }

        case 'CHANGE_CHECKED_TEST':

            const newTests = state.tests.map((item) => {
                if ('checked' in item) {

                    if (item.id == action.payload) {
                        item.checked = !item.checked
                    }
                } else {

                    if (item.id == action.payload) {
                        item.checked = true
                    }
                }

                return  item
            })

            return {
                ...state,
                tests: newTests
            }

        case 'CHANGE_CHECKED_CATEGORY':
            const newCategories = state.remoteCategories.map((item) => {
                if ('checked' in item) {

                    if (item.id == action.payload) {
                        item.checked = !item.checked
                    }
                } else {

                    if (item.id == action.payload) {
                        item.checked = true
                    }
                }

                return  item
            })

            return {
                ...state,
                remoteCategories: newCategories
            }

        case 'CHANGE_CHECKED_COMPANY':
            const newCompanies = state.companies.map((item) => {
                if ('checked' in item) {
                    if (item.id ==action.payload) {
                        item.checked = !item.checked
                    }
                } else {
                    if (item.id == action.payload) {
                        item.checked = true
                    }
                }

                return item
            })

            return {
                ...state,
                companies: newCompanies
            }

        case 'CHANGE_FIELD':
            return {
                ...state,
                [action.payload.field]: action.payload.value
            }

        case 'SET_API_TOKEN':
            window.localStorage.setItem('api_token', action.payload)
            return {
                ...state,
                apiToken: action.payload
            }

        case 'CHANGE_PRELOADER':
            return {
                ...state,
                isShowPreloader: action.payload
            }

        case 'CHANGE_MESSAGE':
            return {
                ...state,
                isShowMessage: action.payload.isShowMessage,
                titleMessage: action.payload.titleMessage,
                textMessage: action.payload.textMessage
            }

        case 'CHANGE_ADMIN_TEST_INFO':
            return {
                ...state,
                adminTestInfo: {
                    ...state.adminTestInfo,
                    id: action.payload.id,
                    test_name: action.payload.test_name,
                    questions: action.payload.questions
                }
            }

        default:
            return state;
    }
}

export default reducer;