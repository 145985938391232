import React, {useEffect} from 'react'
import TheHeader from "../../components/the-header/TheHeader";
import TheSidebarMenu from "../../components/the-sidebar-menu/TheSidebarMenu";
import AppPageCenterWrapper from "../../components/app-page-center-wrapper/AppPageCenterWrapper";
import AppRow from "../../components/app-row/AppRow";
import AppTableFilter from "../../components/app-table-filter/AppTableFilter";
import AppendButtonWrapper from "../../styles/AppendButtonWrapper";
import AppEmployeesTable from "../../components/app-employees-table/AppEmployeesTable";
import AppTablePagination from "../../components/app-table-pagination/AppTablePagination";
import AppContainerWrapper from "../../styles/AppContainerWrapper";
import AppPageTitleWrapper from "../../styles/AppPageTitleWrapper";
import TheAdminTestFormIndex from "../../components/the-admin-test-form-index/the-admin-test-form-index";
import {useDispatch, useSelector} from "react-redux";
import {
    remoteGetAdminTests,
    remoteGetAdminUsers,
    remoteGetCategories,
    remoteGetCompanies,
    remoteGetSpecializations
} from "../../redux/actions";
import {useHistory, useParams} from "react-router-dom";
import AppTableSearch from "../../components/app-table-search/AppTableSearch";
import AdminSpecializationsTable from "./AdminSpecializationsTable";


const AdminSpecializationsIndex = () => {

    const dispatch = useDispatch()
    const apiToken = useSelector(state => state.apiToken)
    const specializations = useSelector(state => state.specializations)

    const history = useHistory()

    const {page = 1} = useParams()

    useEffect(() => {
        //TODO check if apiToken
        dispatch(remoteGetSpecializations({
            apiToken: apiToken,
            cb: () => {}
        }))
    }, [apiToken, page])

    const createTestPageHandler = () => {
        history.push('/admin/user/create')
    }

    return (
        <React.Fragment>
            <TheHeader/>
            <TheSidebarMenu activeLink="admin-specializations"/>
            <AppPageCenterWrapper>
                <AppContainerWrapper>
                    <AppRow>
                        <AppPageTitleWrapper>
                            Список специализаций
                        </AppPageTitleWrapper>
                    </AppRow>
                    <AppRow justify="space-between">
                        <AppTableSearch/>
                        <AppendButtonWrapper onClick={createTestPageHandler}>Добавить специализацию</AppendButtonWrapper>
                    </AppRow>
                    <AppRow>
                        <AdminSpecializationsTable rows={specializations}/>
                    </AppRow>
                </AppContainerWrapper>
            </AppPageCenterWrapper>
        </React.Fragment>
    )
}

export default AdminSpecializationsIndex