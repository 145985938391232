import styled from "styled-components";

const AdminQuestionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  .admin-question-item {
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    min-width: 125px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2A2A2A;
    border-radius: 6px;
    margin-top: 15px;
    cursor: pointer;
    background-color: #F8F8F8;
    border: 1px solid #E2E2E2;
  }
  
  .admin-question-item-active {
    background-color: #597ABA;
    border: 1px solid #597ABA;
    color: white;
  }
  
  .admin-question-item:not(:last-child) {
    margin-right: 20px;
  }
  
  .admin-question-item-add-btn {
    background-color: #E2E2E2;
    border: none;
    cursor: pointer;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: #606060;
    font-size: 24px;
    width: 52px;
    height: 52px;
    margin-top: 15px;
    border-radius: 6px;
  }
`

export default AdminQuestionsWrapper