import styled from "styled-components";

const AppFormRow = styled.div<{justify?: string}>`
  display: flex;
  justify-content: ${props => props.justify || 'space-between'};
  width: 100%;
  margin-top: 35px;
  
  @media (max-width: 1100px) {
    flex-direction: column;
    margin-top: 0;
  }
`;

export default AppFormRow;