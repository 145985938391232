import React from "react";
import styled from "styled-components";

const AppFormGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => props.align || 'flex-start'};
  justify-content: flex-start;
  position: relative;
  max-width: 430px;
  width: 100%;
  margin-top: 20px;

  @media (max-width: 1100px) {
    font-size: 16px;
    justify-content: center;
    align-items: center;
  }
  
  button {
    background: #597ABA;
    box-shadow: -7px 5px 33px rgba(25, 41, 124, 0.05);
    border-radius: 7px;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    color: #ffffff;
    font-weight: 500;
    padding: 15px 27px;
    border: none;
    cursor: pointer;
    transition: .3s all;
    min-width: 266px;
    
    @media (max-width: 1100px) {
      font-size: 16px;
    }
    
    &:hover {
      transition: .3s all;
      background-color: #466094;
    }
  }
`;

const AppFormGroupSubmit = ({label, align = 'flex-start'}) => {
    return (
        <AppFormGroupWrapper align={align}>
            <button type="submit">{label}</button>
        </AppFormGroupWrapper>
    );
};

export default AppFormGroupSubmit;