import styled from "styled-components";
import React from 'react'
import {useSelector} from "react-redux";

const AppContainerWrapperStyles = styled.div`
  max-width: 1659px;
  width: 100%;
  padding: 0 15px;
  padding-left: ${props => props.isActive ? '300px' : '15px'};
  transition: .3s all;

  @media (max-width: 1340px) {
    padding-left: 280px;
  }

  @media (max-width: 900px) {
    padding-left: 15px;
  }
`

const AppContainerWrapper = ({children}) => {

    const isSidebarActive = useSelector(state => state.isSidebarActive)

    return (
        <AppContainerWrapperStyles isActive={isSidebarActive}>
            {children}
        </AppContainerWrapperStyles>
    )
}

export default AppContainerWrapper