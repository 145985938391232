import React from "react";
import VideoListItem from "./VideoListItem";
import AppRow from "../app-row/AppRow";
import styled from "styled-components";
import App from "../../App";

const VideoListItemWrapper = styled.div`
  max-width: ${props => props.maxwidth || '20%'};
  width: 100%;

  @media (max-width: 1600px) {
    max-width: 25%;
  }

  @media (max-width: 1200px) {
    max-width: 33.3%;
  }

  @media (max-width: 900px) {
    max-width: 50%;
  }

  @media (max-width: 600px) {
    max-width: 100%;
    display: flex;
    justify-content: center;
  }
`;

const VideoList = ({maxwidth}) => {
    return (
        <AppRow justify="flex-start">
            <VideoListItemWrapper maxwidth={maxwidth}>
                <VideoListItem/>
            </VideoListItemWrapper>
            <VideoListItemWrapper maxwidth={maxwidth}>
                <VideoListItem/>
            </VideoListItemWrapper>
            <VideoListItemWrapper maxwidth={maxwidth}>
                <VideoListItem/>
            </VideoListItemWrapper>
            <VideoListItemWrapper maxwidth={maxwidth}>
                <VideoListItem/>
            </VideoListItemWrapper>
            <VideoListItemWrapper maxwidth={maxwidth}>
                <VideoListItem/>
            </VideoListItemWrapper>
            <VideoListItemWrapper maxwidth={maxwidth}>
                <VideoListItem/>
            </VideoListItemWrapper>
            <VideoListItemWrapper maxwidth={maxwidth}>
                <VideoListItem/>
            </VideoListItemWrapper>
            <VideoListItemWrapper maxwidth={maxwidth}>
                <VideoListItem/>
            </VideoListItemWrapper>
            <VideoListItemWrapper maxwidth={maxwidth}>
                <VideoListItem/>
            </VideoListItemWrapper>
            <VideoListItemWrapper maxwidth={maxwidth}>
                <VideoListItem/>
            </VideoListItemWrapper>
            <VideoListItemWrapper maxwidth={maxwidth}>
                <VideoListItem/>
            </VideoListItemWrapper>
            <VideoListItemWrapper maxwidth={maxwidth}>
                <VideoListItem/>
            </VideoListItemWrapper>
            <VideoListItemWrapper maxwidth={maxwidth}>
                <VideoListItem/>
            </VideoListItemWrapper>
            <VideoListItemWrapper maxwidth={maxwidth}>
                <VideoListItem/>
            </VideoListItemWrapper>
            <VideoListItemWrapper maxwidth={maxwidth}>
                <VideoListItem/>
            </VideoListItemWrapper>
        </AppRow>
    );
};

export default VideoList;