import React from "react";
import styled from "styled-components";

import videoPhoto from '../../assets/videoimage.jpg';

const CheckboxHeaderWrapper = styled.span`
  border: 2px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 3px;
  width: 20px;
  height: 20px;
  display: block;
`;

const CheckboxWrapper = styled.span`
  border: 2px solid #2A2A2A;
  box-sizing: border-box;
  border-radius: 3px;
  width: 20px;
  height: 20px;
  display: block;
`;

const AppVideoTableWrapper = styled.div`
  width: 100%;

  @media (max-width: 900px) {
    overflow-x: auto;
  }

  table {
    width: 100%;
    border-collapse: collapse;

    thead {
      tr {
        th:first-child {
          padding-left: 25px;
        }

        th {
          height: 60px;
          background: #2A2A2A;
          font-family: "Gilroy", sans-serif;
          color: #fff;
          font-size: 18px;
          font-weight: 400;
          border-bottom: 1px solid #E2E8F0;
          text-align: left;
          padding-right: 25px;

          @media (max-width: 900px) {
            font-size: 14px;
            min-height: 40px;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 7px 0;
          border-bottom: 1px solid #E2E8F0;
          font-family: "Gilroy", sans-serif;
          font-size: 16px;
          background: #FFFFFF;

          input {
            font-family: "Gilroy", sans-serif;
            font-size: 16px;
            background: #F0F0F0;
            border: 1px solid #DADADA;
            box-sizing: border-box;
            height: 44px;
            outline: none;
            cursor: pointer;
            padding: 0 20px;
          }

          @media (max-width: 900px) {
            padding-right: 20px;
          }
          
        }

        td:first-child {
          padding-left: 25px;
        }
      }
    }
  }

  .test-done-success {
    color: #2C9E31;
  }

  .test-done-medium {
    color: #D6B84F;
  }

  .test-done-failed {
    color: #E72E2E;
  }
`;

const TableVideoWrapper = styled.div`

  display: flex;

  .table-video-image {
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
    background-image: url(${videoPhoto});
    width: 120px;
    height: 68px;
  }
`;

const TableVideoTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 18px;
  max-width: 330px;
  width: 100%;

  @media (max-width: 900px) {
    width: 330px;
  }

  .table-video-text-title {
    font-family: "Gilroy", sans-serif;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #2D3748;
  }

  .table-video-text-text {
    font-family: "Gilroy", sans-serif;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #737373;
  }
`;

const UserStatVideo = () => {
    return (
        <AppVideoTableWrapper>
            <table>
                <thead>
                <tr>
                    <th>Видео</th>
                    <th>Строитель</th>
                    <th>Дата закрепления</th>
                    <th>Сдать до</th>
                    <th>Статус</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>
                        <TableVideoWrapper>
                            <div className="table-video-image"/>
                            <TableVideoTextWrapper>
                                <p className="table-video-text-title">Название Видео</p>
                                <p className="table-video-text-text">
                                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                    sed do eiusmod tempor ut labore et dolore ...
                                </p>
                            </TableVideoTextWrapper>
                        </TableVideoWrapper>
                    </td>
                    <td>Иван Иванович</td>
                    <td>00/00/00</td>
                    <td>00/00/00</td>
                    <td>
                        <span className="test-done-success">Сдан</span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <TableVideoWrapper>
                            <div className="table-video-image"/>
                            <TableVideoTextWrapper>
                                <p className="table-video-text-title">Название Видео</p>
                                <p className="table-video-text-text">
                                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                    sed do eiusmod tempor ut labore et dolore ...
                                </p>
                            </TableVideoTextWrapper>
                        </TableVideoWrapper>
                    </td>
                    <td>Иван Иванович</td>
                    <td>00/00/00</td>
                    <td>00/00/00</td>
                    <td>
                        <span className="test-done-medium">Сдан</span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <TableVideoWrapper>
                            <div className="table-video-image"/>
                            <TableVideoTextWrapper>
                                <p className="table-video-text-title">Название Видео</p>
                                <p className="table-video-text-text">
                                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                    sed do eiusmod tempor ut labore et dolore ...
                                </p>
                            </TableVideoTextWrapper>
                        </TableVideoWrapper>
                    </td>
                    <td>Иван Иванович</td>
                    <td>00/00/00</td>
                    <td>00/00/00</td>
                    <td>
                        <span className="test-done-success">Сдан</span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <TableVideoWrapper>
                            <div className="table-video-image"/>
                            <TableVideoTextWrapper>
                                <p className="table-video-text-title">Название Видео</p>
                                <p className="table-video-text-text">
                                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                    sed do eiusmod tempor ut labore et dolore ...
                                </p>
                            </TableVideoTextWrapper>
                        </TableVideoWrapper>
                    </td>
                    <td>Иван Иванович</td>
                    <td>00/00/00</td>
                    <td>00/00/00</td>
                    <td>
                        <span className="test-done-success">Сдан</span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <TableVideoWrapper>
                            <div className="table-video-image"/>
                            <TableVideoTextWrapper>
                                <p className="table-video-text-title">Название Видео</p>
                                <p className="table-video-text-text">
                                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                    sed do eiusmod tempor ut labore et dolore ...
                                </p>
                            </TableVideoTextWrapper>
                        </TableVideoWrapper>
                    </td>
                    <td>Иван Иванович</td>
                    <td>00/00/00</td>
                    <td>00/00/00</td>
                    <td>
                        <span className="test-done-success">Сдан</span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <TableVideoWrapper>
                            <div className="table-video-image"/>
                            <TableVideoTextWrapper>
                                <p className="table-video-text-title">Название Видео</p>
                                <p className="table-video-text-text">
                                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                    sed do eiusmod tempor ut labore et dolore ...
                                </p>
                            </TableVideoTextWrapper>
                        </TableVideoWrapper>
                    </td>
                    <td>Иван Иванович</td>
                    <td>00/00/00</td>
                    <td>00/00/00</td>
                    <td>
                        <span className="test-done-success">Сдан</span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <TableVideoWrapper>
                            <div className="table-video-image"/>
                            <TableVideoTextWrapper>
                                <p className="table-video-text-title">Название Видео</p>
                                <p className="table-video-text-text">
                                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                    sed do eiusmod tempor ut labore et dolore ...
                                </p>
                            </TableVideoTextWrapper>
                        </TableVideoWrapper>
                    </td>
                    <td>Иван Иванович</td>
                    <td>00/00/00</td>
                    <td>00/00/00</td>
                    <td>
                        <span className="test-done-success">Сдан</span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <TableVideoWrapper>
                            <div className="table-video-image"/>
                            <TableVideoTextWrapper>
                                <p className="table-video-text-title">Название Видео</p>
                                <p className="table-video-text-text">
                                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                    sed do eiusmod tempor ut labore et dolore ...
                                </p>
                            </TableVideoTextWrapper>
                        </TableVideoWrapper>
                    </td>
                    <td>Иван Иванович</td>
                    <td>00/00/00</td>
                    <td>00/00/00</td>
                    <td>
                        <span className="test-done-success">Сдан</span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <TableVideoWrapper>
                            <div className="table-video-image"/>
                            <TableVideoTextWrapper>
                                <p className="table-video-text-title">Название Видео</p>
                                <p className="table-video-text-text">
                                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                    sed do eiusmod tempor ut labore et dolore ...
                                </p>
                            </TableVideoTextWrapper>
                        </TableVideoWrapper>
                    </td>
                    <td>Иван Иванович</td>
                    <td>00/00/00</td>
                    <td>00/00/00</td>
                    <td>
                        <span className="test-done-success">Сдан</span>
                    </td>
                </tr>
                </tbody>
            </table>
        </AppVideoTableWrapper>
    );
}

export default UserStatVideo;