import React, {useState} from "react";
import styled from "styled-components";

const AppFormGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  max-width: 430px;
  width: 100%;
  margin-bottom: 10px;

  @media (max-width: 1100px) {
    max-width: 100%;
    margin-top: 15px;
    margin-bottom: 0;
  }
  
  label {
    position: relative;
    top: 0;
    left: 30px;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    z-index: 1;
    transition: .3s all;
    color: #373737;
    
    @media (max-width: 1100px) {
      font-size: 14px;
    }

    &:before {
      content: '';
      position: absolute;
      width: 14px;
      height: 14px;
      background-color: #E7EAED;
      border-radius: 2px;
      left: -30px;
      top: 2px;
    }
  }

  input {
    display: none;
  }

  .checkbox-active:before {
    background-color: #597ABA;
  }
`;

const AppFormGroupCheckbox = ({label, isActive, name, onChange}) => {

    const onClickHandler = (e) => {
        onChange(name, !isActive)
    };

    console.log(isActive)

    return (
        <AppFormGroupWrapper>
            <label onClick={onClickHandler} className={isActive ? 'checkbox-active' : ''}>
                {label}
            </label>
        </AppFormGroupWrapper>
    );
}

export default AppFormGroupCheckbox;