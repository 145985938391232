import React from "react"
import styled from "styled-components"
import {Link} from "react-router-dom"
import {useSelector} from "react-redux"

import profileIcon from '../../assets/profile.svg'
import cartIcon from '../../assets/cartsvg.svg'
import videoIcon from '../../assets/video.svg'
import settingsIcon from '../../assets/settings2.svg'
import nextIcon from '../../assets/next2icon.svg'
import logoutIcon from '../../assets/logout.svg'
import languageIcon from '../../assets/lang2.svg'
import categoriesIcon from '../../assets/categories.svg'
import specializationsIcon from '../../assets/specializations.svg'
import statIcon from '../../assets/stat.svg'
import companyIcon from '../../assets/companyicon.svg'
import videoIcon2 from '../../assets/videoicon.svg'

const SidebarMenuWrapper = styled.div`
  position: fixed;
  top: 96px;
  left: 0;
  transform: translateX(${props => props.isActive ? '0': '-100%'});
  width: 265px;
  background: #2B2B2B;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: .3s all;
  z-index: 1;
`;

const SidebarMenuInnerWrapper = styled.div`
  padding: 20px 26px;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  @media (max-width: 1340px) {
    justify-content: flex-start;
    height: auto;
  }
`;

const SidebarMenuInnerItemWrapper = styled.div`
  a {
    font-family: "Gilroy", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-decoration: none;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    position: relative;
    img {
      margin-right: 10px;
    }
    &:not(:last-child) {
      margin-bottom: 40px;
    }
    @media (max-width: 1300px) {
      margin-bottom: 40px;
    }
  }
  .has-submenu:before {
    content: '';
    background-image: url(${nextIcon});
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 13px;
    width: 7px;
  }
  .menu-active {
    position: relative;
    &:after {
      content: '';
      background: #4F4F4F;
      position: absolute;
      top: -100%;
      left: -26px;
      width: calc(52px + 100%);
      height: 62px;
      z-index: -1;
    }
  }
`;

const TheSidebarMenu = ({activeLink}) => {

    const isActive = useSelector(state => state.isSidebarActive)

    if (true) {
        return (
            <SidebarMenuWrapper isActive={isActive}>
                <SidebarMenuInnerWrapper>
                    <SidebarMenuInnerItemWrapper>
                        <Link className={activeLink === 'admin-companies' ? 'has-submenu menu-active': 'has-submenu'}
                              to="/admin/companies/page/1">
                            <img src={companyIcon} alt=""/>
                            Компании
                        </Link>
                        <Link className={activeLink === 'admin-users' ? 'has-submenu menu-active': 'has-submenu'}
                              to="/admin/users/page/1">
                            <img src={profileIcon} alt=""/>
                            Пользователи
                        </Link>
                        <Link className={activeLink === 'admin-videos' ? 'has-submenu menu-active': 'has-submenu'}
                              to="/admin/videos/page/1">
                            <img src={videoIcon2} alt=""/>
                            Видео
                        </Link>
                        <Link className={activeLink === 'admin-tests' ? 'has-submenu menu-active': 'has-submenu'}
                              to="/admin/test/page/1">
                            <img src={statIcon} alt=""/>
                            Тесты
                        </Link>
                        <Link className={activeLink === 'admin-stat' ? 'has-submenu menu-active': 'has-submenu'}
                              to="/admin/stat/page/1">
                            <img src={statIcon} alt=""/>
                            Статистика
                        </Link>
                        <Link className={activeLink === 'admin-categories' ? 'has-submenu menu-active': 'has-submenu'}
                              to="/admin/categories/page/1">
                            <img src={categoriesIcon} alt=""/>
                            Разделы
                        </Link>
                        <Link className={activeLink === 'admin-specializations' ? 'has-submenu menu-active': 'has-submenu'}
                              to="/admin/specializations/page/1">
                            <img src={specializationsIcon} alt=""/>
                            Специализации
                        </Link>
                        <Link to="/">
                            <img src={logoutIcon} alt=""/>
                            Выйти
                        </Link>
                        <Link to="/">
                            <img src={languageIcon} alt=""/>
                            RU
                        </Link>
                    </SidebarMenuInnerItemWrapper>
                </SidebarMenuInnerWrapper>
            </SidebarMenuWrapper>
        )
    } else {
        return (
            <SidebarMenuWrapper isActive={isActive}>
                <SidebarMenuInnerWrapper>
                    <SidebarMenuInnerItemWrapper>
                        <Link className={activeLink === 'employees' ? 'has-submenu menu-active': 'has-submenu'} to="/employees">
                            <img src={profileIcon} alt=""/>
                            Сотрудники
                        </Link>
                        <Link className={activeLink === 'video-catalog' ? 'has-submenu menu-active': 'has-submenu'} to="/company-videos">
                            <img src={cartIcon} alt=""/>
                            Каталог видео
                        </Link>
                        <Link className={activeLink === 'my-video' ? 'menu-active': ''} to="/my-videos">
                            <img src={videoIcon} alt=""/>
                            Мои видео
                        </Link>
                        <Link to="/">
                            <img src={settingsIcon} alt=""/>
                            Настройки компании
                        </Link>
                        <Link to="/">
                            <img src={logoutIcon} alt=""/>
                            Выйти
                        </Link>
                        <Link to="/">
                            <img src={languageIcon} alt=""/>
                            RU
                        </Link>
                    </SidebarMenuInnerItemWrapper>
                </SidebarMenuInnerWrapper>
            </SidebarMenuWrapper>
        )
    }


}

export default TheSidebarMenu;