import styled from "styled-components";

const FormGroupWrapper = styled.div`

  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 30px;

  select {
    background: #F8F8F8;
    border: 1px solid #E2E2E2;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    padding: 0 25px;
    height: 52px;
    margin-top: 5px;
  }

  label {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #2A2A2A;
  }

  input {
    margin-top: 5px;
    width: 100%;
    background: #F8F8F8;
    border: 1px solid #E2E2E2;
    box-sizing: border-box;
    border-radius: 6px;
    height: 52px;
    outline: none;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    padding: 0 25px;
  }

  textarea {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    border: 1px solid #E2E2E2;
    background-color: #F8F8F8;
    padding: 15px;
    border-radius: 6px;
    outline: none;
    margin-top: 8px;
  }

  .add-question-item {
    position: relative;

    input {
      padding-right: 150px;
    }
  }

  .add-question-btn {
    position: absolute;
    top: 5px;
    right: 0;
    cursor: pointer;
    width: 52px;
    height: 52px;
    border: none;
    background-color: #E2E2E2;
    color: #606060;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 24px;
    border-radius: 6px;
  }

  .mark-question-select {
    height: 52px;
    margin-right: 10px;
    margin-left: 10px;
    padding: 0 10px;
    font-family: "Roboto", sans-serif;
    border: 1px solid #E2E2E2;
    color: black;
    border-radius: 6px;
    cursor: pointer;
  }


  .remove-answer-btn {
    height: 52px;
    padding: 0 10px;
    font-family: "Roboto", sans-serif;
    border: none;
    background-color: tomato;
    color: white;
    border-radius: 6px;
    cursor: pointer;
  }

  .add-question-item-edit {
    display: flex;
    align-items: center;
  }
`

export default FormGroupWrapper