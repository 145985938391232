import React, {useEffect, useState} from 'react'
import {useHistory, useParams} from "react-router-dom";
import TheHeader from "../../components/the-header/TheHeader";
import TheSidebarMenu from "../../components/the-sidebar-menu/TheSidebarMenu";
import AppPageCenterWrapper from "../../components/app-page-center-wrapper/AppPageCenterWrapper";
import AppContainerWrapper from "../../styles/AppContainerWrapper";
import AppRow from "../../components/app-row/AppRow";
import AppPageTitleWrapper from "../../styles/AppPageTitleWrapper";
import AppTableFilter from "../../components/app-table-filter/AppTableFilter";
import AppendButtonWrapper from "../../styles/AppendButtonWrapper";
import TheAdminTestFormIndex from "../../components/the-admin-test-form-index/the-admin-test-form-index";
import AppTablePagination from "../../components/app-table-pagination/AppTablePagination";
import {useDispatch, useSelector} from "react-redux";
import {
    createAdminCategory,
    remoteCreateAdminTest, remoteGetAdminCategory,
    remoteGetAdminTestInfo,
    remoteGetCategories,
    showMessage, updateAdminCategory
} from "../../redux/actions";
import fileIcon from "../../assets/file.svg";
import FormWrapper from "../../styles/FormWrapper";
import FormGroupWrapper from "../../styles/FormGroupWrapper";
import FormGroupButtonWrapper from "../../styles/FormGroupButtonWrapper";
import AdminQuestionsWrapper from "../../styles/AdminQuestionsWrapper";
import AdminQuestionItemWrapper from "../../styles/AdminQuestionItemWrapper";
import {v4 as uuidv4} from 'uuid';

const AdminCategoryForm= () => {

    const dispatch = useDispatch()
    const [categories, setCategories] = useState([])
    const [parentId, setParentId] = useState(0)

    const {id} = useParams()

    const apiToken = useSelector(state => state.apiToken)

    const [name, setName] = useState({
        'ru': '',
        'en': '',
        'de': ''
    })

    useEffect(() => {
        dispatch(remoteGetCategories({
            apiToken,
            cb: (res) => {
                console.log(res)
                console.log('here')
                setCategories(res)
            }
        }))

        dispatch(remoteGetAdminCategory({
            apiToken,
            categoryId: id,
            cb: (res) => {
                setParentId(res.parent_id)
                const newNames = {};

                res.description.forEach((item) => {
                    newNames[item.language] = item.content
                })

                setName(newNames)
            }
        }))

    }, [apiToken, id])

    const onChangeParentId = (e) => {
        setParentId(e.target.value)
    }

    const onChangeNameHandler = (e) => {
        const language = e.target.getAttribute('data-language')

        setName({
            ...name,
            [language]: e.target.value
        })

    }

    const createCategoryHandler = () => {
        dispatch(updateAdminCategory({
            apiToken,
            category: name,
            categoryId: id,
            parent_id: parentId,
            cb: () => {
            }
        }))
    }

    return (
        <React.Fragment>
            <TheHeader/>
            <TheSidebarMenu activeLink="admin-categories"/>
            <AppPageCenterWrapper>
                <AppContainerWrapper>
                    <AppRow>
                        <AppPageTitleWrapper>
                            <p>Редактирование раздела</p>
                        </AppPageTitleWrapper>
                    </AppRow>
                    <AppRow>
                        <FormWrapper>
                            <FormGroupWrapper>
                                <label>Название раздела RU</label>
                                <input onChange={onChangeNameHandler} data-language="ru" value={name.ru} type="text"/>
                            </FormGroupWrapper>

                            <FormGroupWrapper>
                                <label>Название раздела EN</label>
                                <input onChange={onChangeNameHandler} data-language="en" value={name.en} type="text"/>
                            </FormGroupWrapper>

                            <FormGroupWrapper>
                                <label>Название раздела DE</label>
                                <input onChange={onChangeNameHandler} data-language="de" value={name.de} type="text"/>
                            </FormGroupWrapper>

                            <FormGroupWrapper>
                                <label>Родительский раздел</label>
                                <select onChange={onChangeParentId} value={parentId}>
                                    <option value="0">-Не используется-</option>
                                    {
                                        categories.map((item) => {
                                            {
                                                if (item.id != id) {
                                                    return (<option key={item.id} value={item.id}>{item.name}</option>)
                                                }
                                            }

                                        })
                                    }
                                </select>
                            </FormGroupWrapper>

                            <FormGroupButtonWrapper>
                                <button onClick={createCategoryHandler}>Сохранить изменения</button>
                            </FormGroupButtonWrapper>

                        </FormWrapper>
                    </AppRow>
                </AppContainerWrapper>
            </AppPageCenterWrapper>
        </React.Fragment>
    )
}

export default AdminCategoryForm