import React from "react";
import TheHeader from "../../components/the-header/TheHeader";
import AppContainer from "../../components/app-container/AppContainer";
import AppPageCenterWrapper from "../../components/app-page-center-wrapper/AppPageCenterWrapper";
import AppRow from "../../components/app-row/AppRow";
import AppPageTitle from "../../components/app-page-title/AppPageTitle";
import VideoList from "../../components/video-list/VideoList";
import AppPagination from "../../components/app-pagination/AppPagination";

const VideosList = () => {
    return (
        <React.Fragment>
            <TheHeader isLogin={true}/>
            <AppPageCenterWrapper>
                <AppContainer>
                    <AppRow>
                        <AppPageTitle title="Видео, доступные вам"/>
                    </AppRow>
                    <VideoList />
                    <AppRow justify="center">
                        <AppPagination />
                    </AppRow>
                </AppContainer>
            </AppPageCenterWrapper>
        </React.Fragment>
    );
};

export default VideosList;