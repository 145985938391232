import styled from "styled-components";

const AppVideoTableWrapper = styled.div`
  width: 100%;
  
  .app-table-link {
    color: #2466B0;
    text-decoration: underline;
  }

  @media (max-width: 900px) {
    overflow-x: auto;
  }

  table {
    width: 100%;
    border-collapse: collapse;

    thead {
      tr {
        th:first-child {
          padding-left: 25px;
        }

        th {
          height: 60px;
          background: #2A2A2A;
          font-family: "Gilroy", sans-serif;
          color: #fff;
          font-size: 18px;
          font-weight: 400;
          border-bottom: 1px solid #E2E8F0;
          text-align: left;
          padding-right: 25px;

          @media (max-width: 900px) {
            font-size: 14px;
            min-height: 40px;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 7px 0;
          border-bottom: 1px solid #E2E8F0;
          font-family: "Gilroy", sans-serif;
          font-size: 16px;
          background: #FFFFFF;
          height: 63px;
          
          a {
            text-decoration: none;
            color: #2D3748;;
          }

          input {
            font-family: "Gilroy", sans-serif;
            font-size: 16px;
            background: #F0F0F0;
            border: 1px solid #DADADA;
            box-sizing: border-box;
            height: 44px;
            outline: none;
            cursor: pointer;
            padding: 0 20px;
          }

          @media (max-width: 900px) {
            padding-right: 20px;
          }
          
        }

        td:first-child {
          padding-left: 25px;
        }
      }
    }
  }
`

export default AppVideoTableWrapper