import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";
import headerSearchIcon from '../../assets/header-search.png';

const HeaderSearchWrapper = styled.div`
  max-width: 380px;
  width: 100%;

  input {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #FFFFFF;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: white;
    outline: none;
    box-sizing: border-box;
    width: 100%;
    padding-bottom: 5px;
    padding-left: 50px;

    @media (max-width: 800px) {
      padding-left: 20px;
      font-size: 16px;
    }
    
    &::placeholder {
      color: white;
    }
  }

  position: relative;

  img {
    position: absolute;
    top: -5px;
    left: 0;
    width: 24px;
    height: 24px;
    
    @media (max-width: 800px) {
      width: 16px;
      height: 16px;
      top: 3px;
    }
    
  }

`;

const HeaderSearchResultWrapper = styled.div`
  position: absolute;
  display: ${props => props.show ? 'flex' : 'none'};
  flex-direction: column;
  left: 10px;
  top: 40px;
  background: #2A2A2A;
  border: 1px solid #4D4D4D;
  box-sizing: border-box;
  padding: 25px 20px;
  max-width: 400px;
  width: 100%;
  max-height: 207px;
  overflow-y: auto;
  z-index: 3;
  
  a {
    color: #FFFFFF;
    font-size: 16px;
    text-decoration: none;
    font-family: "Roboto", sans-serif;
    
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
`;

const TheHeaderSearch = () => {

    const [searchQuery, setSearchQuery] = useState('');
    const [isActive, setActive] = useState(false);

    const onSearchInput = (e) => {
        setSearchQuery(e.target.value);
        if (e.target.value.length > 0) {
            setActive(true);
        } else {
            setActive(false);
        }
    };

    const ref = useRef();

    useEffect(() => {
        const checkIfClickedOutside = e => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (isActive && ref.current && !ref.current.contains(e.target)) {
                setActive(false);
            }
        }

        document.addEventListener("mousedown", checkIfClickedOutside)

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [isActive]);

    return (
        <HeaderSearchWrapper ref={ref}>
            <img src={headerSearchIcon} alt=""/>
            <input value={searchQuery} onChange={onSearchInput} placeholder="Поиск видео" type="text"/>
            <HeaderSearchResultWrapper show={isActive}>
                <Link to="/one-video">Сварка полуавтоматом</Link>
                <Link to="/one-video">Сварка алюминия</Link>
                <Link to="/one-video">Сварка электродом</Link>
                <Link to="/one-video">Дуговая сварка</Link>
                <Link to="/one-video">Дуговая сварка</Link>
            </HeaderSearchResultWrapper>
        </HeaderSearchWrapper>
    );
};

export default TheHeaderSearch;