import styled from "styled-components";

const FormGroupFileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 30px;
  position: relative;

  label {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #2A2A2A;
  }

  img {
    width: 19px;
    height: 24px;
  }
  
  input {
    display: none;
  }
  
  span {
    margin-top: 5px;
    width: 100%;
    background: #F8F8F8;
    border: 1px solid #E2E2E2;
    box-sizing: border-box;
    border-radius: 6px;
    height: 52px;
    outline: none;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    padding: 0 25px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-left: 70px;
  }
`

export default FormGroupFileWrapper