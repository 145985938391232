import styled from "styled-components";

const TableFilterInputWrapper = styled.div`
  background: #FFFFFF;
  border: 1px solid #E2E8F0;
  box-sizing: border-box;
  border-radius: 6px;
  min-width: 277px;
  width: 100%;
  height: 40px;
  position: relative;
  padding-left: 60px;
  
  @media (max-width: 900px) {
    min-width: 240px;
  }

  img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
  }

  input {
    height: 38px;
    width: 100%;
    border: none;
    outline: none;
    font-family: "Gilroy", sans-serif;
    font-size: 16px;
    color: #2D3748;
    padding-right: 10px;

    &::placeholder {
      color: #2D3748;
    }
  }
`

export default TableFilterInputWrapper