import styled from "styled-components";

const CheckboxHeaderWrapper = styled.span`
  border: 2px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 3px;
  width: 20px;
  height: 20px;
  display: block;
`

export default CheckboxHeaderWrapper