import React, {useState} from 'react'
import {Link} from "react-router-dom";
import CheckboxWrapper from "../../styles/CheckboxWrapper";
import CheckboxHeaderWrapper from "../../styles/CheckboxHeaderWrapper";
import AppVideoTableWrapper from "../../styles/AppVideoTableWrapper";
import RemoveIcon from "../../styles/RemoveIcon";
import {useDispatch, useSelector} from "react-redux";
import {
    changeCheckedCategory, changeCheckedCompany,
    changeCheckedTest,
    remoteAdminDeleteCategory, remoteAdminDeleteCompany,
    removeCheckedTests
} from "../../redux/actions";

const AdminCompaniesTable = ({rows}) => {

    const dispatch = useDispatch()

    const apiToken = useSelector(state => state.apiToken)

    const onCheckHandler = (e) => {
        const companyId = e.target.getAttribute('data-test-id')

        dispatch(changeCheckedCompany({
            companyId
        }))
    }

    const onDeleteHandler = () => {
        const isConfirm = window.confirm('Подтверждаете удаление?')

        if (!isConfirm) return

        const idsToRemove = []

        rows.forEach((item) => {
            if (item.checked) {
                idsToRemove.push(item.id)
            }
        })

        dispatch(remoteAdminDeleteCompany({
            apiToken,
            ids: idsToRemove
        }))
    }

    return (
        <AppVideoTableWrapper>
            <table>
                <thead>
                <tr>
                    <th>
                        <RemoveIcon onClick={onDeleteHandler}/>
                    </th>
                    <th>#</th>
                    <th>Название</th>
                    <th>Email</th>
                    <th>Телефон</th>
                    <th>Кол-во сотрудников</th>
                    <th>Статистика</th>
                </tr>
                </thead>
                <tbody>
                {
                    rows.map((item) => {
                        return (
                            <tr key={item.id}>
                                <td>
                                    <CheckboxWrapper checked={item.checked}
                                                     data-test-id={item.id} onClick={onCheckHandler} />
                                </td>
                                <td>{item.id}</td>
                                <td>
                                    <Link className="app-table-link"
                                          to={`/admin/companies/${item.id}`}>{item.name}</Link>
                                </td>
                                <td>{item.email}</td>
                                <td>{item.phone_number}</td>
                                <td>{item.employee_amount}</td>
                                <td><Link className="app-table-link" to="#">Статистика</Link></td>
                            </tr>
                        )

                    })
                }

                </tbody>
            </table>
        </AppVideoTableWrapper>
    )
}

export default AdminCompaniesTable