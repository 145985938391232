import React, {useEffect, useRef, useState} from "react";
import TheHeader from "../../components/the-header/TheHeader";
import RoleService from "../../services/RoleService";
import TheSidebarMenu from "../../components/the-sidebar-menu/TheSidebarMenu";
import AppPageCenterWrapper from "../../components/app-page-center-wrapper/AppPageCenterWrapper";
import AppRow from "../../components/app-row/AppRow";
import styled from "styled-components";
import {Link} from "react-router-dom";

import profilePhoto from '../../assets/profile-ava.svg';

import useWindowDimensions from "../../hooks/useWindowDimensions";
import TheCertificates from "../../components/the-certificates/TheCertificates";
import AppVideoTable from "../../components/app-video-table/AppVideoTable";
import AppTableFilter from "../../components/app-table-filter/AppTableFilter";
import AppUserVideoTable from "../../components/app-user-video-table/AppUserVideoTable";
import AppTablePagination from "../../components/app-table-pagination/AppTablePagination";
import AppEmployeesTable from "../../components/app-employees-table/AppEmployeesTable";
import fileIcon from "../../assets/file.svg";
import AppContainerWrapper from "../../styles/AppContainerWrapper";
import AppPageTitleWrapper from "../../styles/AppPageTitleWrapper";
import FormWrapper from "../../styles/FormWrapper";
import FormGroupWrapper from "../../styles/FormGroupWrapper";
import FormGroupFileWrapper from "../../styles/FormGroupFileWrapper";
import ImageWrapper from "../../styles/ImageWrapper";
import FormGroupButtonWrapper from "../../styles/FormGroupButtonWrapper";
import {useDispatch, useSelector} from "react-redux";
import {getPayments, remoteCreateCompany, showMessage} from "../../redux/actions";

const CompanyCreateForm = () => {

    function getBase64(file, cb) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    const dispatch = useDispatch()

    const refFile = useRef();

    const onFileClickHandler = () => {
        refFile.current.click();
    }

    const [company, setCompany] = useState({
        'name': '',
        'logo': false,
        'register_address': '',
        'post_index': '',
        'city': '',
        'country': '',
        'email': '',
        'contact_person': '',
        'phone_number': '',
        'field_of_activity': '',
        'employee_amount': '',
        'recipient_invoice': '',
        'payment_method': 0,
        'comment': '',
        'password': '',
        'repeat_password': '',
    })

    const onFileUploadHandler = (e) => {
        if (e.target.files.length > 0) {
            getBase64(e.target.files[0], (base64) => {
                setCompany({
                    ...company,
                    logo: base64
                })
                //refFile.current.files = new FileList()
            })
        }
    }

    const onChangeCompany = (e) => {
        setCompany({
            ...company,
            [e.target.name]: e.target.value
        })
    }

    const payments = useSelector(state => state.payments)

    useEffect(() => {
        dispatch(getPayments())
    }, [])

    const onClearHandler = () => {
        setCompany({
            ...company,
            logo: false
        })
    }

    const apiToken = useSelector(state => state.apiToken)

    const onSubmitHandler = () => {
        if (company.password !== company.repeat_password || company.password.length < 6) {
            dispatch(showMessage({
                title: 'Ошибка!',
                message: ' Пароли должны совпадать и быть больше 6 символов'
            }))

            return
        }

        dispatch(remoteCreateCompany({
            apiToken,
            company,
            cb: (res) => {
                setCompany({
                    'name': '',
                    'logo': false,
                    'register_address': '',
                    'post_index': '',
                    'city': '',
                    'country': '',
                    'email': '',
                    'contact_person': '',
                    'phone_number': '',
                    'field_of_activity': '',
                    'employee_amount': '',
                    'recipient_invoice': '',
                    'payment_method': 0,
                    'comment': '',
                    'password': '',
                    'repeat_password': '',
                })
            }
        }))
    }

    return (
        <React.Fragment>
            <TheHeader/>
            <TheSidebarMenu activeLink="admin-companies"/>
            <AppPageCenterWrapper>
                <AppContainerWrapper>
                    <AppRow>
                        <AppPageTitleWrapper>
                            Добавить компанию
                        </AppPageTitleWrapper>
                    </AppRow>
                    <AppRow>
                        <FormWrapper>
                            <FormGroupWrapper>
                                <label>Название компании</label>
                                <input onChange={onChangeCompany} value={company.name} name="name" type="text"/>
                            </FormGroupWrapper>

                            {
                                company.logo != false ?
                                    <FormGroupWrapper>
                                        <label>Текущий логотип</label>
                                        <img style={{maxWidth: '200px', height: 'auto', marginTop: '10px'}}
                                             src={company.logo} alt=""/>
                                        <button style={{marginTop: '15px'}}
                                                onClick={onClearHandler} type="button">Очистить изображение</button>
                                    </FormGroupWrapper>
                                    :
                                    ''
                            }

                            <FormGroupFileWrapper onClick={onFileClickHandler}>
                                <label>Логотип компании</label>
                                <ImageWrapper>
                                    <img src={fileIcon} alt=""/>
                                </ImageWrapper>
                                <input onChange={onFileUploadHandler} ref={refFile} type="file"/>
                                <span>Загрузить логотип компании</span>
                            </FormGroupFileWrapper>

                            <FormGroupWrapper>
                                <label>Адрес регистрации компании</label>
                                <input name="register_address" onChange={onChangeCompany}
                                       value={company.register_address} type="text"/>
                            </FormGroupWrapper>

                            <FormGroupWrapper>
                                <label>Почтовый индекс</label>
                                <input onChange={onChangeCompany} name="post_index" value={company.post_index}
                                       type="text"/>
                            </FormGroupWrapper>

                            <FormGroupWrapper>
                                <label>Город регистрации компании</label>
                                <input onChange={onChangeCompany} name="city" value={company.city} type="text"/>
                            </FormGroupWrapper>

                            <FormGroupWrapper>
                                <label>Страна регистрации компании</label>
                                <input name="country" onChange={onChangeCompany} value={company.country} type="text"/>
                            </FormGroupWrapper>

                            <FormGroupWrapper>
                                <label>Электронная почта компании</label>
                                <input value={company.email} name="email" onChange={onChangeCompany} type="email"/>
                            </FormGroupWrapper>

                            <FormGroupWrapper>
                                <label>Контактное лицо (Имя Фамилия)</label>
                                <input value={company.contact_person} name="contact_person" onChange={onChangeCompany}
                                       type="text"/>
                            </FormGroupWrapper>

                            <FormGroupWrapper>
                                <label>Телефонный номер</label>
                                <input value={company.phone_number} name="phone_number" onChange={onChangeCompany}
                                       type="text"/>
                            </FormGroupWrapper>

                            <FormGroupWrapper>
                                <label>Сфера деятельности компании</label>
                                <input name="field_of_activity" value={company.field_of_activity}
                                       onChange={onChangeCompany} type="text"/>
                            </FormGroupWrapper>

                            <FormGroupWrapper>
                                <label>Количество сотрудников</label>
                                <input value={company.employee_amount} name="employee_amount"
                                       onChange={onChangeCompany} min={1} type="number"/>
                            </FormGroupWrapper>

                            <FormGroupWrapper>
                                <label>Получатель счёта</label>
                                <input name="recipient_invoice" value={company.recipient_invoice}
                                       onChange={onChangeCompany}
                                       type="text"/>
                            </FormGroupWrapper>

                            <FormGroupWrapper>
                                <label>Пароль</label>
                                <input value={company.password} onChange={onChangeCompany}
                                       type="password" name="password"/>
                            </FormGroupWrapper>

                            <FormGroupWrapper>
                                <label>Повторите пароль</label>
                                <input type="password" onChange={onChangeCompany}
                                       value={company.repeat_password}
                                       name="repeat_password"/>
                            </FormGroupWrapper>

                            <FormGroupWrapper>
                                <label>Способ оплаты</label>
                                <select onChange={onChangeCompany} name="payment_method" value={company.payment_method}>
                                    {
                                        payments.map((item) => {
                                            return (<option key={item.id} value={item.id}>{item.name}</option>)
                                        })
                                    }
                                </select>
                            </FormGroupWrapper>

                            <FormGroupWrapper>
                                <label>Комментарий</label>
                                <textarea name="comment" value={company.comment}
                                          onChange={onChangeCompany} />
                            </FormGroupWrapper>

                            <FormGroupButtonWrapper>
                                <button type="button" onClick={onSubmitHandler}>Сохранить изменения</button>
                            </FormGroupButtonWrapper>

                        </FormWrapper>
                    </AppRow>
                </AppContainerWrapper>
            </AppPageCenterWrapper>
        </React.Fragment>
    )
}

export default CompanyCreateForm