import React from "react";
import styled from "styled-components";
import AppTestVideoItem from "./AppTestVideoItem";

const VideoTestWrapper = styled.div`
  margin-top: 56px;
  max-width: 842px;
  width: 100%;
  
  @media (max-width: 800px) {
    margin-top: 20px;
  }
`;

const VideoTestTitleWrapper = styled.p`
  color: #424242;
  font-size: 24px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
`;

const AppTestVideo = () => {
    return (
        <VideoTestWrapper>
            <VideoTestTitleWrapper>Тестирование</VideoTestTitleWrapper>
            <AppTestVideoItem title="Вопрос 1 из 15" />
        </VideoTestWrapper>
    );
};

export default AppTestVideo;