import config from "../config";
import axios from "axios";

const publicRemoteService = {
    getPayments: () => {
        return axios({
            method: 'GET',
            url: config.getPaymentsUrl(),
        })
    },

    getUserInfo: ({apiToken}) => {
        return axios({
            method: 'GET',
            url: config.getUserInfoUrl(),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            },
        })
    },

    getAdminTests: ({apiToken, page}) => {
        return axios({
            method: 'GET',
            url: config.getAdminTestsUrl(page),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            },
        })
    },

    getAdminAllTests: ({apiToken}) => {
        return axios({
            method: 'GET',
            url: config.getAdminAllTestsUrl(),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            },
        })
    },

    getAdminTestInfo: ({apiToken, testId}) => {
        return axios({
            method: 'GET',
            url: config.getAdminTestInfoUrl({
                testId
            }),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            },
        })
    },

    createTest: ({apiToken, test}) => {
        return axios({
            method: 'POST',
            url: config.getAdminCreateTestUrl(),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            },
            data: test
        })
    },

    updateTest: ({apiToken, testId, test}) => {
        return axios({
            method: 'PUT',
            url: config.getAdminUpdateTestUrl({
                testId: testId
            }),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            },
            data: test
        })
    },

    deleteTest: ({apiToken, testId}) => {
        return axios({
            method: 'DELETE',
            url: config.getAdminDeleteTestUrl({
                testId
            }),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            }
        })
    },

    searchTest: ({apiToken, query}) => {
        return axios({
            method: 'POST',
            url: config.getAdminSearchTestUrl(),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            }
        })
    },

    getAdminCategories: ({apiToken}) => {
        return axios({
            method: 'GET',
            url: config.getAdminCategoriesUrl(),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            }
        })
    },

    getAdminCategory: ({apiToken, categoryId}) => {
        return axios({
            method: 'GET',
            url: config.getAdminCategoryUrl(categoryId),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            }
        })
    },

    createAdminCategory: ({apiToken, category, parent_id}) => {
        return axios({
            method: 'post',
            url: config.getAdminCreateCategoryUrl(),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            },
            data: {
                descriptions: category,
                parent_id
            }
        })
    },

    updateAdminCategory: ({apiToken, category, parent_id, categoryId}) => {
        return axios({
            method: 'put',
            url: config.getAdminUpdateCategoryUrl(categoryId),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            },
            data: {
                descriptions: category,
                parent_id
            }
        })
    },

    deleteCategory: ({apiToken, categoryId}) => {
        return axios({
            method: 'delete',
            url: config.getAdminDeleteCategoryUrl(categoryId),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            }
        })
    },

    deleteCompany: ({apiToken, companyId}) => {
        return axios({
            method: 'delete',
            url: config.getAdminDeleteCompanyUrl(companyId),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            }
        })
    },

    createCompany: ({apiToken, company}) => {
        return axios({
            method: 'post',
            url: config.getAdminCreateCompanyUrl(),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            },
            data: company
        })
    },

    updateCompany: ({apiToken, company}) => {
        return axios({
            method: 'put',
            url: config.getAdminUpdateCompanyUrl(company.id),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            },
            data: company
        })
    },

    getAdminCompanies: ({apiToken, page}) => {
        return axios({
            method: 'get',
            url: config.getAdminCompaniesUrl(page),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            },
        })
    },

    getAdminCompany: ({apiToken, companyId}) => {
        return axios({
            method: 'get',
            url: config.getAdminCompanyUrl(companyId),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            },
        })
    },

    getAdminAllCompanies: ({apiToken}) => {
        return axios({
            method: 'get',
            url: config.getAdminAllCompaniesUrl(),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            },
        })
    },

    getAdminVideos: ({apiToken, page}) => {
        return axios({
            method: 'get',
            url: config.getAdminVideosUrl(page),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            },
        })
    },

    getAdminVideo: ({apiToken, video}) => {
        return axios({
            method: 'get',
            url: config.getAdminVideoUrl({video}),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            },
        })
    },

    getAdminSpecializations: ({apiToken}) => {
        return axios({
            method: 'get',
            url: config.getAdminSpecializationsUrl(),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            },
        })
    },

    createVideo: ({apiToken, video}) => {
        return axios({
            method: 'post',
            url: config.getAdminCreateVideoUrl(),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            },
            data: {
                descriptions: {
                    ru: video.ru,
                    en: video.en,
                    de: video.de
                },
                test_id: video.test_id,
                category_id: video.category_id,
                available_at: video.available_at,
                specialization_id: video.specialization_id
            }
        })
    },

    getAdminUsers: ({apiToken, page}) => {
        return axios({
            method: 'get',
            url: config.getAdminUsersUrl(page),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            },
        })
    },

    getAdminAllUsers: ({apiToken}) => {
        return axios({
            method: 'get',
            url: config.getAdminAllUsersUrl(),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            },
        })
    },

    getAdminUser: ({apiToken, userId}) => {
        return axios({
            method: 'get',
            url: config.getAdminUserUrl(userId),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            }
        })
    },

    createUser: ({apiToken, user}) => {
        return axios({
            method: 'post',
            url: config.getAdminUserCreateUrl(),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            },
            data: user
        })
    },

    editUser: ({apiToken, userId, user}) => {
        return axios({
            method: 'put',
            url: config.getAdminUserEditUrl(userId),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            },
            data: user
        })
    },

    getBrigadesByCompany: ({apiToken, companyId}) => {
        return axios({
            method: 'get',
            url: config.getBrigadesByCompanyUrl(companyId),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            },
        })
    },

    getDepartmentsByCompany: ({apiToken, companyId}) => {
        return axios({
            method: 'get',
            url: config.getDepartmentsByCompanyUrl(companyId),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            },
        })
    },

    getStats: ({apiToken, page}) => {
        return axios({
            method: 'get',
            url: config.getStatsUrl(page),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            }
        })
    },

    getStatsDetailed: ({apiToken, company, video}) => {
        return axios({
            method: 'get',
            url: config.getStatDetailedUrl({company, video}),
            headers: {
                'Authorization': `Bearer ${apiToken}`
            }
        })
    }
}

export default publicRemoteService