import React from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";

import prevIcon from '../../assets/prev.svg';
import nextIcon from '../../assets/next.svg';

const PaginationWrapper = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  margin-top: 80px;
  margin-bottom: 30px;
  
  li {
    text-decoration: none;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    color: #BCC7DC;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      text-decoration: none;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
      color: #BCC7DC;
    }
  }

  .pagination-active {
    background: #7E9BD1;
    border-radius: 20px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const AppPagination = () => {
    return (
        <PaginationWrapper>
            <li><Link to=""> <img src={prevIcon} alt=""/> </Link></li>
            <li><Link to="">1</Link></li>
            <li><Link to="">2</Link></li>
            <li className="pagination-active">3</li>
            <li><Link to="">4</Link></li>
            <li><Link to="">5</Link></li>
            <li>...</li>
            <li><Link to="">8</Link></li>
            <li><Link to=""> <img src={nextIcon} alt=""/> </Link></li>
        </PaginationWrapper>
    );
}

export default AppPagination;