import styled from "styled-components";

const FormGroupButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    height: 55px;
    width: 326px;
    background: #597ABA;
    box-shadow: -7px 5px 33px rgba(25, 41, 124, 0.05);
    border-radius: 7px;
    border: none;
    cursor: pointer;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
  }
`

export default FormGroupButtonWrapper