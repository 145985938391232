import React, {useEffect} from 'react'
import TheHeader from "../../components/the-header/TheHeader";
import TheSidebarMenu from "../../components/the-sidebar-menu/TheSidebarMenu";
import AppPageCenterWrapper from "../../components/app-page-center-wrapper/AppPageCenterWrapper";
import AppRow from "../../components/app-row/AppRow";
import AppContainerWrapper from "../../styles/AppContainerWrapper";
import AppPageTitleWrapper from "../../styles/AppPageTitleWrapper";

const AdminDashboard = () => {

    return (
        <React.Fragment>
            <TheHeader/>
            <TheSidebarMenu activeLink="admin-test-index"/>
            <AppPageCenterWrapper>
                <AppContainerWrapper>
                    <AppRow>
                        <AppPageTitleWrapper>
                            Добро пожаловать!
                        </AppPageTitleWrapper>
                    </AppRow>
                </AppContainerWrapper>
            </AppPageCenterWrapper>
        </React.Fragment>
    )
}

export default AdminDashboard