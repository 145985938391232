import publicRemoteService from "../../services/publicRemoteService";
import authService from "../../services/authService";

export const setApiToken = ({apiToken}) => {
    return (dispatch) => {
        dispatch({
            type: 'SET_API_TOKEN',
            payload: apiToken
        })
    }
}

export const showPreloader = () => {
    return (dispatch) => {
        dispatch({
            type: 'CHANGE_PRELOADER',
            payload: true
        })
    }
}

export const hidePreloader = () => {
    return (dispatch) => {
        dispatch({
            type: 'CHANGE_PRELOADER',
            payload: false
        })
    }
}

export const changeField = (payload) => {
    return (dispatch) => {
        dispatch({
            type: 'CHANGE_FIELD',
            payload
        })
    }
}

export const showMessage = ({title, message}) => {
    return (dispatch) => {
        dispatch({
            type: 'CHANGE_MESSAGE',
            payload: {
                isShowMessage: true,
                titleMessage: title,
                textMessage: message
            }
        })
    }
}

export const hideMessage = () => {
    return (dispatch) => {
        dispatch({
            type: 'CHANGE_MESSAGE',
            payload: {
                isShowMessage: false,
                titleMessage: '',
                textMessage: ''
            }
        })
    }
}

export const getPayments = () => {
    return (dispatch) => {
        dispatch(showPreloader())

        publicRemoteService.getPayments()
            .then((res) => {

                const resPayments = res.data.payload;

                console.log(resPayments)

                dispatch({
                    type: 'CHANGE_FIELD',
                    payload: {
                        field: 'payments',
                        value: resPayments
                    }
                })
                dispatch(hidePreloader())
            })
            .catch((err) => {

                dispatch(hidePreloader())
            })
    }
}

export const remoteRegister = (payload) => {
    return (dispatch) => {
        dispatch(showPreloader())

        authService.register(payload)
            .then((res) => {

                dispatch(hidePreloader())

                dispatch(setApiToken({
                    apiToken: res.data.api_token
                }))

                dispatch(showMessage({
                    title: 'Регистрация успешна!',
                    message: 'Временный пароль: ' + res.data.payload.temp_password
                }))

            })
            .catch((err) => {
                dispatch(hidePreloader())
                dispatch(showMessage({
                    title: 'Ошибка!',
                    message: err.response.data.message
                }))
            })
    }
}

export const remoteLogin = (payload) => {
    return (dispatch) => {
        dispatch(showPreloader())

        authService.login(payload)
            .then((res) => {

                dispatch(setApiToken({
                    apiToken: res.data.payload.api_token
                }))

                dispatch(hidePreloader())
            }).catch((err) => {

            dispatch(showMessage({
                title: 'Ошибка!',
                message: err.response.data.message
            }))
            dispatch(hidePreloader())
        })
    }
}

export const getUserInfo = (payload) => {
    return (dispatch) => {
        dispatch(showPreloader())

        publicRemoteService.getUserInfo(payload)
            .then((res) => {

                dispatch(changeField({
                    field: 'userRole',
                    value: res.data.payload.role
                }))

                dispatch(changeField({
                    field: 'email',
                    value: res.data.payload.email
                }))

                dispatch(hidePreloader())
            })
            .catch((err) => {
                dispatch(hidePreloader())
            })
    }
}

export const remoteGetAdminTests = ({apiToken, page}) => {
    return (dispatch) => {
        dispatch(showPreloader())

        publicRemoteService.getAdminTests({
            apiToken,
            page
        }).then((res) => {
            const tests = res.data.payload.data

            dispatch({
                type: 'CHANGE_FIELD',
                payload: {
                    field: 'tests',
                    value: tests
                }
            })

            dispatch(changeField({
                field: 'maxPagination',
                value: res.data.payload.pagination.last_page
            }))

            dispatch(hidePreloader())
        }).catch((err) => {
            dispatch(hidePreloader())
        })
    }
}

export const remoteGetAdminTestsAll = ({apiToken, cb}) => {
    return (dispatch) => {
        dispatch(showPreloader())

        publicRemoteService.getAdminAllTests({
            apiToken,
        }).then((res) => {
            const tests = res.data.payload.data

            cb(tests)

            dispatch(hidePreloader())
        }).catch((err) => {
            dispatch(hidePreloader())
        })
    }
}

export const remoteGetAdminTestInfo = ({apiToken, testId, cb}) => {
    return (dispatch) => {
        dispatch(showPreloader())

        publicRemoteService.getAdminTestInfo({
            apiToken,
            testId
        }).then((res) => {
            const testInfo = res.data.payload

            // dispatch({
            //     type: 'CHANGE_ADMIN_TEST_INFO',
            //     payload: testInfo
            // })

            cb(testInfo)

            dispatch(hidePreloader())
        }).catch((err) => {
            dispatch(hidePreloader())
        })
    }
}

export const remoteCreateAdminTest = ({apiToken, test, cb}) => {
    return (dispatch) => {
        dispatch(showPreloader())

        publicRemoteService.createTest({
            apiToken,
            test
        }).then((res) => {
            cb(res)
            dispatch(hidePreloader())
        }).catch((err) => {
            dispatch(hidePreloader())
        })
    }
}

export const remoteUpdateAdminTest = ({apiToken, testId, test, cb}) => {
    return (dispatch) => {
        dispatch(showPreloader())

        publicRemoteService.updateTest({
            apiToken,
            testId,
            test
        }).then((res) => {
            cb(res)
            dispatch(hidePreloader())
        }).catch((err) => {
            dispatch(hidePreloader())
        })
    }
}

export const changeCheckedTest = ({testId}) => {
    return (dispatch) => {
        dispatch({
            type: 'CHANGE_CHECKED_TEST',
            payload: testId
        })
    }
}

export const changeCheckedCategory = ({categoryId}) => {
    return (dispatch) => {
        dispatch({
            type: 'CHANGE_CHECKED_CATEGORY',
            payload: categoryId
        })
    }
}

export const changeCheckedCompany = ({companyId}) => {
    return (dispatch) => {
        dispatch({
            type: 'CHANGE_CHECKED_COMPANY',
            payload: companyId
        })
    }
}

export const removeCheckedTests = ({apiToken, testIds}) => {
    return (dispatch) => {

        testIds.forEach((testId) => {

            dispatch(showPreloader())

            publicRemoteService.deleteTest({
                apiToken,
                testId
            }).then((res) => {
                dispatch({
                    type: 'REMOVE_TEST',
                    payload: testId
                })

                dispatch(hidePreloader())

            }).catch((err) => {
                dispatch(hidePreloader())
            })
        })
    }
}

export const searchTest = ({apiToken, query}) => {
    return (dispatch) => {

        publicRemoteService.searchTest({
            apiToken,
            query
        }).then((res) => {
            const results = res.data
            dispatch({
                type: 'SET_SEARCH_RESULTS',
                payload: results
            })
        }).catch((err) => {

        })
    }
}

export const remoteGetCategories = ({apiToken, cb}) => {
    return (dispatch) => {

        dispatch(showPreloader())

        publicRemoteService.getAdminCategories({
            apiToken
        }).then((res) => {

            dispatch(hidePreloader())

            const categories = res.data.payload.data

            console.log(categories)

            cb(categories)

            dispatch(changeField({
                field: 'remoteCategories',
                value: categories
            }))

        }).catch((err) => {
            dispatch(hidePreloader())
        })
    }
}

export const createAdminCategory = ({apiToken, category, parent_id, cb}) => {
    return (dispatch) => {
        dispatch(showPreloader())

        publicRemoteService.createAdminCategory({
            apiToken,
            category,
            parent_id
        }).then((res) => {

            dispatch(hidePreloader())

            dispatch(showMessage({
                title: 'Операция успешна!',
                message: 'Категория успешно создана'
            }))

            cb()
        }).catch((err) => {
            dispatch(hidePreloader())
        })
    }
}

export const updateAdminCategory = ({apiToken, category, parent_id, categoryId, cb}) => {
    return (dispatch) => {
        dispatch(showPreloader())

        publicRemoteService.updateAdminCategory({
            apiToken,
            category,
            categoryId,
            parent_id
        }).then((res) => {
            dispatch(hidePreloader())

            cb(res)

            dispatch(showMessage({
                title: 'Операция успешна!',
                message: 'Категория успешно обновлена'
            }))
        }).catch((err) => {
            dispatch(hidePreloader())
        })
    }
}

export const remoteGetAdminCategory = ({apiToken, categoryId, cb}) => {
    return (dispatch) => {
        dispatch(showPreloader())

        publicRemoteService.getAdminCategory({
            apiToken,
            categoryId
        }).then((res) => {
            cb(res.data.payload)
            dispatch(hidePreloader())
        }).catch((err) => {
            dispatch(hidePreloader())
        })

    }
}

export const remoteAdminDeleteCategory = ({apiToken, ids}) => {
    return (dispatch) => {

        ids.forEach((categoryId) => {

            dispatch(showPreloader())

            publicRemoteService
                .deleteCategory({apiToken, categoryId})
                .then((res) => {
                    dispatch(hidePreloader())
                    dispatch({
                        type: 'REMOVE_CATEGORY',
                        payload: categoryId
                    })
                }).catch((err) => {
                dispatch(hidePreloader())
            })
        })
    }
}

export const remoteAdminDeleteCompany = ({apiToken, ids}) => {
    return (dispatch) => {

        ids.forEach((companyId) => {

            dispatch(showPreloader())

            publicRemoteService
                .deleteCompany({apiToken, companyId})
                .then((res) => {
                    dispatch(hidePreloader())
                    dispatch({
                        type: 'REMOVE_COMPANY',
                        payload: companyId
                    })
                }).catch((err) => {
                dispatch(hidePreloader())
            })
        })
    }
}

export const remoteCreateCompany = ({apiToken, company, cb}) => {
    return (dispatch) => {
        dispatch(showPreloader())

        publicRemoteService.createCompany({
            apiToken,
            company
        }).then((res) => {
            dispatch(hidePreloader())
            dispatch(showMessage({
                title: 'Операция успешна!',
                message: 'Компания успешно создана'
            }))
            cb(res)
        }).catch((err) => {
            dispatch(hidePreloader())
        })
    }
}

export const remoteUpdateCompany = ({apiToken, company, cb}) => {
    return (dispatch) => {
        dispatch(showPreloader())

        publicRemoteService.updateCompany({
            apiToken,
            company
        }).then((res) => {
            dispatch(hidePreloader())
            dispatch(showMessage({
                title: 'Операция успешна!',
                message: 'Компания успешно обновлена'
            }))
            cb(res)
        }).catch((err) => {
            dispatch(hidePreloader())
        })
    }
}

export const remoteGetCompanies = ({apiToken, page}) => {
    return (dispatch) => {
        dispatch(showPreloader())

        publicRemoteService.getAdminCompanies({
            apiToken,
            page
        }).then((res) => {
            const companies = res.data.payload.data

            dispatch({
                type: 'CHANGE_FIELD',
                payload: {
                    field: 'companies',
                    value: companies
                }
            })

            dispatch(changeField({
                field: 'maxPagination',
                value: res.data.payload.pagination.last_page
            }))

            dispatch(hidePreloader())
        }).catch((err) => {
            dispatch(hidePreloader())
        })
    }
}

export const remoteGetCompany = ({apiToken, companyId, cb}) => {
    return (dispatch) => {
        dispatch(showPreloader())

        publicRemoteService.getAdminCompany({
            apiToken,
            companyId
        }).then((res) => {
            const company = res.data.payload

            cb(company)

            dispatch(hidePreloader())
        }).catch((err) => {
            dispatch(hidePreloader())
        })
    }
}

export const remoteGetAllCompanies = ({apiToken, cb}) => {
    return (dispatch) => {
        dispatch(showPreloader())

        publicRemoteService.getAdminAllCompanies({
            apiToken
        }).then((res) => {
            const companies = res.data.payload.data

            cb(companies)

            dispatch(hidePreloader())
        }).catch((err) => {
            dispatch(hidePreloader())
        })
    }
}

export const remoteGetVideos = ({apiToken, page}) => {
    return (dispatch) => {
        dispatch(showPreloader())

        publicRemoteService.getAdminVideos({
            apiToken,
            page
        }).then((res) => {

            const videos = res.data.payload.data

            dispatch({
                type: 'CHANGE_FIELD',
                payload: {
                    field: 'videos',
                    value: videos
                }
            })

            dispatch(changeField({
                field: 'maxPagination',
                value: res.data.payload.pagination.last_page
            }))

            dispatch(hidePreloader())
        }).catch((err) => {
            dispatch(hidePreloader())
        })
    }
}

export const remoteGetVideo = ({apiToken, video, cb}) => {
    return (dispatch) => {
        dispatch(showPreloader())

        publicRemoteService.getAdminVideo({apiToken, video})
            .then((res) => {
                cb(res.data.payload)
                dispatch(hidePreloader())
            })
            .catch((err) => {
                dispatch(hidePreloader())
            })
    }
}

export const remoteGetSpecializations = ({apiToken, cb}) => {
    return (dispatch) => {
        dispatch(showPreloader())

        publicRemoteService.getAdminSpecializations({
            apiToken
        }).then((res) => {
            const specializations = res.data.payload.data
            cb(specializations)

            dispatch(changeField({
                field: 'specializations',
                value: specializations
            }))

            dispatch(hidePreloader())
        }).catch((err) => {
            dispatch(hidePreloader())
        })
    }
}

export const remoteCreateVideo = ({apiToken, video, cb}) => {
    return (dispatch) => {
        dispatch(showPreloader())

        publicRemoteService.createVideo({
            apiToken,
            video
        }).then((res) => {

            cb(res)

            dispatch(hidePreloader())
        }).catch((err) => {
            dispatch(hidePreloader())
        })
    }
}

export const remoteGetAdminUsers = ({apiToken, page}) => {
    return (dispatch) => {
        dispatch(showPreloader())

        publicRemoteService.getAdminUsers({
            apiToken,
            page
        }).then((res) => {

            const videos = res.data.payload.data

            dispatch({
                type: 'CHANGE_FIELD',
                payload: {
                    field: 'users',
                    value: videos
                }
            })

            dispatch(changeField({
                field: 'maxPagination',
                value: res.data.payload.pagination.last_page
            }))

            dispatch(hidePreloader())
        }).catch((err) => {
            dispatch(hidePreloader())
        })
    }
}

export const remoteGetAdminUser = ({apiToken, userId, cb}) => {
    return (dispatch) => {
        dispatch(showPreloader())

        publicRemoteService.getAdminUser({
            apiToken,
            userId
        })
            .then((res) => {
                cb(res.data.payload)
                dispatch(hidePreloader())
            })
            .catch((err) => {
                dispatch(hidePreloader())
            })
    }
}

export const remoteGetAdminAllUsers = ({apiToken, cb}) => {
    return (dispatch) => {
        dispatch(showPreloader())

        publicRemoteService.getAdminAllUsers({apiToken})
            .then((res) => {

                cb(res.data.payload.data)

                dispatch(hidePreloader())
            })
            .catch((err) => {
                dispatch(hidePreloader())
            })
    }
}

export const remoteCreateUser = ({apiToken, user, cb}) => {
    return (dispatch) => {
        dispatch(showPreloader())

        publicRemoteService.createUser({
            apiToken,
            user
        }).then((res) => {

            cb(res)

            dispatch(showMessage({
                title: 'Операция успешна!',
                message: 'Пользователь успешно добавлен'
            }))

            dispatch(hidePreloader())
        }).catch((err) => {
            dispatch(hidePreloader())
        })
    }
}

export const remoteEditUser = ({apiToken, user, userId, cb}) => {
    return (dispatch) => {
        dispatch(showPreloader())

        publicRemoteService.editUser({
            apiToken,
            userId,
            user
        }).then((res) => {

            cb(res)

            dispatch(showMessage({
                title: 'Операция успешна!',
                message: 'Пользователь успешно обновлен'
            }))

            dispatch(hidePreloader())
        }).catch((err) => {
            dispatch(hidePreloader())
        })
    }
}

export const remoteGetBrigadesByCompanyId = ({apiToken, companyId, cb}) => {
    return (dispatch) => {
        dispatch(showPreloader())

        publicRemoteService.getBrigadesByCompany({
            apiToken,
            companyId
        }).then((res) => {
            cb(res.data.payload)
            dispatch(hidePreloader())
        }).catch((err) => {
            dispatch(hidePreloader())
        })
    }
}

export const remoteGetDepartmentsByCompanyId = ({apiToken, companyId, cb}) => {
    return (dispatch) => {
        dispatch(showPreloader())

        publicRemoteService.getDepartmentsByCompany({
            apiToken,
            companyId
        }).then((res) => {
            cb(res.data.payload)
            dispatch(hidePreloader())
        }).catch((err) => {
            dispatch(hidePreloader())
        })
    }
}

export const remoteGetStats = ({apiToken, page}) => {
    return (dispatch) => {

        console.log('after dispatch')

        dispatch(showPreloader())

        publicRemoteService
            .getStats({apiToken, page})
            .then((res) => {

                const videos = res.data.payload.data

                dispatch({
                    type: 'CHANGE_FIELD',
                    payload: {
                        field: 'statistics',
                        value: videos
                    }
                })

                dispatch(changeField({
                    field: 'maxPagination',
                    value: res.data.payload.pagination.last_page
                }))

                dispatch(hidePreloader())
            })
            .catch((err) => {
                dispatch(hidePreloader())
            })
    }
}

export const remoteGetStatDetailed = ({apiToken, company, video, cb}) => {
    return (dispatch) => {
        dispatch(showPreloader())

        publicRemoteService.getStatsDetailed({apiToken, company, video})
            .then((res) => {

                cb(res.data.payload)

                dispatch(hidePreloader())
            })
            .catch((err) => {
                dispatch(hidePreloader())
            })
    }
}