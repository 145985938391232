import React, {useEffect, useState} from 'react'
import {useHistory, useParams} from "react-router-dom";
import TheHeader from "../../components/the-header/TheHeader";
import TheSidebarMenu from "../../components/the-sidebar-menu/TheSidebarMenu";
import AppPageCenterWrapper from "../../components/app-page-center-wrapper/AppPageCenterWrapper";
import AppContainerWrapper from "../../styles/AppContainerWrapper";
import AppRow from "../../components/app-row/AppRow";
import AppPageTitleWrapper from "../../styles/AppPageTitleWrapper";
import AppTableFilter from "../../components/app-table-filter/AppTableFilter";
import AppendButtonWrapper from "../../styles/AppendButtonWrapper";
import TheAdminTestFormIndex from "../../components/the-admin-test-form-index/the-admin-test-form-index";
import AppTablePagination from "../../components/app-table-pagination/AppTablePagination";
import {useDispatch, useSelector} from "react-redux";
import {
    createAdminCategory,
    remoteCreateAdminTest, remoteCreateVideo,
    remoteGetAdminTestInfo, remoteGetAdminTestsAll,
    remoteGetCategories, remoteGetSpecializations,
    showMessage
} from "../../redux/actions";
import fileIcon from "../../assets/file.svg";
import FormWrapper from "../../styles/FormWrapper";
import FormGroupWrapper from "../../styles/FormGroupWrapper";
import FormGroupButtonWrapper from "../../styles/FormGroupButtonWrapper";
import AdminQuestionsWrapper from "../../styles/AdminQuestionsWrapper";
import AdminQuestionItemWrapper from "../../styles/AdminQuestionItemWrapper";
import {v4 as uuidv4} from 'uuid';

const AdminVideoCreate = () => {

    const dispatch = useDispatch()
    const [categories, setCategories] = useState([])
    const [tests, setTests] = useState([])
    const [specializations, setSpecializations] = useState([])

    const apiToken = useSelector(state => state.apiToken)

    useEffect(() => {
        dispatch(remoteGetCategories({
            apiToken,
            cb: (res) => {
                console.log(res)
                setCategories(res)
            }
        }))

        dispatch(remoteGetAdminTestsAll({
            apiToken,
            cb: (res) => {
                console.log(res)
                setTests(res)
            }
        }))

        dispatch(remoteGetSpecializations({
            apiToken,
            cb: (res) => {
                setSpecializations(res)
            }
        }))

    }, [apiToken])

    const [video, setVideo] = useState({
        'ru': {
            name: '',
            url: '',
            demoUrl: '',
            description: ''
        },
        'en': {
            name: '',
            url: '',
            demoUrl: '',
            description: ''
        },
        'de': {
            name: '',
            url: '',
            demoUrl: '',
            description: ''
        },
        category_id: 0,
        available_at: '',
        test_id: 0,
        specialization_id: 0
    })

    const onChangeLanguageHandler = (e) => {
        const language = e.target.getAttribute('data-language')

        setVideo({
            ...video,
            [language]: {
                ...video[language],
                [e.target.name]: e.target.value
            }
        })

    }

    const onChangeVideoHandler = (e) => {
        setVideo({
            ...video,
            [e.target.name]: e.target.value
        })
    }

    const createCategoryHandler = () => {
        dispatch(remoteCreateVideo({
            apiToken,
            video,
            cb: () => {
                dispatch(showMessage({
                    title: 'Операция успешна!',
                    message: 'Видео успешно добавлено'
                }))

                setVideo({
                    'ru': {
                        name: '',
                        url: '',
                        demoUrl: '',
                        description: ''
                    },
                    'en': {
                        name: '',
                        url: '',
                        demoUrl: '',
                        description: ''
                    },
                    'de': {
                        name: '',
                        url: '',
                        demoUrl: '',
                        description: ''
                    },
                    category_id: 0,
                    available_at: '',
                    test_id: 0,
                    specialization_id: 0
                })
            }
        }))
    }

    return (
        <React.Fragment>
            <TheHeader/>
            <TheSidebarMenu activeLink="admin-videos"/>
            <AppPageCenterWrapper>
                <AppContainerWrapper>
                    <AppRow>
                        <AppPageTitleWrapper>
                            <p>Добавление видео</p>
                        </AppPageTitleWrapper>
                    </AppRow>
                    <AppRow>
                        <FormWrapper>

                            <FormGroupWrapper>
                                <label>Название видео RU</label>
                                <input onChange={onChangeLanguageHandler} data-language="ru" value={video.ru.name}
                                       name="name" type="text"/>
                            </FormGroupWrapper>

                            <FormGroupWrapper>
                                <label>Название видео EN</label>
                                <input onChange={onChangeLanguageHandler} data-language="en" value={video.en.name}
                                       name="name"
                                       type="text"/>
                            </FormGroupWrapper>

                            <FormGroupWrapper>
                                <label>Название видео DE</label>
                                <input onChange={onChangeLanguageHandler} data-language="de" value={video.de.name}
                                       name="name"
                                       type="text"/>
                            </FormGroupWrapper>

                            <FormGroupWrapper>
                                <label>Ссылка на видео RU</label>
                                <input onChange={onChangeLanguageHandler} data-language="ru" value={video.ru.url}
                                       name="url" type="text"/>
                            </FormGroupWrapper>

                            <FormGroupWrapper>
                                <label>Ссылка на видео EN</label>
                                <input onChange={onChangeLanguageHandler} data-language="en" value={video.en.url}
                                       name="url"
                                       type="text"/>
                            </FormGroupWrapper>

                            <FormGroupWrapper>
                                <label>Ссылка на видео DE</label>
                                <input onChange={onChangeLanguageHandler} data-language="de" value={video.de.url}
                                       name="url"
                                       type="text"/>
                            </FormGroupWrapper>

                            <FormGroupWrapper>
                                <label>Ссылка на демо видео RU</label>
                                <input onChange={onChangeLanguageHandler} data-language="ru" value={video.ru.demo_url}
                                       name="demo_url" type="text"/>
                            </FormGroupWrapper>

                            <FormGroupWrapper>
                                <label>Ссылка на демо видео EN</label>
                                <input onChange={onChangeLanguageHandler} data-language="en" value={video.en.demo_url}
                                       name="demo_url"
                                       type="text"/>
                            </FormGroupWrapper>

                            <FormGroupWrapper>
                                <label>Ссылка на демо видео DE</label>
                                <input onChange={onChangeLanguageHandler} data-language="de" value={video.de.demo_url}
                                       name="demo_url"
                                       type="text"/>
                            </FormGroupWrapper>

                            <FormGroupWrapper>
                                <label>Описание видео RU</label>
                                <textarea onChange={onChangeLanguageHandler} data-language="ru"
                                          value={video.ru.description}
                                          name="description"/>
                            </FormGroupWrapper>

                            <FormGroupWrapper>
                                <label>Описание видео EN</label>
                                <textarea onChange={onChangeLanguageHandler} data-language="en"
                                          value={video.en.description}
                                          name="description"/>
                            </FormGroupWrapper>

                            <FormGroupWrapper>
                                <label>Описание видео DE</label>
                                <textarea onChange={onChangeLanguageHandler} data-language="de"
                                          value={video.de.description}
                                          name="description"/>
                            </FormGroupWrapper>

                            <FormGroupWrapper>
                                <label>Ссылка на демо видео RU</label>
                                <input onChange={onChangeVideoHandler} value={video.available_at}
                                       name="available_at" type="date"/>
                            </FormGroupWrapper>

                            <FormGroupWrapper>
                                <label>Раздел</label>
                                <select value={video.category_id} onChange={onChangeVideoHandler} name="category_id">
                                    {
                                        categories.map((item) => {
                                            return (<option value={item.id}>{item.name}</option>)
                                        })
                                    }
                                </select>
                            </FormGroupWrapper>

                            <FormGroupWrapper>
                                <label>Специализация</label>
                                <select value={video.specialization_id} onChange={onChangeVideoHandler}
                                        name="specialization_id">
                                    {
                                        specializations.map((item) => {
                                            return (<option value={item.id}>{item.name}</option>)
                                        })
                                    }
                                </select>
                            </FormGroupWrapper>

                            <FormGroupWrapper>
                                <label>Тест</label>
                                <select value={video.test_id} onChange={onChangeVideoHandler} name="test_id">
                                    {
                                        tests.map((item) => {
                                            return (<option value={item.id}>{item.name}</option>)
                                        })
                                    }
                                </select>
                            </FormGroupWrapper>

                            <FormGroupButtonWrapper>
                                <button onClick={createCategoryHandler}>Сохранить изменения</button>
                            </FormGroupButtonWrapper>

                        </FormWrapper>
                    </AppRow>
                </AppContainerWrapper>
            </AppPageCenterWrapper>
        </React.Fragment>
    )
}

export default AdminVideoCreate