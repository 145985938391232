import React, {useState, useRef, useEffect} from "react";
import styled from "styled-components";

const AppFormGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  max-width: ${props => props.maxwidth || '430px'};
  width: 100%;
  
  @media (max-width: 1100px) {
    max-width: 100%;
    margin-top: 30px;
  }

  label {
    position: absolute;
    top: 0;
    left: 5px;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    color: #606060;
    z-index: 1;
    transition: .3s all;
    @media (max-width: 1100px) {
      font-size: 14px;
    }
  }

  input {
    border: none;
    border-bottom: 1px solid #C6C6C6;
    background-color: transparent;
    outline: none;
    width: 100%;
    box-sizing: border-box;
    padding-left: 5px;
    padding-bottom: 5px;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    color: #606060;
    z-index: 2;
  }

  .label-active {
    top: -20px;
    transition: .3s all;
    font-size: 14px;
  }
`;

const AppFormGroupInput = ({label, name, value, onChange, maxwidth = '430px', type= 'text'}) => {

    const ref = useRef();
    const [isActive, setActive] = useState(false);

    const onActiveHandler = (e) => {
        setActive(true);
    }

    const onChangeHandler = (e) => {
        onChange(e.target.name, e.target.value)
    };

    useEffect(() => {
        const checkIfClickedOutside = e => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (isActive && ref.current && !ref.current.contains(e.target)) {

                if (value.length === 0) {
                    setActive(false);
                } else {
                    setActive(true)
                }
            }
        }

        document.addEventListener("mousedown", checkIfClickedOutside)

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [isActive, value]);

    return (
        <AppFormGroupWrapper maxwidth={maxwidth} ref={ref}>
            <label className={isActive ? 'label-active' : ''}>{label}</label>
            <input type={type} name={name} value={value} onChange={onChangeHandler} onFocus={onActiveHandler}/>
        </AppFormGroupWrapper>
    );
};

export default AppFormGroupInput;