const config = {
    apiUrl: 'http://testplatform.ar4u.nl/api',
    apiVersion: 'v1',
    language: 'ru',

    _getBaseUrl: function() {
        return this.apiUrl + '/' + this.apiVersion
    },

    getRegisterUrl: function() {
        return this._getBaseUrl() + '/register'
    },

    getLoginUrl: function() {
        return this._getBaseUrl() + '/login'
    },

    getPaymentsUrl: function() {
        return this._getBaseUrl() + '/payments'
    },

    getResetPasswordUrl: function() {

    },

    getUserInfoUrl: function() {
        return this._getBaseUrl() + '/user/info'
    },

    getAdminRole: function() {
        return  'ADMIN'
    },

    getAdminTestsUrl: function(page) {
        return this._getBaseUrl() + '/admin/tests/pages?page=' + page
    },

    getAdminAllTestsUrl: function() {
        return this._getBaseUrl() + '/admin/tests/all'
    },

    getAdminTestInfoUrl: function({testId}) {
        return this._getBaseUrl() + '/admin/tests/' + testId
    },

    getAdminCreateTestUrl: function() {
        return this._getBaseUrl() + '/admin/test'
    },

    getAdminUpdateTestUrl: function({testId}) {
        return this._getBaseUrl() + '/admin/tests/' + testId
    },

    getAdminDeleteTestUrl: function({testId}) {
        return this._getBaseUrl() + '/admin/tests/' + testId
    },

    getAdminSearchTestUrl: function() {
        return this._getBaseUrl() + '/admin/search/test'
    },

    getAdminCategoriesUrl: function() {
        return this._getBaseUrl() + '/admin/categories'
    },

    getAdminCategoryUrl: function(categoryId) {
        return this._getBaseUrl() + '/admin/categories/' + categoryId
    },

    getAdminCreateCategoryUrl: function() {
        return this._getBaseUrl() + '/admin/category'
    },

    getAdminUpdateCategoryUrl: function(categoryId) {
        return this._getBaseUrl() + '/admin/category/' + categoryId
    },

    getAdminDeleteCategoryUrl: function(categoryId) {
        return this._getBaseUrl() + '/admin/category/' + categoryId
    },

    getAdminDeleteCompanyUrl: function(companyId) {
        return this._getBaseUrl() + '/admin/company/' + companyId
    },

    getAdminCreateCompanyUrl: function() {
        return this._getBaseUrl() + '/admin/company'
    },

    getAdminUpdateCompanyUrl: function(companyId) {
        return this._getBaseUrl() + '/admin/company/' + companyId
    },

    getAdminCompaniesUrl: function(page) {
        return this._getBaseUrl() + '/admin/companies?page=' + page
    },

    getAdminCompanyUrl: function(companyId) {
        return this._getBaseUrl() + '/admin/companies/' + companyId
    },

    getAdminAllCompaniesUrl: function() {
        return this._getBaseUrl() + '/admin/companies/all'
    },

    getAdminVideosUrl: function(page) {
        return this._getBaseUrl() + '/admin/videos?page=' + page
    },

    getAdminVideoUrl: function({video}) {
        return this._getBaseUrl() + '/admin/videos/' + video
    },

    getAdminSpecializationsUrl: function() {
        return this._getBaseUrl() + '/admin/specializations'
    },

    getAdminCreateVideoUrl: function() {
        return this._getBaseUrl() + '/admin/video'
    },

    getAdminUsersUrl: function(page) {
        return this._getBaseUrl() + '/admin/users?page=' + page
    },

    getAdminAllUsersUrl: function() {
        return this._getBaseUrl() + '/admin/users/all'
    },

    getAdminUserCreateUrl: function() {
        return this._getBaseUrl() + '/admin/user'
    },

    getAdminUserEditUrl: function(userId) {
      return this._getBaseUrl() + '/admin/user/' + userId
    },

    getAdminUserDeleteUrl: function(userId) {
        return this._getBaseUrl() + '/admin/user/' + userId
    },

    getAdminUserUrl: function(userId) {
        return this._getBaseUrl() + '/admin/users/' + userId
    },

    getBrigadesByCompanyUrl: function(companyId) {
        return this._getBaseUrl() + '/admin/brigades/company/' + companyId
    },

    getDepartmentsByCompanyUrl: function(companyId) {
        return this._getBaseUrl() + '/admin/departments/company/' + companyId
    },

    getStatsUrl: function(page) {
        return this._getBaseUrl() + '/admin/stat/pages?page=' + page
    },

    getStatDetailedUrl: function({company, video}) {
        return this._getBaseUrl() + '/admin/stat/' + company + '/' + video
    }
}

export default config;