import React, {useState} from "react";
import downArrowIcon from '../../assets/downarrow.svg';
import styled from "styled-components";

const LanguageSelectWrapper = styled.div`
  background-color: transparent;
  border: none;
  font-family: "Roboto", sans-serif;
  font-size: 22px;
  font-weight: 500;
  color: #ffffff;
  position: relative;
`;

const LanguageSelectVisibleWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  cursor: pointer;

  img {
    margin-left: 10px;
    margin-bottom: 7px;
    width: 14px;
    height: 7px;
  }
`;

interface isActiveI {
    isActive: boolean
}

const LanguageSelectVariantsWrapper = styled.div`
  position: absolute;
  display: ${(props: isActiveI) => props.isActive ? 'block' : 'none'};
  background: #2A2A2A;
  border: 1px solid #3A3A3A;
  box-sizing: border-box;
  padding: 25px 30px;
  top: 35px;
  
  > div:not(:last-child) {
    margin-bottom: 15px;
  }
  
  @media (max-width: 1340px) {
    left: -20px;
  }
`;

const TheHeaderFilterItemWrapper = styled.div`
  position: relative;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  cursor: pointer;
`;

const TheLanguageSelect = () => {

    const [isActive, setActive] = useState(false);
    const [currentLanguage, setCurrentLanguage] = useState('RU');

    const isActiveHandler = () => {
        setActive(!isActive);
    };

    const onLanguageClick = (e:any) => {
        setCurrentLanguage(e.target.innerHTML);
        setActive(false);
    };

    return (
        <LanguageSelectWrapper>
            <LanguageSelectVisibleWrapper onClick={isActiveHandler}>
                <span>{currentLanguage}</span>
                <img src={downArrowIcon} alt=""/>
            </LanguageSelectVisibleWrapper>
            <LanguageSelectVariantsWrapper isActive={isActive}>
                <TheHeaderFilterItemWrapper onClick={onLanguageClick}>RU</TheHeaderFilterItemWrapper>
                <TheHeaderFilterItemWrapper onClick={onLanguageClick}>EN</TheHeaderFilterItemWrapper>
                <TheHeaderFilterItemWrapper onClick={onLanguageClick}>DE</TheHeaderFilterItemWrapper>
            </LanguageSelectVariantsWrapper>
        </LanguageSelectWrapper>
    )
}

export default TheLanguageSelect;