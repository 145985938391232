import React, {useEffect} from 'react'
import TheHeader from "../../components/the-header/TheHeader";
import TheSidebarMenu from "../../components/the-sidebar-menu/TheSidebarMenu";
import AppPageCenterWrapper from "../../components/app-page-center-wrapper/AppPageCenterWrapper";
import AppRow from "../../components/app-row/AppRow";
import AppTablePagination from "../../components/app-table-pagination/AppTablePagination";
import AppContainerWrapper from "../../styles/AppContainerWrapper";
import AppPageTitleWrapper from "../../styles/AppPageTitleWrapper";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import AppTableSearch from "../../components/app-table-search/AppTableSearch";
import AdminStatTable from "./AdminStatTable";
import {remoteGetStats} from "../../redux/actions";


const AdminStatIndex = () => {

    const dispatch = useDispatch()
    const apiToken = useSelector(state => state.apiToken)
    const statistics = useSelector(state => state.statistics)

    const history = useHistory()

    const {page = 1} = useParams()

    useEffect(() => {

        dispatch(remoteGetStats({
            apiToken,
            page
        }))

    }, [apiToken, page])

    return (
        <React.Fragment>
            <TheHeader/>
            <TheSidebarMenu activeLink="admin-stat"/>
            <AppPageCenterWrapper>
                <AppContainerWrapper>
                    <AppRow>
                        <AppPageTitleWrapper>
                            Сводная статистика
                        </AppPageTitleWrapper>
                    </AppRow>
                    <AppRow justify="space-between">
                        <AppTableSearch/>
                    </AppRow>
                    <AppRow>
                        <AdminStatTable rows={statistics}/>
                    </AppRow>
                    <AppRow justify="center">
                        <AppTablePagination link="/admin/stat/page/" currentPage={parseInt(page)}/>
                    </AppRow>
                </AppContainerWrapper>
            </AppPageCenterWrapper>
        </React.Fragment>
    )
}

export default AdminStatIndex