import React from "react";
import TheHeader from "../../components/the-header/TheHeader";
import AppPageCenterWrapper from "../../components/app-page-center-wrapper/AppPageCenterWrapper";
import AppContainer from "../../components/app-container/AppContainer";
import AppRow from "../../components/app-row/AppRow";
import styled from "styled-components";
import AppFormGroupInput from "../../components/app-form-group/AppFormGroupInput";
import AppFormRow from "../../components/app-form-row/AppFormRow";
import AppFormGroupSubmit from "../../components/app-form-group/AppFormGroupSubmit";
import {Link} from "react-router-dom";

const AppForm = styled.form`
  max-width: 630px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  box-shadow: -7px 5px 33px rgba(25, 41, 124, 0.05);
  border-radius: 16px;
  padding: 35px 50px;
  margin-top: 100px;

  @media (max-width: 1100px) {
    margin-top: 20px;
    padding: 20px;
  }
`;

const FormTitleWrapper = styled.p`
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  color: black;
  text-align: center;
  @media (max-width: 1100px) {
    font-size: 18px;
  }
`;

const FormColumnGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 430px;
  width: 100%;
`;

const ResetPasswordWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 12px;

  a {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: #5A5A5A;
    text-decoration: none;
  }
`;

const TextWrapper = styled.p`
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #5A5A5A;
  text-align: center;
  margin-top: 20px;
  line-height: 1.5em;
  
  a {
    color: #597ABA;
    text-decoration: none;
  }
`;

const Reset = () => {
    return (
        <React.Fragment>
            <TheHeader/>
            <AppPageCenterWrapper>
                <AppContainer>
                    <AppRow justify="center">
                        <AppForm>
                            <FormTitleWrapper>Выслать данные для восстановления на email</FormTitleWrapper>
                            <AppFormRow justify="center">
                                <AppFormGroupInput maxwidth="426px" label="Введите e-mail"/>
                            </AppFormRow>
                            <AppFormRow justify="center">
                                <AppFormGroupSubmit align="center" label="Выслать"/>
                            </AppFormRow>
                            <TextWrapper>
                                Внимание, письмо может попасть в папку спам!
                            </TextWrapper>
                        </AppForm>
                    </AppRow>
                </AppContainer>
            </AppPageCenterWrapper>
        </React.Fragment>
    );
}

export default Reset;