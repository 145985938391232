import styled from "styled-components";

const ImageWrapper = styled.div`
  background: #E2E2E2;
  border: 1px solid #E2E2E2;
  box-sizing: border-box;
  border-radius: 6px;
  position: absolute;
  top: 26px;
  left: 0;
  height: 52px;
  width: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export default ImageWrapper