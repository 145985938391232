import styled from "styled-components";

const AppPageTitleWrapper = styled.div`
  color: #222222;
  font-size: 36px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  margin-top: 50px;
  margin-bottom: 10px;
  border-bottom: 2px solid #ABABAB;
  width: 100%;
  padding-bottom: 5px;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  
  select {
    cursor: pointer;
    background: #FFFFFF;
    box-shadow: -6.04623px 4.31873px 28.5036px rgba(25, 41, 124, 0.05);
    border-radius: 6px;
    padding: 0 15px;
    border: none;
    outline: none;
    height: 52px;
    margin-left: 20px;
  }

  a {
    font-family: "Gilroy", sans-serif;
    text-decoration: none;
    color: white;
    font-size: 16px;
    background: #2A2A2A;
    border-radius: 6px;
    height: 40px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    @media (max-width: 900px) {
      text-align: center;
    }
  }

  @media (max-width: 800px) {
    margin-top: 20px;
    font-size: 18px;
  }
`

export default AppPageTitleWrapper