import styled from "styled-components"

const AppendButtonWrapper = styled.button`
  height: 40px;
  padding: 0 25px;
  font-size: 16px;
  color: #FFFFFF;
  font-family: "Gilroy", sans-serif;
  background: #2A2A2A;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  margin-top: 40px;
  margin-bottom: 15px;
  
  @media (max-width: 900px) {
    margin-top: 30px;
  }
`

export default AppendButtonWrapper