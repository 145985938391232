import React from "react";
import styled from "styled-components";

const AppPageTitleWrapper = styled.h1`
  color: #222222;
  font-size: 24px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  margin-top: 50px;
  margin-bottom: 10px;
  
  @media (max-width: 800px) {
    margin-top: 20px;
  }
`;

const AppPageTitle = ({title}) => {
    return (
        <AppPageTitleWrapper>
            {title}
        </AppPageTitleWrapper>
    );
}

export default AppPageTitle;