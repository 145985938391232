import React from "react";
import styled from "styled-components";

import filterSearchIcon from '../../assets/tablefilterserarch.svg';
import filterIcon from '../../assets/tableflter.svg';

const TableFilterWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 15px;
`;

const TableFilterInputWrapper = styled.div`
  background: #FFFFFF;
  border: 1px solid #E2E8F0;
  box-sizing: border-box;
  border-radius: 6px;
  min-width: 277px;
  width: 100%;
  height: 40px;
  position: relative;
  padding-left: 60px;
  
  @media (max-width: 900px) {
    min-width: 240px;
  }

  img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
  }

  input {
    height: 38px;
    width: 100%;
    border: none;
    outline: none;
    font-family: "Gilroy", sans-serif;
    font-size: 16px;
    color: #2D3748;
    padding-right: 10px;

    &::placeholder {
      color: #2D3748;
    }
  }
`;

const TableFilterBtnWrapper = styled.div`
  background: #2A2A2A;
  border: 1px solid #202020;
  box-sizing: border-box;
  border-radius: 0px 6px 6px 0px;
  min-width: 103px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Gilroy", sans-serif;
  font-size: 16px;
  color: #FFFFFF;
  cursor: pointer;
  img {
    margin-left: 5px;
  }
`;

const AppTableFilter = () => {
    return (
        <TableFilterWrapper>
            <TableFilterInputWrapper>
                <img src={filterSearchIcon} alt=""/>
                <input type="text" placeholder="Поиск"/>
            </TableFilterInputWrapper>
            <TableFilterBtnWrapper>
                Фильтр
                <img src={filterIcon} alt=""/>
            </TableFilterBtnWrapper>
        </TableFilterWrapper>
    );
}

export default AppTableFilter;