import React, {useState} from 'react'
import StatSubVideosWrapper from "../../styles/StatSubVideosWrapper"
import {v4 as uuidv4} from 'uuid'
import {Link} from "react-router-dom"

const AppAdminStatTableItem = ({item}) => {

    const [isActive, setIsActive] = useState(false)

    const onActiveHandler = () => {
        setIsActive(!isActive)
    }

    return (
        <tr>
            <td>{item.company}</td>
            <td>
                <StatSubVideosWrapper>
                    <p onClick={onActiveHandler}>Выберите видео</p>
                    {
                        isActive ?
                            <div className="adminStatTestSubItems">
                                <ul>
                                    {
                                        item.videos.map((video) => {
                                            return (
                                                <li key={uuidv4()}>
                                                    <Link to={`/admin/stat/${item.company_id}/${video.video_id}`}>
                                                        { video.content }
                                                        <span>x{video.videos_count}</span>
                                                    </Link>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                            :
                            ''
                    }
                </StatSubVideosWrapper>
            </td>
        </tr>
    )
}

export default AppAdminStatTableItem