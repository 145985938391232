import styled from "styled-components";

const FormWrapper = styled.div`
  background: #FFFFFF;
  box-shadow: -7px 5px 33px rgba(25, 41, 124, 0.05);
  border-radius: 16px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 50px;
`

export default FormWrapper