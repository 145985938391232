import React from "react";
import TheHeader from "../../components/the-header/TheHeader";
import AppPageCenterWrapper from "../../components/app-page-center-wrapper/AppPageCenterWrapper";
import AppContainer from "../../components/app-container/AppContainer";
import AppRow from "../../components/app-row/AppRow";
import styled from "styled-components";
import AppFormGroupInput from "../../components/app-form-group/AppFormGroupInput";
import AppFormRow from "../../components/app-form-row/AppFormRow";
import AppFormGroupSubmit from "../../components/app-form-group/AppFormGroupSubmit";
import {Link, Redirect, useHistory} from "react-router-dom";
import {changeField, remoteLogin} from "../../redux/actions";
import {useDispatch, useSelector} from "react-redux";
import config from "../../config";

const AppForm = styled.form`
  max-width: 630px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  box-shadow: -7px 5px 33px rgba(25, 41, 124, 0.05);
  border-radius: 16px;
  padding: 35px 50px;
  margin-top: 100px;

  @media (max-width: 1100px) {
    margin-top: 20px;
    padding: 20px;
  }
`;

const FormTitleWrapper = styled.p`
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  color: black;
  text-align: center;
  @media (max-width: 1100px) {
    font-size: 18px;
  }
`;

const FormColumnGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 430px;
  width: 100%;
`;

const ResetPasswordWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 12px;

  a {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: #5A5A5A;
    text-decoration: none;
    
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
`;

const TextWrapper = styled.p`
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #5A5A5A;
  text-align: center;
  margin-top: 20px;
  line-height: 1.5em;
  
  a {
    color: #597ABA;
    text-decoration: none;
  }
`;


const Login = () => {

    const dispatch = useDispatch()
    const history = useHistory()

    const onChangeHandler = (name, value) => {
        dispatch(changeField({
            field: name,
            value
        }))
    }

    const email = useSelector(state => state.email)
    const password = useSelector(state => state.password)

    const onSubmitHandler = (e) => {
        e.preventDefault()
        dispatch(remoteLogin({
            email,
            password
        }))
    }

    const userRole = useSelector(state => state.userRole)

    if (userRole === config.getAdminRole()) {
        history.push('/company-edit')
    }

    return (
        <React.Fragment>
            <TheHeader/>
            <AppPageCenterWrapper>
                <AppContainer>
                    <AppRow justify="center">
                        <AppForm onSubmit={onSubmitHandler}>
                            <FormTitleWrapper>Вход</FormTitleWrapper>
                            <AppFormRow>
                                <AppFormGroupInput name="email"
                                                   value={email}
                                                   onChange={onChangeHandler}
                                                   maxwidth="100%" label="E-mail"/>
                            </AppFormRow>
                            <AppFormRow>
                                <AppFormGroupInput name="password"
                                                   value={password}
                                                   type="password"
                                                   onChange={onChangeHandler}
                                                   maxwidth="100%" label="Пароль"/>
                            </AppFormRow>
                            <ResetPasswordWrapper>
                                <Link to="/reset">Забыли свой пароль?</Link>
                                <Link to="/register">Создать аккаунт</Link>
                            </ResetPasswordWrapper>
                            <AppFormRow justify="center">
                                <AppFormGroupSubmit align="center" label="Войти в личный кабинет"/>
                            </AppFormRow>
                            <TextWrapper>
                                Авторизуясь, вы соглашаетесь с <Link to="/private">пользовательским соглашением</Link> <br/>
                                и даёте согласие на обработку <Link to="/conf">персональных данных</Link>.
                            </TextWrapper>
                        </AppForm>
                    </AppRow>
                </AppContainer>
            </AppPageCenterWrapper>
        </React.Fragment>
    )
}

export default Login;