import React, {useEffect} from "react";
import AppContainer from "../../components/app-container/AppContainer";
import AppRow from "../../components/app-row/AppRow";
import styled from "styled-components";
import AppPageCenterWrapper from "../../components/app-page-center-wrapper/AppPageCenterWrapper";
import AppFormRow from "../../components/app-form-row/AppFormRow";
import AppFormGroupInput from "../../components/app-form-group/AppFormGroupInput";
import AppFormGroupTextArea from "../../components/app-form-group/AppFormGroupTextArea";
import AppFormGroupCheckbox from "../../components/app-form-group/AppFormGroupCheckbox";
import AppFormGroupSubmit from "../../components/app-form-group/AppFormGroupSubmit";
import AppFormGroupSelect from "../../components/app-form-group/AppFormGroupSelect";
import TheHeader from "../../components/the-header/TheHeader";
import {useDispatch, useSelector} from "react-redux";
import {changeField, getPayments, remoteRegister} from "../../redux/actions";

const AppForm = styled.form`
  max-width: 1136px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #FFFFFF;
  box-shadow: -7px 5px 33px rgba(25, 41, 124, 0.05);
  border-radius: 16px;
  padding: 35px 70px;
  margin-top: 100px;

  @media (max-width: 1100px) {
    margin-top: 20px;
    padding: 20px;
  }
`;

const FormTitleWrapper = styled.p`
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  color: black;
  text-align: center;
  @media (max-width: 1100px) {
    font-size: 18px;
  }
`;

const FormColumnGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 430px;
  width: 100%;
`;


const CompanyRegister = () => {

    const dispatch = useDispatch()
    const payments = useSelector(state => state.payments)

    useEffect(() => {
        dispatch(getPayments())
    }, [])

    const companyName = useSelector(state => state.companyName)
    const companyAddress = useSelector(state => state.companyAddress)
    const postIndex = useSelector(state => state.postIndex)
    const companyCity = useSelector(state => state.companyCity)
    const companyCountry = useSelector(state => state.companyCountry)
    const email = useSelector(state => state.email)
    const contactPerson = useSelector(state => state.contactPerson)
    const phone = useSelector(state => state.phone)
    const fieldOfActivity = useSelector(state => state.fieldOfActivity)
    const amountOfStuff = useSelector(state => state.amountOfStuff)
    const accountReceiver = useSelector(state => state.accountReceiver)
    const payment = useSelector(state => state.payment)
    const isOfferAccepted = useSelector(state => state.isOfferAccepted)
    const isConfidentialAccepted = useSelector(state => state.isConfidentialAccepted)
    const comment = useSelector(state => state.comment)

    const onSubmitHandler = (e) => {
        e.preventDefault()
        dispatch(remoteRegister({
            companyName,
            companyAddress,
            postIndex,
            companyCity,
            companyCountry,
            email,
            contactPerson,
            phone,
            fieldOfActivity,
            amountOfStuff,
            accountReceiver,
            payment,
            isOfferAccepted,
            isConfidentialAccepted,
            comment
        }))
    }

    const onChangeHandler = (name, value) => {
        dispatch(changeField({
            field: name,
            value
        }))
    }

    return (
        <React.Fragment>
            <TheHeader isLogin={false}/>
            <AppPageCenterWrapper>
                <AppContainer>
                    <AppRow justify="center">
                        <AppForm onSubmit={onSubmitHandler}>
                            <FormTitleWrapper>Регистрация компании</FormTitleWrapper>
                            <AppFormRow>
                                <AppFormGroupInput name="companyName" value={companyName} onChange={onChangeHandler}
                                                   label="Название компании"/>
                                <AppFormGroupInput name="contactPerson"
                                                   value={contactPerson}
                                                   onChange={onChangeHandler}
                                                   label="Контактное лицо (Имя и фамилия)"/>
                            </AppFormRow>
                            <AppFormRow>
                                <AppFormGroupInput name="companyAddress" value={companyAddress}
                                                   onChange={onChangeHandler}
                                                   label="Адрес регистрации компании"/>
                                <AppFormGroupInput name="phone"
                                                   value={phone}
                                                   onChange={onChangeHandler} label="Телефонный номер"/>
                            </AppFormRow>
                            <AppFormRow>
                                <AppFormGroupInput name="postIndex" value={postIndex} onChange={onChangeHandler}
                                                   label="Почтовый индекс"/>
                                <AppFormGroupInput name="fieldOfActivity"
                                                   value={fieldOfActivity}
                                                   onChange={onChangeHandler}
                                                   label="Сфера деятельности компании"/>
                            </AppFormRow>
                            <AppFormRow>
                                <AppFormGroupInput name="companyCity" value={companyCity} onChange={onChangeHandler}
                                                   label="Город регистрации компании"/>
                                <AppFormGroupInput name="amountOfStuff"
                                                   value={amountOfStuff}
                                                   onChange={onChangeHandler}
                                                   label="Количество сотрудников"/>
                            </AppFormRow>
                            <AppFormRow>
                                <AppFormGroupInput name="companyCountry" value={companyCountry}
                                                   onChange={onChangeHandler}
                                                   label="Страна регистрации компании"/>
                                <AppFormGroupInput name="accountReceiver" value={accountReceiver}
                                                   onChange={onChangeHandler}
                                                   label="Получатель счёта"/>
                            </AppFormRow>
                            <AppFormRow>
                                <AppFormGroupInput name="email" value={email} onChange={onChangeHandler}
                                                   label="Электронная почта компании"/>
                                <AppFormGroupSelect name="payment" onChange={onChangeHandler}
                                                    value={payment}
                                                    options={payments} label="Способ оплаты"/>
                            </AppFormRow>
                            <AppFormRow>
                                <AppFormGroupTextArea value={comment} name="comment" onChange={onChangeHandler}
                                                      label="Комментарий"/>
                                <FormColumnGroup>
                                    <AppFormGroupCheckbox name="isOfferAccepted"
                                                          onChange={onChangeHandler}
                                                          isActive={isOfferAccepted}
                                                          label="условия договора приняты"/>
                                    <AppFormGroupCheckbox name="isConfidentialAccepted"
                                                          isActive={isConfidentialAccepted}
                                                          onChange={onChangeHandler}
                                                          label="c условием конфиденциальности ознакомлен"/>
                                    <AppFormGroupSubmit label="Завершить регистрацию"/>
                                </FormColumnGroup>
                            </AppFormRow>
                        </AppForm>
                    </AppRow>
                </AppContainer>
            </AppPageCenterWrapper>
        </React.Fragment>
    );
};

export default CompanyRegister;