import React from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";

const TableTitleWrapper = styled.p`
  font-family: "Gilroy", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #000000;
  margin-bottom: 13px;
`;

const TheLastVideoTableWrapper = styled.div`
  max-width: 49%;
  width: 100%;
  margin-top: 45px;
  
  @media (max-width: 900px) {
    max-width: 100%;
  }
  
  table {
    border: none;
    border-collapse: collapse;
    width: 100%;
    border-radius: 13px;
    box-shadow: -7px 5px 33px rgba(25, 41, 124, 0.05);
    background: #FFFFFF;

    tbody {

      tr:first-child {
        td:first-child {
          border-top-left-radius: 13px;
        }

        td:last-child {
          border-top-right-radius: 13px;
        }
      }

      td {
        padding-left: 50px;
        background: #FFFFFF;
        height: 50px;
        font-family: "Gilroy", sans-serif;
        font-weight: 500;
        font-size: 16px;
        
        &:not(:first-child) {
          border-top: 1px solid #E1E1E1;  
        }
        
        border-bottom: 1px solid #E1E1E1;

        a {
          font-family: "Gilroy", sans-serif;
          line-height: 19px;
          color: #4C81D1;
        }
        
      }
    }

    tfoot {
      td {
        height: 70px;

        a {
          font-family: "Gilroy", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          color: #000000;
          text-decoration: none;
          padding-right: 50px;
        }
      }
    }
  }
  
  .test-done-success {
    color: #2C9E31;
  }

  .test-done-medium {
    color: #D6B84F;
  }
  
  .test-done-failed {
    color: #E72E2E;
  }
`;

const TheLastActivityTable = () => {
    return (
        <TheLastVideoTableWrapper>
            <TableTitleWrapper>Последняя активность</TableTitleWrapper>
            <table>
                <tbody>
                <tr>
                    <td><Link to="/user">Иван Иванович</Link> сдал тест 00/00/00 на
                        <span className="test-done-success"> 90 баллов</span>. </td>
                </tr>
                <tr>
                    <td><Link to="/user">Иван Иванович</Link> сдал тест 00/00/00 на
                        <span className="test-done-medium"> 74 балла</span>. </td>
                </tr>
                <tr>
                    <td><Link to="/user">Иван Иванович</Link> сдал тест 00/00/00 на
                        <span className="test-done-failed"> 18 баллов</span>. </td>
                </tr>
                <tr>
                    <td><Link to="/user">Иван Иванович</Link> сдал тест 00/00/00 на
                        <span className="test-done-medium"> 74 балла</span>. </td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                    <td align="right">
                        <Link to="full-videos">Увидеть полный список →</Link>
                    </td>
                </tr>
                </tfoot>
            </table>
        </TheLastVideoTableWrapper>
    )
};

export default TheLastActivityTable;