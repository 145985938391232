import React, {useEffect, useState} from "react";
import TheHeader from "../../components/the-header/TheHeader";
import RoleService from "../../services/RoleService";
import TheSidebarMenu from "../../components/the-sidebar-menu/TheSidebarMenu";
import AppPageCenterWrapper from "../../components/app-page-center-wrapper/AppPageCenterWrapper";
import AppRow from "../../components/app-row/AppRow";
import styled from "styled-components";
import {Link} from "react-router-dom";

import profilePhoto from '../../assets/profile-ava.svg';

import useWindowDimensions from "../../hooks/useWindowDimensions";
import TheCertificates from "../../components/the-certificates/TheCertificates";
import AppVideoTable from "../../components/app-video-table/AppVideoTable";
import AppTableFilter from "../../components/app-table-filter/AppTableFilter";
import AppUserVideoTable from "../../components/app-user-video-table/AppUserVideoTable";
import AppTablePagination from "../../components/app-table-pagination/AppTablePagination";

const AppPageTitleWrapper = styled.div`
  color: #222222;
  font-size: 36px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  margin-top: 50px;
  margin-bottom: 10px;
  border-bottom: 2px solid #ABABAB;
  width: 100%;
  padding-bottom: 5px;

  display: flex;
  justify-content: space-between;

  a {
    font-family: "Gilroy", sans-serif;
    text-decoration: none;
    color: white;
    font-size: 16px;
    background: #2A2A2A;
    border-radius: 6px;
    height: 40px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    @media (max-width: 900px) {
      text-align: center;
    }
  }

  @media (max-width: 800px) {
    margin-top: 20px;
    font-size: 18px;
  }
`;

const AppContainerWrapper = styled.div`
  max-width: 1659px;
  width: 100%;
  padding: 0 15px;
  padding-left: ${props => props.isActive ? '250px' : '15px'};
  transition: .3s all;

  @media (max-width: 1340px) {
    padding-left: 280px;
  }

  @media (max-width: 900px) {
    padding-left: 15px;
  }
`;

const CompanyAccountCartItem = styled.div`
  margin-top: 30px;
  max-width: 337px;
  width: 100%;
  min-height: 196px;
  background: #2A2A2A;
  box-shadow: -7px 5px 33px rgba(25, 41, 124, 0.05), -7px 5px 33px rgba(25, 41, 124, 0.05);
  border-radius: 13px;
  padding: 20px;

  img {
    transition: 1s all;
  }

  &:hover {
    img {
      transform: scale(1.2);
      transition: 1s all;
    }
  }

  &.company-account-cart-item-second {
    background: #4C81D1;

    a {
      color: white;
    }
  }
`;

const CompanyAccountCartItemTitle = styled.p`
  font-family: "Gilroy", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #F0F4F7;
  min-height: 38px;
`;

const CompanyAccountCartItemContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props => props.justify || 'space-between'};

  p {
    font-family: "Gilroy", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 80px;
    line-height: 97px;
    color: #F0F4F7;
  }
`;

const CompanyAccountCartItemFooter = styled.div`
  display: flex;
  justify-content: flex-end;

  a {
    font-family: "Gilroy", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #989898;
    text-decoration: none;
  }
`;

const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 270px;
  width: 100%;
  margin-top: 35px;

  @media (max-width: 900px) {
    max-width: 100%;
  }
  
  img {
    width: 185px;
    height: 185px;
  }
  
  ul {
    list-style: none;
    li {
      margin-top: 24px;
      font-family: "Gilroy", sans-serif;
      font-size: 16px;
      color: #272727;
      span {
        font-weight: 500;
      }
      a {
        color: #4C81D1;
      }

      @media (max-width: 900px) {
        margin-top: 15px;
      }
    }
  }
`;

const AppendButtonWrapper = styled.button`
  height: 40px;
  padding: 0 25px;
  font-size: 16px;
  color: #FFFFFF;
  font-family: "Gilroy", sans-serif;
  background: #2A2A2A;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  margin-top: 40px;
  margin-bottom: 15px;
  
  @media (max-width: 900px) {
    margin-top: 30px;
  }
`;

const UserVideos = () => {

    const roleService = new RoleService();
    const [isSidebarActive, setSidebarActive] = useState(true);

    const {width} = useWindowDimensions();

    useEffect(() => {
        if (width < 900) {
            setSidebarActive(false);
        }
    }, [width]);

    const onMobileMenuClick = () => {
        setSidebarActive(!isSidebarActive);
    };

    return (
        <React.Fragment>
            <TheHeader onMobileMenuHandler={onMobileMenuClick} role={roleService.getCompanyRole()} isLogin={true}/>
            <TheSidebarMenu isActive={isSidebarActive}/>
            <AppPageCenterWrapper>
                <AppContainerWrapper isActive={isSidebarActive}>
                    <AppRow>
                        <AppPageTitleWrapper>
                            Доступные видео
                        </AppPageTitleWrapper>
                    </AppRow>
                    <AppRow justify="space-between">
                        <AppTableFilter />
                        <AppendButtonWrapper>Просмотреть новые демо-видео</AppendButtonWrapper>
                    </AppRow>
                    <AppRow>
                        <AppUserVideoTable />
                    </AppRow>
                    <AppRow justify="center">
                        <AppTablePagination />
                    </AppRow>
                </AppContainerWrapper>
            </AppPageCenterWrapper>
        </React.Fragment>
    );
}

export default UserVideos;