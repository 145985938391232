import React from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";
import prevIcon from "../../assets/prev.svg";
import nextIcon from "../../assets/next.svg";
import {useSelector} from "react-redux";

const AppTablePaginationWrapper = styled.ul`
  list-style: none;
  display: flex;
  margin-top: 80px;
  li, a {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    color: #5E646F;
    text-decoration: none;
  }
  li {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pagination-active {
    color: #24272D;
    position: relative;
    
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      height: 1px;
      width: 30px;
      background-color: #24272D;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

const AppTablePagination = ({link, currentPage}) => {

    const maxPage = useSelector(state => state.maxPagination)

    const paginations = [];

    for (let i = 1; i <= maxPage; i++) {

        if (currentPage == i) {
            paginations.push(<li key={i} className="pagination-active">{i}</li>)
        } else {
            paginations.push(<li key={i}><Link to={`${link}${i}`}>{i}</Link></li>)
        }
    }

    return (
        <AppTablePaginationWrapper>

            {
                currentPage != 1 ?
                    <li key={0}><Link to={`${link}${currentPage - 1}`}> <img src={prevIcon} alt=""/> </Link></li>
                    :
                    ''
            }

            {paginations}

            {
                currentPage != maxPage ?
                    <li key={999999999999}><Link to={`${link}${currentPage + 1}`}> <img src={nextIcon} alt=""/> </Link></li>
                    :
                    ''
            }
        </AppTablePaginationWrapper>
    );
}

export default AppTablePagination;