import React, {useState} from "react";
import styled from "styled-components";
import TheHeaderFilterItem from "./TheHeaderFilterItem";

const TheHeaderFilterWrapper = styled.div`
  
  position: relative;
  margin-left: 24px;
  margin-right: 50px;
  
  @media (max-width: 800px) {
    margin-left: 10px;
    margin-right: 10px;
  }
  
  button {
    border: none;
    outline: none;
    cursor: pointer;
    background: #696969;
    border: 1px solid #838383;
    box-sizing: border-box;
    border-radius: 14.5px;
    width: 50px;
    height: 30px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */
    text-align: center;
    color: #FFFFFF;
  }
`;

const TheHeaderFilterResultWrapper = styled.div`
  display: ${props => props.isActive ? 'block' : 'none'};
  background: #2A2A2A;
  border: 1px solid #3A3A3A;
  box-sizing: border-box;
  padding: 25px 30px;
  position: absolute;
  width: 400px;
  max-height: 296px;
  overflow-y: auto;
  right: -20px;
  top: 45px;
  z-index: 2;
  
  @media (max-width: 800px) {
    right: -63px;
    width: 100vw;
    top: 41px;
  }

  &::-webkit-scrollbar {
    width: 14px;
    background-color: #3A3A3A;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 7px 7px 8px 8px;
    border: none;
    outline: none;
  }

  &::-webkit-scrollbar-thumb {
    background: #6C6C6C;
    border-radius: 7px 7px 8px 8px;
    border: none;
    outline: none;
  }
  
  .the-header-filter-item:not(:last-child) {
    margin-bottom: 17px;
  }
`;

const TheHeaderFilter = () => {

    const [isActive, setActive] = useState(false);

    const isActiveHandler = () => {
      setActive(!isActive);
    };

    return (
        <TheHeaderFilterWrapper>
            <button onClick={isActiveHandler} type="button">...</button>
            <TheHeaderFilterResultWrapper isActive={isActive}>
                <TheHeaderFilterItem title="Категория 1" />
                <TheHeaderFilterItem title="Категория 2" />
                <TheHeaderFilterItem title="Категория 3" />
                <TheHeaderFilterItem title="Категория 4" />
                <TheHeaderFilterItem title="Категория 5" />
                <TheHeaderFilterItem title="Категория 6" />
                <TheHeaderFilterItem title="Категория 7" />
                <TheHeaderFilterItem title="Категория 7" />
                <TheHeaderFilterItem title="Категория 7" />
                <TheHeaderFilterItem title="Категория 7" />
                <TheHeaderFilterItem title="Категория 7" />
            </TheHeaderFilterResultWrapper>
        </TheHeaderFilterWrapper>
    )
};

export default TheHeaderFilter;