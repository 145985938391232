import React from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";

import videoPhoto from '../../assets/videoimage.jpg';
import CheckboxHeaderWrapper from "../../styles/CheckboxHeaderWrapper";
import CheckboxWrapper from "../../styles/CheckboxWrapper";
import AppVideoTableWrapper from "../../styles/AppVideoTableWrapper";

const TableVideoWrapper = styled.div`

  display: flex;

  .table-video-image {
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
    background-image: url(${videoPhoto});
    width: 120px;
    height: 68px;
  }
`;

const TableVideoTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 18px;
  max-width: 330px;
  width: 100%;

  @media (max-width: 900px) {
    width: 330px;
  }

  .table-video-text-title {
    font-family: "Gilroy", sans-serif;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #2D3748;
  }

  .table-video-text-text {
    font-family: "Gilroy", sans-serif;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #737373;
  }
`;

const AppEmployeesTable = () => {
    return (
        <AppVideoTableWrapper>
            <table>
                <thead>
                <tr>
                    <th>
                        <CheckboxHeaderWrapper/>
                    </th>
                    <th>Строитель</th>
                    <th>Бригада</th>
                    <th>Отдел</th>
                    <th>Дата рождения</th>
                    <th>E-mail</th>
                    <th>Статистика</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>
                        <CheckboxWrapper/>
                    </td>
                    <td>
                        <Link className="app-table-link" to="/edit-user-profile">Фомин Родион</Link>
                    </td>
                    <td>Бригада</td>
                    <td>Отдел1</td>
                    <td>00/00/1990</td>
                    <td>
                        <Link className="app-table-link" to="/">buildertest@email</Link>
                    </td>
                    <td>
                        <Link to="/detailed">Подробная статистика</Link>
                    </td>
                </tr>
                <tr>
                    <td>
                        <CheckboxWrapper/>
                    </td>
                    <td>
                        <Link className="app-table-link" to="/edit-user-profile">Фомин Родион</Link>
                    </td>
                    <td>Бригада</td>
                    <td>Отдел1</td>
                    <td>00/00/1990</td>
                    <td>
                        <Link className="app-table-link" to="/">buildertest@email</Link>
                    </td>
                    <td>
                        <Link to="/detailed">Подробная статистика</Link>
                    </td>
                </tr>
                <tr>
                    <td>
                        <CheckboxWrapper/>
                    </td>
                    <td>
                        <Link className="app-table-link" to="/edit-user-profile">Фомин Родион</Link>
                    </td>
                    <td>Бригада</td>
                    <td>Отдел1</td>
                    <td>00/00/1990</td>
                    <td>
                        <Link className="app-table-link" to="/">buildertest@email</Link>
                    </td>
                    <td>
                        <Link to="/detailed">Подробная статистика</Link>
                    </td>
                </tr>
                <tr>
                    <td>
                        <CheckboxWrapper/>
                    </td>
                    <td>
                        <Link className="app-table-link" to="/edit-user-profile">Фомин Родион</Link>
                    </td>
                    <td>Бригада</td>
                    <td>Отдел1</td>
                    <td>00/00/1990</td>
                    <td>
                        <Link className="app-table-link" to="/">buildertest@email</Link>
                    </td>
                    <td>
                        <Link to="/detailed">Подробная статистика</Link>
                    </td>
                </tr>
                <tr>
                    <td>
                        <CheckboxWrapper/>
                    </td>
                    <td>
                        <Link className="app-table-link" to="/edit-user-profile">Фомин Родион</Link>
                    </td>
                    <td>Бригада</td>
                    <td>Отдел1</td>
                    <td>00/00/1990</td>
                    <td>
                        <Link className="app-table-link" to="/">buildertest@email</Link>
                    </td>
                    <td>
                        <Link to="/detailed">Подробная статистика</Link>
                    </td>
                </tr>
                </tbody>
            </table>
        </AppVideoTableWrapper>
    );
}

export default AppEmployeesTable;