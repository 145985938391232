import React, {useRef} from "react";
import TheHeader from "../../components/the-header/TheHeader";
import AppContainer from "../../components/app-container/AppContainer";
import AppPageCenterWrapper from "../../components/app-page-center-wrapper/AppPageCenterWrapper";
import AppRow from "../../components/app-row/AppRow";
import styled from "styled-components";

import fileIcon from '../../assets/file.svg';

const AppPageTitleWrapper = styled.h1`
  color: #222222;
  font-size: 36px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  margin-top: 50px;
  margin-bottom: 10px;
  border-bottom: 2px solid #ABABAB;
  width: 100%;
  padding-bottom: 5px;

  @media (max-width: 800px) {
    margin-top: 20px;
    font-size: 21px;
  }
`;

const FormGroupWrapper = styled.div`

  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 30px;
  
  label {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #2A2A2A;
  }
  
  input {
    margin-top: 5px;
    width: 100%;
    background: #F8F8F8;
    border: 1px solid #E2E2E2;
    box-sizing: border-box;
    border-radius: 6px;
    height: 52px;
    outline: none;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    padding: 0 25px;
  }
`;

const FormWrapper = styled.div`
  background: #FFFFFF;
  box-shadow: -7px 5px 33px rgba(25, 41, 124, 0.05);
  border-radius: 16px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 50px;
`;

const FormGroupFileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 30px;
  position: relative;

  label {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #2A2A2A;
  }

  img {
    width: 19px;
    height: 24px;
  }
  
  input {
    display: none;
  }
  
  span {
    margin-top: 5px;
    width: 100%;
    background: #F8F8F8;
    border: 1px solid #E2E2E2;
    box-sizing: border-box;
    border-radius: 6px;
    height: 52px;
    outline: none;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    padding: 0 25px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-left: 70px;
  }
`;

const ImageWrapper = styled.div`
  background: #E2E2E2;
  border: 1px solid #E2E2E2;
  box-sizing: border-box;
  border-radius: 6px;
  position: absolute;
  top: 26px;
  left: 0;
  height: 52px;
  width: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FormGroupButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    height: 55px;
    width: 326px;
    background: #597ABA;
    box-shadow: -7px 5px 33px rgba(25, 41, 124, 0.05);
    border-radius: 7px;
    border: none;
    cursor: pointer;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
  }
`;

const EditUserProfile = () => {

    const refFile = useRef();

    const onFileClickHandler = () => {
        refFile.current.click();
    };

    return (
        <React.Fragment>
            <TheHeader isLogin={true}/>
            <AppPageCenterWrapper>
                <AppContainer>
                    <AppRow>
                        <AppPageTitleWrapper>
                            Редактировать личную информацию
                        </AppPageTitleWrapper>
                    </AppRow>
                    <AppRow>
                        <FormWrapper>
                            <FormGroupWrapper>
                                <label>Фамилия и Имя сотрудника</label>
                                <input type="text"/>
                            </FormGroupWrapper>
                            <FormGroupFileWrapper onClick={onFileClickHandler}>
                                <label>Фотография профиля</label>
                                <ImageWrapper>
                                    <img src={fileIcon} alt=""/>
                                </ImageWrapper>
                                <input ref={refFile} type="file"/>
                                <span>Загрузить фотографию</span>
                            </FormGroupFileWrapper>
                            <FormGroupWrapper>
                                <label>Бригада</label>
                                <input type="text"/>
                            </FormGroupWrapper>
                            <FormGroupWrapper>
                                <label>Отдел</label>
                                <input type="text"/>
                            </FormGroupWrapper>
                            <FormGroupWrapper>
                                <label>Название компании</label>
                                <input type="text"/>
                            </FormGroupWrapper>
                            <FormGroupWrapper>
                                <label>Дата рождения</label>
                                <input type="date"/>
                            </FormGroupWrapper>
                            <FormGroupWrapper>
                                <label>E-mail</label>
                                <input type="email"/>
                            </FormGroupWrapper>
                            <FormGroupWrapper>
                                <label>Пароль</label>
                                <input type="password"/>
                            </FormGroupWrapper>
                            <FormGroupButtonWrapper>
                                <button>Сохранить изменения</button>
                            </FormGroupButtonWrapper>
                        </FormWrapper>
                    </AppRow>
                </AppContainer>
            </AppPageCenterWrapper>
        </React.Fragment>
    );
};

export default EditUserProfile;