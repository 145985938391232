import styled from "styled-components";

const CheckboxWrapper = styled.span`
  border: 2px solid #2A2A2A;
  background-color: ${props => props.checked ? '#2A2A2A' : 'white'};
  box-sizing: border-box;
  border-radius: 3px;
  width: 20px;
  height: 20px;
  display: block;
  cursor: pointer;
`

export default CheckboxWrapper