import React, {useEffect, useRef, useState} from "react";
import TheHeader from "../../components/the-header/TheHeader";
import RoleService from "../../services/RoleService";
import TheSidebarMenu from "../../components/the-sidebar-menu/TheSidebarMenu";
import AppPageCenterWrapper from "../../components/app-page-center-wrapper/AppPageCenterWrapper";
import AppRow from "../../components/app-row/AppRow";
import styled from "styled-components";
import {Link, useParams} from "react-router-dom";

import profilePhoto from '../../assets/profile-ava.svg';

import useWindowDimensions from "../../hooks/useWindowDimensions";
import TheCertificates from "../../components/the-certificates/TheCertificates";
import AppVideoTable from "../../components/app-video-table/AppVideoTable";
import AppTableFilter from "../../components/app-table-filter/AppTableFilter";
import AppUserVideoTable from "../../components/app-user-video-table/AppUserVideoTable";
import AppTablePagination from "../../components/app-table-pagination/AppTablePagination";
import AppEmployeesTable from "../../components/app-employees-table/AppEmployeesTable";
import fileIcon from "../../assets/file.svg";
import AppContainerWrapper from "../../styles/AppContainerWrapper";
import AppPageTitleWrapper from "../../styles/AppPageTitleWrapper";
import FormWrapper from "../../styles/FormWrapper";
import FormGroupWrapper from "../../styles/FormGroupWrapper";
import FormGroupFileWrapper from "../../styles/FormGroupFileWrapper";
import ImageWrapper from "../../styles/ImageWrapper";
import FormGroupButtonWrapper from "../../styles/FormGroupButtonWrapper";
import {useDispatch, useSelector} from "react-redux";
import {
    getPayments,
    remoteCreateCompany,
    remoteCreateUser, remoteEditUser, remoteGetAdminUser,
    remoteGetAllCompanies, remoteGetBrigadesByCompanyId, remoteGetDepartmentsByCompanyId,
    showMessage
} from "../../redux/actions";

const AdminUserEdit = () => {

    const dispatch = useDispatch()

    const apiToken = useSelector(state => state.apiToken)

    const [user, setUser] = useState({
        'name': '',
        'email': '',
        'description': '',
        'password': '',
        'repeat_password': '',
        'birth_date': '',
        'role': 'builder',
        'department_id': 0,
        'brigade_id': 0,
        'company_id': 0
    })

    const [companies, setCompanies] = useState([])
    const [brigades, setBrigades] = useState([])
    const [departments, setDepartments] = useState([])
    const {id} = useParams()

    const onChangeCompany = (e) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value
        })
    }

    //const departments = useSelector(state => state.departments)
    //const brigades = useSelector(state => state.brigades)

    useEffect(() => {
        dispatch(remoteGetAllCompanies({
            apiToken,
            cb: (companies) => {
                setCompanies(companies)
            }
        }))

        dispatch(remoteGetBrigadesByCompanyId({
            apiToken,
            companyId: user.company_id,
            cb: (brigades) => {
                setBrigades(brigades)
            }
        }))

        dispatch(remoteGetDepartmentsByCompanyId({
            apiToken,
            companyId: user.company_id,
            cb: (departments) => {
                setDepartments(departments)
            }
        }))

    }, [apiToken, user.company_id, id])

    useEffect(() => {
        dispatch(remoteGetAdminUser({
            apiToken,
            userId: id,
            cb: (userRes) => {
                setUser({
                    name: userRes.name,
                    email: userRes.email,
                    description: userRes.description ? userRes.description: '',
                    birth_date: userRes.birth_date,
                    role: userRes.role,
                    department_id: userRes.department_id ? userRes.department_id : 0,
                    brigade_id: userRes.brigade_id ? userRes.brigade_id : 0,
                    company_id: userRes.company_id ? userRes.company_id: 0,
                    password: '',
                    repeat_password: ''
                })
            }
        }))
    }, [id])

    const onSubmitHandler = () => {

        if (user.password.length > 0 && user.repeat_password.length > 0) {
            if (user.password !== user.repeat_password || user.password.length < 6) {
                dispatch(showMessage({
                    title: 'Ошибка!',
                    message: ' Пароли должны совпадать и быть больше 6 символов'
                }))

                return
            }
        }

        dispatch(remoteEditUser({
            apiToken,
            user,
            userId: id,
            cb: (res) => {
            }
        }))
    }

    return (
        <React.Fragment>
            <TheHeader/>
            <TheSidebarMenu activeLink="admin-users"/>
            <AppPageCenterWrapper>
                <AppContainerWrapper>
                    <AppRow>
                        <AppPageTitleWrapper>
                            Редактировать пользователя
                        </AppPageTitleWrapper>
                    </AppRow>
                    <AppRow>
                        <FormWrapper>

                            <FormGroupWrapper>
                                <label>ФИО</label>
                                <input onChange={onChangeCompany} value={user.name} name="name" type="text"/>
                            </FormGroupWrapper>

                            <FormGroupWrapper>
                                <label>Email</label>
                                <input onChange={onChangeCompany} value={user.email} name="email" type="text"/>
                            </FormGroupWrapper>

                            <FormGroupWrapper>
                                <label>Пароль (оставьте пустым, если не хотите менять)</label>
                                <input value={user.password} onChange={onChangeCompany}
                                       type="password" name="password"/>
                            </FormGroupWrapper>

                            <FormGroupWrapper>
                                <label>Повторите пароль</label>
                                <input type="password" onChange={onChangeCompany}
                                       value={user.repeat_password}
                                       name="repeat_password"/>
                            </FormGroupWrapper>

                            <FormGroupWrapper>
                                <label>Дата рождения</label>
                                <input onChange={onChangeCompany} name="birth_date" value={user.birth_date}
                                       type="date"/>
                            </FormGroupWrapper>

                            <FormGroupWrapper>
                                <label>Роль</label>
                                <select onChange={onChangeCompany} name="role" value={user.role}>
                                    <option value="builder">Строитель</option>
                                    <option value="company">Управляющий компанией</option>
                                    <option value="admin">Администратор</option>
                                </select>
                            </FormGroupWrapper>

                            <FormGroupWrapper>
                                <label>Компания</label>
                                <select onChange={onChangeCompany} name="company_id" value={user.company_id}>
                                    <option key={0} value="0">- Выберите компанию -</option>
                                    {
                                        companies.map((item) => {
                                            return (<option key={item.id} value={item.id}>{item.name}</option>)
                                        })
                                    }
                                </select>
                            </FormGroupWrapper>

                            <FormGroupWrapper>
                                <label>Отдел</label>
                                <select onChange={onChangeCompany} name="department_id" value={user.department_id}>
                                    <option key={0} value="0">- Выберите отдел -</option>
                                    {
                                        departments.map((item) => {
                                            return (<option key={item.id} value={item.id}>{item.name}</option>)
                                        })
                                    }
                                </select>
                            </FormGroupWrapper>

                            <FormGroupWrapper>
                                <label>Бригада</label>
                                <select onChange={onChangeCompany} name="brigade_id" value={user.brigade_id}>
                                    <option key={0} value="0">- Выберите бригаду -</option>
                                    {
                                        brigades.map((item) => {
                                            return (<option key={item.id} value={item.id}>{item.name}</option>)
                                        })
                                    }
                                </select>
                            </FormGroupWrapper>

                            <FormGroupWrapper>
                                <label>Описание</label>
                                <textarea onChange={onChangeCompany} name="description" value={user.description}/>
                            </FormGroupWrapper>

                            <FormGroupButtonWrapper>
                                <button type="button" onClick={onSubmitHandler}>Сохранить изменения</button>
                            </FormGroupButtonWrapper>

                        </FormWrapper>
                    </AppRow>
                </AppContainerWrapper>
            </AppPageCenterWrapper>
        </React.Fragment>
    )
}

export default AdminUserEdit