import React, {useState} from "react";
import styled from "styled-components";

const TheHeaderFilterItemWrapper = styled.div`
  position: relative;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  cursor: pointer;
  padding-left: 30px;
  
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 1px;
    background: ${props => props.isActive ? '#C4C4C4' : 'transparent'};
    border: ${props => props.isActive ? 'none' : '1px solid #FFFFFF'};
    border-radius: 2px;
    width: 14px;
    height: 14px;
  }
`;

const TheHeaderFilterItem = ({title}) => {

    const [isActive, setActive] = useState(false);

    const onActiveHandler = () => {
      setActive(!isActive);
    };

    return (
        <TheHeaderFilterItemWrapper isActive={isActive} onClick={onActiveHandler} className="the-header-filter-item">
            {title}
        </TheHeaderFilterItemWrapper>
    );
};

export default TheHeaderFilterItem;