import React from "react";
import styled from "styled-components";

const AppFormGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  max-width: 430px;
  width: 100%;

  @media (max-width: 1100px) {
    max-width: 100%;
    margin-top: 30px;
  }
  
  select {
    background: #FFFFFF;
    color: #606060;
    font-size: 18px;
    font-family: "Roboto", sans-serif;
    border: none;
    cursor: pointer;
    border-bottom: 1px solid #C6C6C6;
    width: 100%;
    outline: none;
    @media (max-width: 1100px) {
      font-size: 14px;
    }
  }

`;

const AppFormGroupSelect = ({label, options, name, value, onChange}) => {

    const onChangeHandler = (e) => {
        onChange(e.target.name, e.target.value)
    }

    return (
        <AppFormGroupWrapper>
            <select value={value} name={name} onChange={onChangeHandler}>
                <option>{label}</option>
                {
                    options.map((item) => {
                        return (<option key={item.id} value={item.id}>{item.name}</option>)
                    })
                }
            </select>
        </AppFormGroupWrapper>
    );
};

export default AppFormGroupSelect;
