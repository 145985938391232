import React, {useState} from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";

import settingsIcon from '../../assets/settings.svg';
import certIcon from '../../assets/cert.svg';
import langIcon from '../../assets/lang.svg';
import logoutIcon from '../../assets/logout.svg';

const TheHeaderAccountWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TheHeaderAccountName = styled.div`
  font-family: "Roboto", sans-serif;
  color: #fff;
  font-weight: 500;
  font-size: 18px;

  span {
    color: #6DA7FF;
    display: block;
    text-align: right;
  }
  
  @media (max-width: 800px) {
    display: none;
  }
`;

const TheHeaderAccountProfileWrapper = styled.div`
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-color: #C4C4C4;
  margin-left: 20px;
  cursor: pointer;
  position: relative;
  
  @media (max-width: 800px) {
    margin-left: 0;
  }
`;

const TheHeaderAccountProfileInfoWrapper = styled.div`
  position: absolute;
  display: ${props => props.isActive ? 'block' : 'none'};
  min-width: 220px;
  background: #2A2A2A;
  border: 1px solid #4D4D4D;
  box-sizing: border-box;
  padding: 20px;
  top: 55px;
  right: 0;
  z-index: 2;
  
  @media (max-width: 800px) {
    top: 45px;
  }

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    
    img {
      margin-right: 10px;
    }
    
    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }

`;

const TheHeaderAccount = () => {

    const [isActive, setActive] = useState(false);

    const onActiveClickHandler = () => {
        setActive(!isActive);
    };

    return (
        <TheHeaderAccountWrapper>
            <TheHeaderAccountName>
                Добро пожаловать, <span> Иван Иванович</span>
            </TheHeaderAccountName>
            <TheHeaderAccountProfileWrapper onClick={onActiveClickHandler}>
                <TheHeaderAccountProfileInfoWrapper isActive={isActive}>
                    <Link to="/edit-user-profile"> <img src={settingsIcon} alt=""/> Изменить профиль</Link>
                    <Link to="/"> <img src={certIcon} alt=""/> Мои сертификаты</Link>
                    <Link to="/"> <img src={langIcon} alt=""/> Язык: Русский</Link>
                    <Link to="/"> <img src={logoutIcon} alt=""/> Выйти</Link>
                </TheHeaderAccountProfileInfoWrapper>
            </TheHeaderAccountProfileWrapper>
        </TheHeaderAccountWrapper>
    );
};

export default TheHeaderAccount;