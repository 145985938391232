import styled from "styled-components";

const StatSubVideosWrapper = styled.div`
  
  position: relative;
  
  li, p {
    color: #2566B0;
    text-decoration: underline;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    cursor: pointer;
  }

  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    
    li:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  .adminStatTestSubItems {
    position: absolute;
    padding: 25px;
    background: #FFFFFF;
    border: 1px solid #E2E8F0;
    box-sizing: border-box;
    box-shadow: -7px 5px 33px rgba(25, 41, 124, 0.05);
    max-height: 330px;
    overflow-y: auto;
    width: 330px;
    z-index: 10;
    
    li span {
      color: #2D3748;
    }
  }
`

export default StatSubVideosWrapper