import React from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";

const TableTitleWrapper = styled.p`
  font-family: "Gilroy", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #000000;
  margin-bottom: 13px;
`;

const TheLastVideoTableWrapper = styled.div`
  max-width: 49%;
  width: 100%;
  margin-top: 45px;
  
  @media (max-width: 900px) {
    overflow-x: auto;
    max-width: 100%;
  }

  table {
    border: none;
    border-collapse: collapse;
    width: 100%;
    border-radius: 13px;
    box-shadow: -7px 5px 33px rgba(25, 41, 124, 0.05);
    background: #FFFFFF;

    thead {
      background: #2A2A2A;
      height: 56px;

      th {
        font-family: "Gilroy", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #F0F4F7;
        text-align: left;
        padding-left: 50px;
        
        &:last-child {
          text-align: right;
          padding-right: 50px;
          border-top-right-radius: 13px;
        }
        &:first-child {
          border-top-left-radius: 13px;
        }
      }
    }

    tbody {
      td {
        padding-left: 50px;
        background: #FFFFFF;
        height: 50px;
        border-top: 1px solid #E1E1E1;
        border-bottom: 1px solid #E1E1E1;
        font-family: "Gilroy", sans-serif;
        font-weight: 400;
        
        a {
          font-family: "Gilroy", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          color: #000000;
          text-decoration: none;
        }
        
        &:last-child {
          padding-left: 0;
          padding-right: 50px;
          text-align: right;
        }
      }
    }
    
    tfoot {
      td {
        height: 70px;

        a {
          font-family: "Gilroy", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          color: #000000;
          text-decoration: none;
          padding-right: 50px;
        }
      }
    }
  }
`;

const TheLastVideoTable = () => {
    return (
        <TheLastVideoTableWrapper>
            <TableTitleWrapper>Последние закрепленные видео</TableTitleWrapper>
            <table>
                <thead>
                <tr>
                    <th>Сотрудники</th>
                    <th>Видео</th>
                    <th>Дата</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td><Link to="/user">Иван Иванович</Link></td>
                    <td><Link to="/user-video">Видео название 1</Link></td>
                    <td>20/07/21</td>
                </tr>
                <tr>
                    <td><Link to="/user">Иван Иванович</Link></td>
                    <td><Link to="/user-video">Видео название 1</Link></td>
                    <td>20/07/21</td>
                </tr>
                <tr>
                    <td><Link to="/user">Иван Иванович</Link></td>
                    <td><Link to="/user-video">Видео название 1</Link></td>
                    <td>20/07/21</td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                    <td align="right" colSpan="3">
                        <Link to="full-videos">Увидеть полный список →</Link>
                    </td>
                </tr>
                </tfoot>
            </table>
        </TheLastVideoTableWrapper>
    )
};

export default TheLastVideoTable;