import React from 'react';
import Routes from "./Routes";
import {BrowserRouter as Router} from "react-router-dom";
import AppPreloader from "./components/app-preloader/AppPreloader";
import AppMessage from "./components/app-message/AppMessage";

function App() {
  return (
    <Router>
        <AppPreloader />
        <AppMessage />
        <Routes />
    </Router>
  );
}

export default App;
