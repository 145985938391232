import React, {useState} from 'react'
import {Link} from "react-router-dom";
import CheckboxWrapper from "../../styles/CheckboxWrapper";
import CheckboxHeaderWrapper from "../../styles/CheckboxHeaderWrapper";
import AppVideoTableWrapper from "../../styles/AppVideoTableWrapper";
import RemoveIcon from "../../styles/RemoveIcon";
import {useDispatch, useSelector} from "react-redux";
import {
    changeCheckedCategory,
    remoteAdminDeleteCategory,
} from "../../redux/actions";
import StatSubVideosWrapper from "../../styles/StatSubVideosWrapper";

import AppAdminStatTableItem from "../../components/app-admin-stat-table-item/AppAdminStatTableItem";

const AdminStatTable = ({rows}) => {

    const dispatch = useDispatch()

    const apiToken = useSelector(state => state.apiToken)

    return (
        <AppVideoTableWrapper>
            <table>
                <thead>
                <tr>
                    <th>Компания</th>
                    <th>Видео</th>
                </tr>
                </thead>
                <tbody>
                {
                    rows.map((item) => {
                        return (
                            <AppAdminStatTableItem key={item.company_id} item={item} />
                        )
                    })
                }

                </tbody>
            </table>
        </AppVideoTableWrapper>
    )
}

export default AdminStatTable