import React from "react";
import styled from "styled-components";

const AppTestVideoItemWrapper = styled.div`
  background: #FFFFFF;
  box-shadow: -7px 5px 33px rgba(25, 41, 124, 0.05);
  border-radius: 16px;
  width: 100%;
  padding: 30px;
  margin-top: 16px;
`;

const AppTestVideoItemTitle = styled.p`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #272727;
`;

const AppTestVideoItemText = styled.p`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.4em;
  color: #272727;
  margin-top: 18px;
  margin-bottom: 30px;
`;

const AppTestVideoAnswerWrapper = styled.div`

  display: flex;
  flex-direction: column;

  label {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #272727;
    cursor: pointer;
    position: relative;
    margin-left: 30px;

    input {
      display: none;

      + span {
        background: #E7EBED;
        border: 1px solid #C5C5C5;
        box-sizing: border-box;
        width: 14px;
        height: 14px;
        border-radius: 14px;
        position: absolute;
        left: -30px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    input:checked {
      + span {
        border: none;
        background: #597ABA;
      }
    }

    &:not(:first-child) {
      margin-top: 20px;
    }
  }
  
  .right-answer-correct {
    
  }
`;

const AppTestVideoAnswerVariantsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  
  @media (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
  }
  
`;

const AppTestVideoAnswerContinue = styled.div`
  button {
    min-width: 165px;
    padding: 17px 10px;
    background: #597ABA;
    box-shadow: -7px 5px 33px rgba(25, 41, 124, 0.05);
    border-radius: 7px;
    border: none;
    cursor: pointer;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
    transition: .3s all;
    &:hover {
      transition: .3s all;
      background-color: #445f91;
    }
    @media (max-width: 800px) {
      margin-top: 20px;
    }
  }
`;

const AppTestVideoItem = ({title}) => {
    return (
        <AppTestVideoItemWrapper>
            <AppTestVideoItemTitle>{title}</AppTestVideoItemTitle>
            <AppTestVideoItemText>
                On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and
                demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the
                pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty
                through weakness of will, which is the same as saying through shrinking from toil and pain.
            </AppTestVideoItemText>
            <AppTestVideoAnswerVariantsWrapper>
                <AppTestVideoAnswerWrapper>
                    <label>
                        Ответ А
                        <input name="answer" type="radio"/>
                        <span></span>
                    </label>
                    <label>
                        Ответ B
                        <input name="answer" type="radio"/>
                        <span></span>
                    </label>
                    <label>
                        Ответ C
                        <input name="answer" type="radio"/>
                        <span></span>
                    </label>
                </AppTestVideoAnswerWrapper>
                <AppTestVideoAnswerContinue>
                    <button type="button">Продолжить</button>
                </AppTestVideoAnswerContinue>

            </AppTestVideoAnswerVariantsWrapper>
        </AppTestVideoItemWrapper>
    );
};

export default AppTestVideoItem;