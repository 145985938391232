import React from "react";
import TheHeader from "../../components/the-header/TheHeader";
import AppPageCenterWrapper from "../../components/app-page-center-wrapper/AppPageCenterWrapper";
import AppContainer from "../../components/app-container/AppContainer";
import AppRow from "../../components/app-row/AppRow";
import AppPageTitle from "../../components/app-page-title/AppPageTitle";
import Vimeo from "@u-wave/react-vimeo";
import styled from "styled-components";
import {Link} from "react-router-dom";
import AppTestVideo from "../../components/app-test-video/AppTestVideo";

const VideoWrapper = styled.div`
  background-color: #212121;
  padding: 10px;
  border-radius: 5px;
  position: relative;
  
  p {
    position: absolute;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: right;
    color: #424242;
    right: 0;
    bottom: -35px;
  }
  
  @media (max-width: 1300px) {
    display: flex;
    margin: 0 auto;
  }

  iframe {
    max-width: 822px;
    
    @media (max-width: 1300px) {
      max-width: 100%;
    }

    @media (max-width: 800px) {
      max-width: 100%;
      height: 25vh;
    }
    
  }
`;

const BeginTestButtonWrapper = styled.div`

  a {
    background: #359C39;
    box-shadow: -7px 5px 33px rgba(25, 41, 124, 0.05);
    border-radius: 7px;
    padding: 15px 27px;
    min-width: 266px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    text-decoration: none;
    margin-top: 50px;

    @media (max-width: 1100px) {
      font-size: 16px;
    }

    &:hover {
      transition: .3s all;
      background-color: #2c8230;
    }

    @media (max-width: 1300px) {
      margin-bottom: 20px;
    }

  }
`;

const VideoDescriptionWrapper = styled.div`
  max-width: 710px;
  max-height: 524px;
  overflow-y: auto;

  @media (max-width: 1600px) {
    max-width: 32%;
  }

  @media (max-width: 1300px) {
    max-width: 100%;
    margin-top: 40px;
  }

  @media (max-width: 800px) {
    max-height: 100%;
  }
  
  h2 {
    color: #424242;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 15px;
  }
  
  p {
    color: #272727;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    line-height: 1.5em;
  }
  
  p:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const BeginTest = () => {
    return (
        <React.Fragment>
            <TheHeader isLogin={true} />
            <AppPageCenterWrapper>
                <AppContainer>
                    <AppRow>
                        <AppPageTitle title="Дуговая сварка, основы"/>
                    </AppRow>
                    <AppRow justify="space-between">
                        <VideoWrapper>
                            <Vimeo height="524" video="564959742"/>
                            <p>Вы просмотрели видео 3 раза</p>
                        </VideoWrapper>
                        <VideoDescriptionWrapper>
                            <h2>Описание видео</h2>
                            <p>
                                Задача организации, в особенности же реализация намеченных плановых заданий влечет за
                                собой процесс внедрения и модернизации дальнейших направлений развития. Не следует,
                                однако забывать, что дальнейшее развитие различных форм деятельности требуют определения
                                и уточнения новых предложений.
                            </p>
                            <p>Товарищи! постоянный количественный рост и сфера нашей активности обеспечивает широкому
                                кругу (специалистов) участие в формировании систем массового участия. Повседневная
                                практика показывает, что сложившаяся структура организации позволяет выполнять важные
                                задания по разработке систем массового участия. Повседневная практика показывает, что
                                постоянный количественный рост и сфера нашей активности в значительной степени
                                обуславливает создание систем массового участия. Повседневная практика показывает, что
                                новая модель организационной деятельности играет важную роль в формировании системы
                                обучения кадров, соответствует насущным потребностям.
                            </p>
                            <p>
                                Значимость этих проблем настолько очевидна, что постоянное
                                информационно-пропагандистское обеспечение нашей деятельности позволяет выполнять важные
                                задания по разработке модели развития. Значимость этих проблем настолько очевидна, что
                            </p>
                        </VideoDescriptionWrapper>
                    </AppRow>
                    <AppRow>
                        <AppTestVideo />
                    </AppRow>
                </AppContainer>
            </AppPageCenterWrapper>
        </React.Fragment>
    );
}

export default BeginTest;