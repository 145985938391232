import React, {useEffect, useState} from "react";
import TheHeader from "../../components/the-header/TheHeader";
import RoleService from "../../services/RoleService";
import TheSidebarMenu from "../../components/the-sidebar-menu/TheSidebarMenu";
import AppPageCenterWrapper from "../../components/app-page-center-wrapper/AppPageCenterWrapper";
import AppRow from "../../components/app-row/AppRow";
import styled from "styled-components";
import {Link} from "react-router-dom";

import userIcon from '../../assets/surface1.svg';
import playIcon from '../../assets/play.svg';
import toolsIcon from '../../assets/tools.svg';
import TheLastVideoTable from "../../components/the-last-video-table/TheLastVideoTable";
import TheLastActivityTable from "../../components/the-last-activity-table/TheLastActivityTable";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const AppPageTitleWrapper = styled.h1`
  color: #222222;
  font-size: 36px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  margin-top: 50px;
  margin-bottom: 10px;
  border-bottom: 2px solid #ABABAB;
  width: 100%;
  padding-bottom: 5px;

  @media (max-width: 800px) {
    margin-top: 20px;
    font-size: 21px;
  }
`;

const AppContainerWrapper = styled.div`
  max-width: 1659px;
  width: 100%;
  padding: 0 15px;
  padding-left: ${props => props.isActive ? '250px': '15px'};
  transition: .3s all;
  
  @media (max-width: 1340px) {
    padding-left: 280px;
  }
  
  @media (max-width: 900px) {
    padding-left: 15px;
  }
`;

const CompanyAccountCartItem = styled.div`
  margin-top: 30px;
  max-width: 337px;
  width: 100%;
  min-height: 196px;
  background: #2A2A2A;
  box-shadow: -7px 5px 33px rgba(25, 41, 124, 0.05), -7px 5px 33px rgba(25, 41, 124, 0.05);
  border-radius: 13px;
  padding: 20px;
  
  img {
    transition: 1s all;
  }
  
  &:hover {
    img {
      transform: scale(1.2);
      transition: 1s all;
    }
  }
  
  &.company-account-cart-item-second {
    background: #4C81D1;
    
    a {
      color: white;
    }
  }
`;

const CompanyAccountCartItemTitle = styled.p`
  font-family: "Gilroy", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #F0F4F7;
  min-height: 38px;
`;

const CompanyAccountCartItemContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props => props.justify || 'space-between'};
  
  p {
    font-family: "Gilroy", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 80px;
    line-height: 97px;
    color: #F0F4F7;
  }
`;

const CompanyAccountCartItemFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  a {
    font-family: "Gilroy", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #989898;
    text-decoration: none;
  }
`;

const CompanyAccount = () => {

    const roleService = new RoleService();
    const [isSidebarActive, setSidebarActive] = useState(true);

    const { width } = useWindowDimensions();

    useEffect(() => {
        if (width < 900) {
            setSidebarActive(false);
        }
    }, [width]);

    const onMobileMenuClick = () => {
      setSidebarActive(!isSidebarActive);
    };

    return (
        <React.Fragment>
            <TheHeader onMobileMenuHandler={onMobileMenuClick} role={roleService.getCompanyRole()} isLogin={true}/>
            <TheSidebarMenu isActive={isSidebarActive} />
            <AppPageCenterWrapper>
                <AppContainerWrapper isActive={isSidebarActive}>
                    <AppRow>
                        <AppPageTitleWrapper>
                            Название компании
                        </AppPageTitleWrapper>
                    </AppRow>
                    <AppRow justify="space-between">

                        <CompanyAccountCartItem>
                            <CompanyAccountCartItemTitle>Количество сотрудников</CompanyAccountCartItemTitle>
                            <CompanyAccountCartItemContent>
                                <img src={userIcon} alt=""/>
                                <p>34</p>
                            </CompanyAccountCartItemContent>
                            <CompanyAccountCartItemFooter>
                                <Link to="/">Подробнее →</Link>
                            </CompanyAccountCartItemFooter>
                        </CompanyAccountCartItem>

                        <CompanyAccountCartItem>
                            <CompanyAccountCartItemTitle>Количество видео в коллекции</CompanyAccountCartItemTitle>
                            <CompanyAccountCartItemContent>
                                <img src={playIcon} alt=""/>
                                <p>16</p>
                            </CompanyAccountCartItemContent>
                            <CompanyAccountCartItemFooter>
                                <Link to="/">Подробнее →</Link>
                            </CompanyAccountCartItemFooter>
                        </CompanyAccountCartItem>

                        <CompanyAccountCartItem>
                            <CompanyAccountCartItemTitle>Количество незадействованных
                                сотрудников</CompanyAccountCartItemTitle>
                            <CompanyAccountCartItemContent>
                                <img src={toolsIcon} alt=""/>
                                <p>3</p>
                            </CompanyAccountCartItemContent>
                            <CompanyAccountCartItemFooter>
                                <Link to="/">Подробнее →</Link>
                            </CompanyAccountCartItemFooter>
                        </CompanyAccountCartItem>

                        <CompanyAccountCartItem className="company-account-cart-item-second">
                            <CompanyAccountCartItemTitle>Счёт за текущий месяц</CompanyAccountCartItemTitle>
                            <CompanyAccountCartItemContent justify="flex-end">
                                <p>100$</p>
                            </CompanyAccountCartItemContent>
                            <CompanyAccountCartItemFooter>
                                <Link to="/">Подробнее →</Link>
                            </CompanyAccountCartItemFooter>
                        </CompanyAccountCartItem>

                    </AppRow>
                    <AppRow justify="space-between">
                        <TheLastVideoTable />
                        <TheLastActivityTable />
                    </AppRow>
                </AppContainerWrapper>
            </AppPageCenterWrapper>
        </React.Fragment>
    );
}

export default CompanyAccount;