import React from "react";
import styled from "styled-components";

const AppFormGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  max-width: 430px;
  width: 100%;

  @media (max-width: 1100px) {
    max-width: 100%;
    margin-top: 30px;
  }

  label {
    position: relative;
    top: 0;
    left: 5px;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    color: #606060;
    z-index: 1;
    transition: .3s all;
    @media (max-width: 1100px) {
      font-size: 14px;
    }
  }

  textarea {
    outline: none;
    width: 100%;
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: #606060;
    z-index: 2;
    background: #FFFFFF;
    border: 1px solid #E7E7E7;
    border-radius: 6px;
    min-height: 136px;
    resize: none;
    padding: 15px;
    margin-top: 10px;
  }
`;

const AppFormGroupTextArea = ({label, name, onChange, value}) => {

    const onChangeHandler = (e) => {
        onChange(e.target.name, e.target.value)
    }

    return(
        <AppFormGroupWrapper>
            <label>{label}</label>
            <textarea name={name} onChange={onChangeHandler} value={value}
                      placeholder="Оставьте комментарий (по желанию)" />
        </AppFormGroupWrapper>
    );
}

export default AppFormGroupTextArea;