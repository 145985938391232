import React from 'react'
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {hideMessage} from "../../redux/actions";

const AppMessageWrapper = styled.div`
  display: ${props => props.isShow ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
  z-index: 999;
  background-color: rgba(0,0,0,.5);
  top: 0;
  left: 0;
  position: fixed;
  height: 100vh;
  width: 100%;
  
  .app-message-content {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    width: 100%;
    padding: 30px 25px;
    border-radius: 4px;
    background-color: #fff;
    font-family: "Roboto",sans-serif;
    margin: 0 10px;
    
    .app-message-bottom {
      display: flex;
      justify-content: flex-end;
    }
    
    .app-message-wrapper-title {
      font-size: 21px;
      margin-bottom: 10px;
    }
    
    .app-message-wrapper-description {
      font-size: 18px;
      margin-bottom: 10px;
    }
    
    button {
      border: none;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      background-color: transparent;
      padding: 10px 20px;
      cursor: pointer;
    }
  }
`

const AppMessage = () => {

    const dispatch = useDispatch();
    const isShow = useSelector(state => state.isShowMessage)
    const title = useSelector(state => state.titleMessage)
    const description = useSelector(state => state.textMessage)

    const onCloseHandler = () => {
        dispatch(hideMessage())
    }

    return (
        <AppMessageWrapper isShow={isShow}>
            <div className="app-message-content">
                <p className="app-message-wrapper-title">{title}</p>
                <p className="app-message-wrapper-description">{description}</p>
                <div className="app-message-bottom">
                    <button onClick={onCloseHandler}>Закрыть</button>
                </div>
            </div>

        </AppMessageWrapper>
    )
}

export default AppMessage;