import React from "react";
import videoImage from '../../assets/videoimage.jpg';
import styled from "styled-components";
import {Link} from "react-router-dom";

const VideoListItemWrapper = styled.div`
  background: #FFFFFF;
  box-shadow: -6.04623px 4.31873px 28.5036px rgba(25, 41, 124, 0.05);
  border-radius: 13px;
  max-width: 284px;
  width: 100%;
  min-height: 344px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  margin-bottom: 25px;
`;

const VideoListItemImage = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(${props => props.image || ''});
  max-width: 262px;
  width: 100%;
  height: 141px;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  span {
    padding: 2px 20px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
    display: block;
    position: relative;
    bottom: -8px;
  }

  .video-new {
    background: #359C39;
  }

  .video-test-in-progress {
    background: #E6B024;
  }

  .video-success {
    background: #359C39;
  }

  .video-alert {
    background: #D04C3A;
  }

`;

const VideoListItemInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  margin-top: 20px;
`;

const VideoListItemTitleWrapper = styled.h2`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #272727;
`;

const VideoListItemSubTitleWrapper = styled.h2`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #272727;
  margin-top: 5px;
`;

const VideoListItemDescriptionWrapper = styled.h2`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #272727;
  margin-top: 15px;
`;

const VideoListItemButtonWrapper = styled.div`
  
  display: flex;
  justify-content: center;
  margin-top: 15px;
  
  a {
    background: #597ABA;
    box-shadow: -5.14159px 3.67257px 24.2389px rgba(25, 41, 124, 0.05);
    border-radius: 5px;
    text-decoration: none;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    padding: 12px 22px;
    transition: .3s all;
    &:hover {
      transition: .3s all;
      background-color: #4a679e;
    }
  }
`;

const VideoListItem = () => {
    return (
        <VideoListItemWrapper>
            <VideoListItemImage image={videoImage}>
                <span className="video-new">Новое</span>
            </VideoListItemImage>
            <VideoListItemInnerWrapper>
                <VideoListItemTitleWrapper>
                    Video 1 Название
                </VideoListItemTitleWrapper>
                <VideoListItemSubTitleWrapper>
                    Тест сделать до 15.07.2021
                </VideoListItemSubTitleWrapper>
                <VideoListItemDescriptionWrapper>
                    On the other hand, we denounce with righteous indignation...
                </VideoListItemDescriptionWrapper>
                <VideoListItemButtonWrapper>
                    <Link to="/one-video">Просмотреть видео</Link>
                </VideoListItemButtonWrapper>
            </VideoListItemInnerWrapper>
        </VideoListItemWrapper>
    );
};

export default VideoListItem;