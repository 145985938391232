import React from "react";
import styled from "styled-components";
import imageLogo from "../../assets/placeholder-logo-2.png";

const AppLogoWrapper = styled.div`
    img {
      max-width: 171px;
      height: 57px;
      
      @media (max-width: 800px) {
        height: auto;
        max-width: 80px;
        margin-right: 10px;
      }
    }
`;

const AppLogo = () => {

    return(
        <AppLogoWrapper>
            <img src={imageLogo} alt=""/>
        </AppLogoWrapper>
    );
};

export default AppLogo;