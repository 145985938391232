import React, {useEffect} from 'react';
import {Switch, Redirect, Route} from 'react-router-dom';

import CompanyRegister from "./pages/company-register/CompanyRegister";
import Login from "./pages/login/Login";
import Reset from "./pages/reset/Reset";
import OneVideo from "./pages/one-video/OneVideo";
import BeginTest from "./pages/begin-test/BeginTest";
import TestDone from "./pages/test-done/TestDone";
import VideosList from "./pages/videos-list/VideosList";
import EditUserProfile from "./pages/edit-user-profile/EditUserProfile";
import CompanyAccount from "./pages/company-account/CompanyAccount";
import SingleUser from "./pages/single-user/SingleUser";
import UserVideos from "./pages/user-videos/UserVideos";
import CompanyVideoCatalog from "./pages/company-video-catalog/CompanyVideoCatalog";
import MyVideos from "./pages/my-videos/MyVideos";
import Employees from "./pages/employees/Employees";
import VideoBuy from "./pages/video-buy/VideoBuy";
import CompanyEdit from "./pages/company-edit/CompanyEdit";
import UserStat from "./pages/user-stat/UserStat";
import {useDispatch, useSelector} from "react-redux";
import {getUserInfo} from "./redux/actions";
import config from "./config";
import AdminTestFormIndex from "./pages/admin-test-form/AdminTestFormIndex";
import AdminTestForm from "./pages/admin-test-form/AdminTestForm";
import AdminTestFormCreate from "./pages/admin-test-form/AdminTestFormCreate";
import AdminDashboard from "./pages/admin-dashboard/AdminDashboard";
import AdminCategoriesIndex from "./pages/admin-categories/AdminCategoriesIndex";
import AdminCategoryFormCreate from "./pages/admin-categories/AdminCategoryFormCreate";
import AdminCategoryForm from "./pages/admin-categories/AdminCategoryForm";
import AdminCompaniesIndex from "./pages/admin-companies/AdminCompaniesIndex";
import CompanyCreateForm from "./pages/admin-companies/CompanyCreateForm";
import AdminVideosIndex from "./pages/admin-videos/AdminVideosIndex";
import AdminVideoCreate from "./pages/admin-videos/AdminVideoCreate";
import AdminUsersIndex from "./pages/admin-users/AdminUsersIndex";
import AdminUserCreate from "./pages/admin-users/AdminUsersCreate";
import AdminSpecializationsIndex from "./pages/admin-specializations/AdminSpecializationsIndex";
import AdminNotFound from "./pages/admin-not-found/AdminNotFound";
import AdminUserEdit from "./pages/admin-users/AdminUserEdit";
import AdminStatIndex from "./pages/admin-stat/AdminStatIndex";
import AdminStatDetailedIndex from "./pages/admin-stat-detailed/AdminStatDetailedIndex";

const Routes = () => {

    const dispatch = useDispatch()
    const apiToken = useSelector(state => state.apiToken)
    const userRole = useSelector(state => state.userRole)

    useEffect(() => {

        if (apiToken) {
            dispatch(getUserInfo({
                apiToken
            }))
        }

    }, [apiToken, userRole])

    return (
        <Switch>
            <Route
                component={VideosList}
                exact
                path="/"
            />
            <Route
                component={CompanyRegister}
                exact
                path="/register"
            />
            <Route
                component={Login}
                exact
                path="/login"
            />
            <Route
                component={Reset}
                exact
                path="/reset"
            />
            <Route
                component={OneVideo}
                exact
                path="/one-video"
            />
            <Route
                component={BeginTest}
                exact
                path="/begin-test"
            />
            <Route
                component={TestDone}
                exact
                path="/test-done"
            />
            <Route
                component={VideosList}
                exact
                path="/video-list"
            />
            <Route
                component={EditUserProfile}
                exact
                path="/edit-user-profile"
            />
            <Route
                component={SingleUser}
                exact
                path="/user"
            />
            <Route
                component={CompanyAccount}
                exact
                path="/company"
            />
            <Route
                component={UserVideos}
                exact
                path="/user-videos"
            />
            <Route
                component={CompanyVideoCatalog}
                exact
                path="/company-videos"
            />
            <Route
                component={MyVideos}
                exact
                path="/my-videos"
            />
            <Route
                component={Employees}
                exact
                path="/employees"
            />
            <Route
                component={VideoBuy}
                exact
                path="/video-buy"
            />

            <Route
                component={UserStat}
                exact
                path="/user-stat"
            />

            <Route
                component={AdminDashboard}
                exact
                path="/admin"
            />

            <Route
                component={AdminTestFormCreate}
                exact
                path="/admin/test/create"
            />

            <Route
                component={AdminTestForm}
                exact
                path="/admin/test/:id"
            />

            <Route
                component={AdminTestFormIndex}
                exact
                path="/admin/test/page/:page"
            />

            <Route
                component={AdminCategoriesIndex}
                exact
                path="/admin/categories/page/:page"
            />

            <Route
                component={AdminCategoryFormCreate}
                exact
                path="/admin/category/create"
            />

            <Route
                component={AdminCategoryForm}
                exact
                path="/admin/category/:id"
            />

            <Route
                component={AdminCategoriesIndex}
                exact
                path="/admin/categories/page/:page"
            />

            <Route
                component={AdminCompaniesIndex}
                exact
                path="/admin/companies/page/:page"
            />

            <Route
                component={CompanyCreateForm}
                exact
                path="/admin/company/create"
            />

            <Route
                component={CompanyEdit}
                exact
                path="/admin/companies/:id"
            />

            <Route
                component={AdminVideosIndex}
                exact
                path="/admin/videos/page/:page"
            />

            <Route
                component={AdminVideoCreate}
                exact
                path="/admin/video/create"
            />

            <Route
                component={AdminUsersIndex}
                exact
                path="/admin/users/page/:page"
            />

            <Route
                component={AdminUserCreate}
                exact
                path="/admin/user/create"
            />

            <Route
                component={AdminUserEdit}
                exact
                path="/admin/users/:id"
            />

            <Route
                component={AdminSpecializationsIndex}
                exact
                path="/admin/specializations/page/:page"
            />

            <Route
                component={AdminStatIndex}
                exact
                path="/admin/stat/page/:page"
            />

                <Route
                    component={AdminStatDetailedIndex}
                    exact
                    path="/admin/stat/:company/:video"
                />

            <Route
                component={AdminNotFound}
                exact
                path="/not-found-admin"
            />


            <Redirect to="/not-found-admin"/>
        </Switch>
    );
};

export default Routes;