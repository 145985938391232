import React from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";

import certIcon from '../../assets/downloadcerticon.svg';

const TableTitleWrapper = styled.p`
  font-family: "Gilroy", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  color: #000000;
  margin-bottom: 13px;
`;

const TheLastVideoTableWrapper = styled.div`
  max-width: 49%;
  width: 100%;
  margin-top: 45px;

  @media (max-width: 900px) {
    max-width: 100%;
  }

  table {
    border: none;
    border-collapse: collapse;
    width: 100%;
    border-radius: 13px;
    box-shadow: -7px 5px 33px rgba(25, 41, 124, 0.05);
    background: #FFFFFF;

    tbody {

      tr:first-child {
        td:first-child {
          border-top-left-radius: 13px;
        }

        td:last-child {
          border-top-right-radius: 13px;
        }
      }

      tr:last-child {
        td:first-child {
          border-bottom-left-radius: 13px;
        }

        td:last-child {
          border-bottom-right-radius: 13px;
        }
      }
      
      tr:not(:last-child) {
        td {
          border-bottom: 1px solid #E1E1E1;
        }
      }

      td {
        
        &:first-child {
          padding-left: 50px;
        }
        
        &:last-child {
          padding-right: 50px;
          padding-left: 20px;
        }
        
        @media (max-width: 900px) {
          &:first-child {
            padding-left: 20px;
          }
          &:last-child {
            padding-right: 20px;
            padding-left: 20px;
          }
        }
        
        background: #FFFFFF;
        height: 50px;
        font-family: "Gilroy", sans-serif;
        font-weight: 500;
        font-size: 16px;

        a {
          font-family: "Gilroy", sans-serif;
          line-height: 19px;
          color: #4C81D1;
        }

      }
    }
    
  }

  .test-done-success {
    color: #2C9E31;
  }

  .test-done-medium {
    color: #D6B84F;
  }

  .test-done-failed {
    color: #E72E2E;
  }
`;

const TheCertificates = () => {
    return (
        <TheLastVideoTableWrapper>
            <TableTitleWrapper>Сертификаты</TableTitleWrapper>
            <table>
                <tbody>
                <tr>
                    <td>
                        <Link to="/user">Иван Иванович</Link> сдал тест 00/00/00 на
                        <span className="test-done-success"> 90 баллов</span>.
                    </td>
                    <td>
                        <a href="#"><img src={certIcon} alt=""/></a>
                    </td>
                </tr>
                <tr>
                    <td><Link to="/user">Иван Иванович</Link> сдал тест 00/00/00 на
                        <span className="test-done-medium"> 74 балла</span>.
                    </td>
                    <td>
                        <a href="#"><img src={certIcon} alt=""/></a>
                    </td>
                </tr>
                <tr>
                    <td><Link to="/user">Иван Иванович</Link> сдал тест 00/00/00 на
                        <span className="test-done-failed"> 18 баллов</span>.
                    </td>
                    <td>
                        <a href="#"><img src={certIcon} alt=""/></a>
                    </td>
                </tr>
                <tr>
                    <td><Link to="/user">Иван Иванович</Link> сдал тест 00/00/00 на
                        <span className="test-done-medium"> 74 балла</span>.
                    </td>
                    <td>
                        <a href="#"><img src={certIcon} alt=""/></a>
                    </td>
                </tr>
                </tbody>
            </table>
        </TheLastVideoTableWrapper>
    )
};

export default TheCertificates;